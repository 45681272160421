import { yupResolver } from '@hookform/resolvers/yup';
import StatusCodes from 'http-status-codes';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useSendOperationReceiptToEmailMutation } from '@/store/reports/service';
import type { EmailFormValues } from '@/types/report';
import { Form } from '@/views/shared/Form';
import { InputWithButton } from '@/views/shared/Form/InputWithButton';

const EmailForm = ({ id }: { id: number }) => {
  const [sendReceiptToEmail, { isLoading }] = useSendOperationReceiptToEmailMutation();
  const [errorText, setErrorText] = useState('');

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(t('other.enter_email')),
  });

  const defaultValues = useMemo(() => {
    return {
      email: '',
    };
  }, []);

  const methods = useForm<EmailFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const {
    formState: { errors },
    reset,
    register,
    handleSubmit,
  } = methods;

  const sendToEmail = useCallback(
    ({ email }: EmailFormValues) => {
      setErrorText('');

      const data = {
        action: 'email',
        recipient: email,
      };
      sendReceiptToEmail({ body: data, id })
        .unwrap()
        .then((res) => {
          if (res.Status === StatusCodes.OK) {
            reset();
            toast.success(`${t('other.check_email_sent_successfully')}: ${email}.`, {
              theme: 'dark',
            });
          }
        });
    },
    [id, reset, sendReceiptToEmail, t],
  );

  return (
    <Form onSubmit={handleSubmit(sendToEmail)} form={methods}>
      <div className="relative my-3">
        <InputWithButton
          register={register}
          name="email"
          type="email"
          errors={errors}
          placeholder="example@kassa.kz"
          label={t('receipt.send_receipt_email')}
          errorText={errors?.email?.message || errorText}
          btnText="duplicate.send"
          isLoading={isLoading}
        />
      </div>
    </Form>
  );
};

export { EmailForm };
