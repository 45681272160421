import {
  Combobox as HeadlessCombobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
} from '@headlessui/react';
import type { ChangeEvent, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Icon } from '../Icon';
import type { iconMap } from '../Icon/icon-map';

import type { OptionType } from '@/types/form';
import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props<T> = {
  options: OptionType<T>[];
  selectedOption: OptionType<T> | null;
  setSelectedOptions: (value: OptionType<T> | null) => void;
  setInputValue?: (value: string) => void;
  CustomIcon?: ReactNode;
  iconType?: keyof typeof iconMap;
  onClickIcon?: () => void;
  name: string;
  label?: string;
};

const Combobox = <T,>({
  options,
  selectedOption,
  CustomIcon,
  iconType,
  name,
  label,
  setSelectedOptions,
  onClickIcon,
  setInputValue,
}: Props<T>) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const onComboboxInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setQuery(value);
  };

  useEffect(() => {
    if (!selectedOption && setInputValue) {
      setInputValue(query);
    } else if (setInputValue) {
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selectedOption]);

  const handleSelect = (value: OptionType<T> | null) => {
    setSelectedOptions(value);
  };

  return (
    <Field className="relative">
      {label && (
        <label htmlFor={name} className="text-sm text-darkGray pl-2">
          {label}
        </label>
      )}
      <HeadlessCombobox
        name={name}
        value={selectedOption || { id: '', label: '', value: null }}
        onChange={handleSelect}
        immediate
      >
        <div
          className={twMergeClsx(
            'border border-lightGray-second rounded-xl overflow-hidden flex items-stretch h-11 transition-shadow',
          )}
        >
          <ComboboxInput
            aria-label="Assignee"
            className="flex-grow outline-none px-4"
            displayValue={(option: OptionType<T>) => (option.value ? option?.label : query)}
            onChange={onComboboxInputChange}
          />
          {onClickIcon && (
            <button
              onClick={onClickIcon}
              type="button"
              className="border-l border-lightGray-second outline-none w-11"
            >
              {CustomIcon}
              {iconType && (
                <Icon type={iconType} className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
              )}
            </button>
          )}
        </div>

        <ComboboxOptions
          transition
          className="origin-top max-h-[320px] overflow-y-scroll shadow-gray rounded-lg transition mt-2 bg-white absolute duration-200 ease-out empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0 w-full overflow-hidden z-20"
        >
          {filteredOptions.map((option) => (
            <ComboboxOption
              key={option.id}
              value={option}
              className="data-[focus]:bg-blue/10 px-3 py-2"
            >
              {option.label}
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </HeadlessCombobox>
    </Field>
  );
};

export { Combobox };
