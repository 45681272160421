import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Company } from '@/types/companies';

type InitialState = {
  companyId: number | null;
  companyItem: Company | null;
};

const initialState: InitialState = {
  companyId: null,
  companyItem: null,
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanyId: (state, { payload }: PayloadAction<number | null>) => {
      state.companyId = payload;
    },
    setCompanyItem: (state, { payload }: PayloadAction<Company>) => {
      state.companyItem = payload;
    },
  },
});

export default companiesSlice.reducer;

export const { setCompanyId, setCompanyItem } = companiesSlice.actions;
