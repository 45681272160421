import { iconMap } from './icon-map';

type Props = {
  className?: string;
  type: keyof typeof iconMap;
};

const Icon = ({ className, type }: Props) => {
  const IconType = iconMap[type];

  return !IconType ? null : <IconType className={className} />;
};

export { Icon };
