import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import { Icon } from '../Icon';

type Props = {
  showScanner: boolean;
  setCode: (value: string) => void;
  setShowScanner: (value: boolean) => void;
};

const BarcodeScanner = ({ showScanner, setShowScanner, setCode }: Props) => {
  const { t } = useTranslation();

  const [barcode, setBarcode] = useState<string>(''); // Текущий ввод
  const inputTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key !== 'Shift' && event.key !== 'Control' && event.key !== 'Alt') {
        setBarcode((prev) => prev + event.key);
      }

      if (inputTimeout.current) {
        clearTimeout(inputTimeout.current);
      }

      inputTimeout.current = setTimeout(() => {
        if (barcode) {
          setCode(barcode);
          setBarcode('');
          setShowScanner(false);
        }
      }, 300);
    };

    // Добавляем слушатель событий
    if (showScanner) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [barcode, setCode, setShowScanner, showScanner]);

  return (
    <Dialog open={showScanner} onClose={() => setShowScanner(false)}>
      <DialogBackdrop className="fixed inset-0 bg-black/30 z-50" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-1 z-50">
        <DialogPanel className="max-w-lg w-full bg-[#0009] p-6 text-white mix-blend-hard-light">
          <h3 className="mb-10 text-base font-semibold text-center mt-4">
            {t('receipt.scan_barcode')}
          </h3>
          <div className="bg-darkGray">
            <BarcodeScannerComponent
              delay={1000}
              onUpdate={(err, result) => {
                if (result) {
                  setCode(result.getText());
                } else {
                  setCode('');
                }

                setShowScanner(false);
              }}
              onError={(err) => {
                console.log(err);
              }}
            />
          </div>
          <Icon type="whiteLogo" className="mt-5 mx-auto" />
          <button
            type="button"
            className="text-base font-semibold text-center mt-10 mx-auto w-full"
            onClick={() => setShowScanner(false)}
          >
            {t('receipt.cancel')}
          </button>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export { BarcodeScanner };
