import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { OperationType } from '@/constants/operations';
import { useNavigation } from '@/hooks/useNavigate';
import useOperations from '@/hooks/useOperations';
import { useAppSelector } from '@/hooks/useRedux';

const useContainer = ({ operationType }: { operationType: OperationType }) => {
  const { t } = useTranslation();
  const { goToReport } = useNavigation();
  const { openedShift } = useAppSelector((state) => state.shift);
  const { errors } = useAppSelector((state) => state.operations);
  const [searchValue, setSearchValue] = useState('');
  const { getOperationDetails, clearOperationStore } = useOperations();

  useEffect(() => {
    clearOperationStore();
  }, [clearOperationStore]);

  const searchReceipt = useCallback(() => {
    clearOperationStore();

    if (!searchValue || !openedShift) return;

    getOperationDetails(searchValue, operationType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOperationStore, getOperationDetails, operationType, searchValue]);

  return {
    t,
    openedShift,
    errors,
    searchValue,
    searchReceipt,
    setSearchValue,
    goToReport,
  };
};

export default useContainer;
