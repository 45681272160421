import { yupResolver } from '@hookform/resolvers/yup';
import { StatusCodes } from 'http-status-codes';
import { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAddSectionMutation } from '@/store/settings/service';
import type { AddSectionForm } from '@/types/settings';

const useContainer = ({ onAdd }: { onAdd: () => void }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [addSectionQuery, { isLoading }] = useAddSectionMutation();
  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .required(t('kassa_settings.enter_name'))
      .typeError(t('kassa_settings.enter_name')),
    Nds: Yup.number()
      .required(t('other.enter_vat'))
      .typeError(t('kassa_settings.section_error_nds'))
      .min(0, t('kassa_settings.section_error_nds')),
  });
  const methods = useForm<AddSectionForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: { Name: '', Nds: 0 },
  });

  const addSection: SubmitHandler<AddSectionForm> = useCallback(
    (values) => {
      if (!params?.idKkm) return;

      addSectionQuery({ idKkm: Number(params?.idKkm), body: { ...values } })
        .unwrap()
        .then((result) => {
          if (result.Status === StatusCodes.OK) {
            methods.reset();
            onAdd();
            toast.success(t('other.section_successfully_added'), {
              theme: 'dark',
            });
          }
        })
        .catch((e) => {
          console.error(e);
          toast.warning(t('main.error'), {
            theme: 'dark',
          });
        });
    },
    [addSectionQuery, methods, onAdd, params?.idKkm, t],
  );

  return { methods, t, isLoading, addSection };
};

export default useContainer;
