import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PERIOD_TYPES } from '@/constants/date';
import { useOutsideClickEvent, useAppDispatch, useAppSelector } from '@/hooks';
import { setDateFilter } from '@/store/reports/slice';
import type { DateRangeCalendar, PeriodLabelAndCallbackType } from '@/types/date';
import { getFormattedDate, getRangePeriod } from '@/utils/dates';

const useContainer = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { periodType, dateFrom, dateTo } = useAppSelector((state) => state.reports);
  const [selectedOption, setSelectedOption] = useState<string>(periodType);
  const [dateLabel, setDateLabel] = useState<string[]>([]);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    { startDate: dateFrom, endDate: dateTo, key: 'selection' },
  ]);
  const { innerRef } = useOutsideClickEvent(() => setCalendarIsOpen(false));

  useEffect(() => {
    setDateLabel([getFormattedDate(dateFrom), getFormattedDate(dateTo)]);
  }, [dateFrom, dateTo]);

  const periodOptions = [
    { label: t('report.yesterday'), value: PERIOD_TYPES.YESTERDAY },
    { label: t('report.three_days'), value: PERIOD_TYPES.THREE_DAYS },
    { label: t('report.week'), value: PERIOD_TYPES.WEEK },
    { label: t('report.month'), value: PERIOD_TYPES.MONTH },
    { label: t('report.quarter'), value: PERIOD_TYPES.QUARTER },
    {
      label: t('report.half_year'),
      value: PERIOD_TYPES.HALF_YEAR,
    },
    { label: t('report.year'), value: PERIOD_TYPES.YEAR },
    { label: t('report.select_period'), value: PERIOD_TYPES.PERIOD },
  ];

  const setPeriodLabelAndCallback = useCallback(
    ({ date, dateString, period }: PeriodLabelAndCallbackType) => {
      setDateLabel([dateString.from, dateString.to]);
      dispatch(
        setDateFilter({
          periodType: period,
          dateFrom: date.from,
          dateTo: date.to,
        }),
      );
    },
    [dispatch],
  );

  const onChangeOption = useCallback(
    (value: string) => {
      setSelectedOption(value);

      if (value === PERIOD_TYPES.PERIOD) {
        setCalendarIsOpen(true);
      } else {
        const dates = getRangePeriod({ type: value });

        setPeriodLabelAndCallback({ ...dates, period: value });
      }
    },
    [setPeriodLabelAndCallback],
  );

  const handleChangeDateRange = useCallback(
    (date: DateRangeCalendar) => {
      setDateRange([date.selection]);

      if (date.selection.startDate !== date.selection.endDate) {
        const dates = getRangePeriod({
          type: PERIOD_TYPES.PERIOD,
          range: [date.selection][0],
        });
        setPeriodLabelAndCallback({ ...dates, period: PERIOD_TYPES.PERIOD });
      }
    },
    [setPeriodLabelAndCallback],
  );

  return {
    periodOptions,
    innerRef,
    dateRange,
    i18n,
    dateLabel,
    selectedOption,
    calendarIsOpen,
    handleChangeDateRange,
    onChangeOption,
  };
};

export default useContainer;
