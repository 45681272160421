export const getLastPathSegment = (urlString: string): string => {
  try {
    const url = new URL(urlString, window.location.origin); // если строка относительная, то можно базовый урл добавить, ну или не добавлять, если сразу полная приходит
    const segments = url.pathname.split('/').filter(Boolean);

    return segments[segments.length - 1] || '';
  } catch {
    return '';
  }
};
