import { Fragment } from 'react/jsx-runtime';

import useContainer from './hook';

import { ButtonType } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import { SelectWithControl } from '@/views/shared/Form/SelectWithControl';

const OfdSettings = ({ onSave }: { onSave: () => void }) => {
  const { t, methods, isLoading, ofdOptions, saveOfdSettings } = useContainer({ onSave });

  return (
    <div className="bg-white rounded-lg shadow-gray py-3 m-auto mt-6 w-auto max-w-lg">
      <Form onSubmit={methods.handleSubmit(saveOfdSettings)} form={methods}>
        <Fragment>
          <div className="flex flex-col gap-2 px-3">
            <SelectWithControl
              options={ofdOptions}
              name="ofdValue"
              control={methods.control}
              label={t('kassa_settings.ofd')}
              withIntl={false}
            />
            <Input
              id="rnm"
              register={methods.register('rnm')}
              type="number"
              aria-invalid={!!methods?.formState?.errors.rnm}
              label={t('kassa_settings.rnm')}
              errorText={methods.formState.errors.rnm?.message}
            />
            <Input
              id="kkmId"
              register={methods.register('kkmId')}
              type="number"
              aria-invalid={!!methods?.formState?.errors.kkmId}
              label={t('kassa_settings.kkm_id')}
              errorText={methods.formState.errors.kkmId?.message}
            />
            <Input
              id="token"
              register={methods.register('token')}
              type="number"
              aria-invalid={!!methods?.formState?.errors.token}
              label={t('kassa_settings.token')}
              errorText={methods.formState.errors.token?.message}
            />
          </div>
          <div className="p-3 border-t border-lightGray lg:border-none mt-4">
            <Button
              text={isLoading ? 'kassa_settings.saving_changes' : 'kassa_settings.save_changes'}
              type={ButtonType.submit}
              isLoading={isLoading}
              className="w-full"
            />
          </div>
        </Fragment>
      </Form>
    </div>
  );
};

export { OfdSettings };
