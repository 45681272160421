import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { CompaniesList } from '@/types/companies';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const companiesService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<ApiResponse<CompaniesList>, object>({
      query: () => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_COMPANIES),
    }),
  }),
});

export const { useLazyGetCompaniesQuery, useGetCompaniesQuery } = companiesService;
