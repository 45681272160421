import useContainer from './hook';

import { ButtonType } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import Modal from '@/views/shared/Modal';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAdd: () => void;
};

const AddSectionModal = ({ isOpen, onClose, onAdd }: Props) => {
  const { t, methods, isLoading, addSection } = useContainer({ onAdd });

  return (
    <Modal open={isOpen} onClose={onClose} title={t('other.add_new_section')} withCloseButton>
      <Form onSubmit={methods.handleSubmit(addSection)} form={methods}>
        <div className="flex flex-col gap-2">
          <Input
            name="Name"
            register={methods.register('Name')}
            type="text"
            label={t('other.section_name')}
            aria-invalid={!!methods?.formState?.errors.Name}
            errorText={methods.formState.errors.Name?.message}
          />
          <Input
            name="Nds"
            register={methods.register('Nds')}
            type="number"
            label={`${t('kassa_settings.vat_rate')}, %`}
            aria-invalid={!!methods?.formState?.errors.Nds}
            errorText={methods.formState.errors.Nds?.message}
          />
          <Button
            type={ButtonType.submit}
            text="kassa_settings.add"
            isLoading={isLoading}
            className="w-full mt-2"
          />
        </div>
      </Form>
    </Modal>
  );
};

export { AddSectionModal };
