import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useLazyGetReceiptQuery } from '@/store/operations/service';
import type { BillcheckForm } from '@/types/form';

const useContainer = () => {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location?.search.replace(/\\u0026/g, '&')),
    [location.search],
  );
  const [getReceiptQuery, { isLoading }] = useLazyGetReceiptQuery();
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [receiptImage, setReceiptImage] = useState('');

  const validationSchema = Yup.object().shape({
    znm: Yup.number().required('Укажите ЗНМ').typeError('Неверный формат ЗНМ'),
    fn: Yup.number()
      .required('Укажите Фискальный признак')
      .typeError('Неверный формат Фискального признака'),
    datetime: Yup.string().required('Укажите Дату').typeError('Неверный формат Даты'),
    total: Yup.number()
      .required('Укажите Итоговую сумму')
      .min(1, 'Неверное значение Итоговой суммы')
      .typeError('Неверный формат Итоговой суммы'),
  });

  const defaultValues = useMemo(() => {
    if (params?.get('fn'))
      return {
        znm: Number(params.get('znm')),
        fn: Number(params.get('fn')),
        datetime: params.get('datetime') || format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
        total: Number(params.get('total')),
      };

    return {
      datetime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    };
  }, [params]);

  const methods = useForm<BillcheckForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const updateReceiptModal = useCallback((img: string, show: boolean) => {
    setShowReceiptModal(show);
    setReceiptImage(img);
  }, []);

  const getReceipt: SubmitHandler<BillcheckForm> = useCallback(
    (values) => {
      const formAsStrings: Record<string, string> = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, String(value)]),
      );
      const queryString = new URLSearchParams(formAsStrings).toString();

      getReceiptQuery(queryString)
        .unwrap()
        .then((res) => {
          updateReceiptModal(res?.Data, true);
        })
        .catch(() => {
          toast.error('Чек не найден', { theme: 'dark' });
        });
    },
    [getReceiptQuery, updateReceiptModal],
  );

  return { methods, isLoading, showReceiptModal, receiptImage, updateReceiptModal, getReceipt };
};

export default useContainer;
