import { calculateDiscountOrMarkup } from './format-amount';

import { DISCOUNT_TYPES, OperationType } from '@/constants/operations';
import type { OperationPosition, Position } from '@/types';

type PositionTotal = {
  Qty: number;
  Price: number;
  Markup: number;
  Discount: number;
  DiscountType: string;
};

export const getPositionTotal = (
  { Qty, Price, Markup, Discount, DiscountType }: PositionTotal,
  status: OperationType,
) => {
  const markupValue = Number.isNaN(Markup) ? 0 : Number(Markup);
  const discountValue = Number.isNaN(Discount) ? 0 : Number(Discount);

  if (status === OperationType.PURCHASE) {
    return Qty * Price;
  }

  if (status === OperationType.REFUND) {
    return Qty * Price;
  }

  if (status === OperationType.PURCHASE_REFUND) {
    return Qty * Price;
  }

  if (status === OperationType.SALE) {
    if (DiscountType === DISCOUNT_TYPES.FIXED) {
      // СуммаВсегоПоСтроке = Количество*Цена - СуммаСкидки + Наценка.
      return Qty * Price - discountValue + markupValue;
    }

    return Qty * (Price - discountValue + markupValue);
  }

  return Qty * (Price - discountValue + markupValue);
};

export const getPositionForPreCheck = (
  position: Position,
  domainId: number,
  operationType: OperationType,
) => {
  const calculatedDiscount = calculateDiscountOrMarkup(
    position.Price,
    position.Discount,
    DISCOUNT_TYPES.FIXED,
  );
  const calculatedMarkup = calculateDiscountOrMarkup(
    position.Price,
    position.Markup,
    DISCOUNT_TYPES.FIXED,
  );

  return {
    id: position.Id,
    discount: calculatedDiscount,
    markup: calculatedMarkup,
    discountPercent: position.Discount,
    markupPercent: position.Markup,
    idUnit: position.IdUnit,
    unitShortName: position.Unit?.ShortName || '',
    discountType: DISCOUNT_TYPES.FIXED,
    idSection: position.IdSection,
    sectionName: position.Section?.Name || '',
    isFixedDiscountOrMarkup: false,
    name: position.Name,
    price: position.Price,
    productCode: position.ProductCode,
    qty: position.Qty,
    storno: false,
    idDomain: domainId,
    total:
      position.Total ??
      getPositionTotal(
        {
          Discount: calculatedDiscount,
          DiscountType: DISCOUNT_TYPES.FIXED,
          Markup: calculatedMarkup,
          Price: position.Price,
          Qty: position.Qty,
        },
        operationType,
      ),
  };
};

export const filterPositionsForRefund = (positions: OperationPosition[]) => {
  return positions.filter((position) => {
    if (position.Storno) {
      return false;
    }

    return true;
  });
};
