import type { ReactNode } from 'react';

import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props = {
  children: ReactNode;
  className?: string;
  title?: string;
};

const DefinitionList = ({ children, className, title }: Props) => {
  return (
    <div
      className={twMergeClsx(
        'p-3 flex flex-col gap-2 border-b last:border-none border-lightGray-second',
        className,
      )}
    >
      {title && <div className="font-semibold">{title}</div>}
      {children}
    </div>
  );
};

export { DefinitionList };
