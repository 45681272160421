import { pultApi } from '../pultApi';

import type { KkmExpired } from '@/types/cashbox';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const pultService = pultApi.injectEndpoints({
  endpoints: (build) => ({
    getExpiredKkmList: build.query<KkmExpired | undefined, string | number>({
      query: (companyId) =>
        buildEndpointUrl('/fiscal/main/:companyId/kkms/expired-list', { companyId }),
    }),
  }),
});

export const { useGetExpiredKkmListQuery, useLazyGetExpiredKkmListQuery } = pultService;
