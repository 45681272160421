import { useTranslation } from 'react-i18next';

import { useNavigation } from './useNavigate';
import { useAppDispatch, useAppSelector } from './useRedux';

import { KKM_STATUSES } from '@/constants/common';
import { SHIFT_EXPIRED_DIFFERENCE } from '@/constants/shift';
import { setOpenedBalances } from '@/store/balance/slice';
import { useLazyGetKkmsByIdQuery } from '@/store/cashbox/service';
import { setKkmItem } from '@/store/cashbox/slice';
import { fiscalApi } from '@/store/fiscalApi';
import { useLazyGetShiftQuery, useOpenShiftMutation } from '@/store/shift/service';
import { setOpenedShift, setShift, setShiftStatus } from '@/store/shift/slice';
import { useLazyGetUserQuery } from '@/store/user/service';
import { setUser } from '@/store/user/slice';
import type { User } from '@/types/auth';
import type { Kkm } from '@/types/cashbox';
import type { Shift, ShiftStatus } from '@/types/shift';

type CheckResult = { shift: Shift | null; shiftStatus: ShiftStatus } | null;

const useShift = () => {
  const { goToCloseShift } = useNavigation();
  const [openShift, { isLoading: loadingOpenShift }] = useOpenShiftMutation();
  const { user } = useAppSelector((state) => state.user);
  const { shift: currentShift } = useAppSelector((state) => state.shift);
  const [getUser] = useLazyGetUserQuery();
  const [getKkm] = useLazyGetKkmsByIdQuery();
  const [getShift] = useLazyGetShiftQuery();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createShiftStatus = (updates: Partial<ShiftStatus>): ShiftStatus => ({
    shiftBlocked: false,
    shiftErrorText: '',
    shiftExpired: false,
    shiftOpen: false,
    shiftOpenByAnotherCashier: false,
    ...updates,
  });

  const checkKkmPaid = (kkmItem: Kkm, baseStatus: ShiftStatus): CheckResult =>
    !kkmItem.IsActive
      ? {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftErrorText: t('shift.cash_register_not_paid'),
          },
        }
      : null;

  const checkKkmBlockedByOfd = (kkmItem: Kkm, baseStatus: ShiftStatus): CheckResult =>
    kkmItem.IdStatusKkm === KKM_STATUSES.BLOCKED_BY_OFD
      ? {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftErrorText: baseStatus.shiftOpen
              ? t('shift.shift_opened_error')
              : t('shift.shift_closed_error'),
          },
        }
      : null;

  const checkKkmRegisterInProgress = (kkmItem: Kkm, baseStatus: ShiftStatus): CheckResult =>
    kkmItem.IdStatusKkm === KKM_STATUSES.REGISTER_IN_PROGRESS
      ? {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftErrorText: t('other.registration_in_progress'),
          },
        }
      : null;

  const checkUserHasOtherShift = (
    kkmItem: Kkm,
    userItem: User,
    baseStatus: ShiftStatus,
  ): CheckResult =>
    userItem.Lock && !kkmItem.Lock
      ? {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftErrorText: t('other.open_shift_on_another_cashier'),
          },
        }
      : null;

  const checkKkmHasShift = async (
    kkmItem: Kkm,
    userItem: User,
    baseStatus: ShiftStatus,
  ): Promise<CheckResult> => {
    if (!kkmItem.Lock) return null;

    if (userItem.Lock) {
      if (kkmItem.IdShift !== userItem.IdShift) {
        return {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftOpenByAnotherCashier: true,
            shiftErrorText: t('shift.shift_opened_by_cashier', { user: userItem.Name }),
          },
        };
      }

      try {
        const shiftResponse = await getShift(kkmItem.IdShift).unwrap();
        const shift = shiftResponse.Data;

        const currentDate = new Date();
        const shiftOpenDate = new Date(shift.DateOpen);
        const shiftExpired =
          (currentDate.getTime() - shiftOpenDate.getTime()) / 3600000 > SHIFT_EXPIRED_DIFFERENCE;

        if (shiftExpired) {
          return {
            shift,
            shiftStatus: {
              ...baseStatus,
              shiftExpired: true,
              shiftOpen: true,
              shiftErrorText: t('other.expired_shift'),
            },
          };
        }

        if (currentDate < shiftOpenDate) {
          return {
            shift,
            shiftStatus: {
              ...baseStatus,
              shiftExpired: true,
              shiftOpen: true,
              shiftErrorText: t('other.incorrect_device_time'),
            },
          };
        }

        return { shift, shiftStatus: { ...baseStatus, shiftOpen: true } };
      } catch {
        return {
          shift: null,
          shiftStatus: {
            ...baseStatus,
            shiftBlocked: true,
            shiftOpen: true,
            shiftErrorText: t('other.device_blocking'),
          },
        };
      }
    }

    return {
      shift: null,
      shiftStatus: {
        ...baseStatus,
        shiftBlocked: true,
        shiftOpenByAnotherCashier: true,
        shiftErrorText: t('shift.shift_opened_by_cashier', { user: userItem.Name }),
      },
    };
  };

  const finalNoShift = (baseStatus: ShiftStatus): CheckResult => ({
    shift: null,
    shiftStatus: { ...baseStatus, shiftOpen: false },
  });

  const checkShift = async ({
    kkmItem,
    userItem,
  }: {
    kkmItem: Kkm;
    userItem: User;
  }): Promise<CheckResult> => {
    const sameShift = kkmItem.Lock && userItem.Lock && kkmItem.IdShift === userItem.IdShift;
    const baseStatus = createShiftStatus({ shiftOpen: sameShift });

    const syncChecks: (() => CheckResult | undefined)[] = [
      () => checkKkmPaid(kkmItem, baseStatus),
      () => checkKkmBlockedByOfd(kkmItem, baseStatus),
      () => checkKkmRegisterInProgress(kkmItem, baseStatus),
      () => checkUserHasOtherShift(kkmItem, userItem, baseStatus),
    ];

    const checkResults = syncChecks.map((check) => check());

    const result = checkResults.find(
      (res): res is CheckResult => res !== null && res !== undefined,
    );

    if (result) return result;

    const asyncRes = await checkKkmHasShift(kkmItem, userItem, baseStatus);

    if (asyncRes) return asyncRes;

    return finalNoShift(baseStatus);
  };

  const updateShiftStatus = ({ kkmItem, userItem }: { kkmItem: Kkm; userItem: User }) => {
    checkShift({
      kkmItem,
      userItem,
    }).then(async (res) => {
      if (!res) {
        return;
      }

      dispatch(setKkmItem(kkmItem));
      dispatch(setShiftStatus(res.shiftStatus));

      if (res.shift) {
        dispatch(setOpenedShift(res.shift));
      }
    });
  };

  const setUserAndShiftStatus = async (kkmId: number, userId: number, isOpenShift?: boolean) => {
    const userResponse = await getUser(userId).unwrap();

    const kkmResponse = await getKkm(kkmId).unwrap();

    if (userResponse && kkmResponse) {
      dispatch(setUser(userResponse.Data));

      if (isOpenShift) {
        updateShiftStatus({ kkmItem: kkmResponse.Data.Kkm, userItem: userResponse.Data });
      } else {
        dispatch(setOpenedShift(null));
        dispatch(setOpenedBalances(null));
      }
    }
  };

  const toggleShift = async (kkm: Kkm, isOpenShift?: boolean) => {
    if (isOpenShift) {
      await openShift({
        idKkm: kkm.Id,
      }).unwrap();

      dispatch(fiscalApi.util.resetApiState());
    } else {
      goToCloseShift(kkm.Id);
    }

    if (!user) {
      return;
    }

    setUserAndShiftStatus(kkm.Id, user.Id, isOpenShift);
  };

  const setCurrentShift = async ({ idShift }: { idShift: number }) => {
    if (currentShift?.Id === idShift) {
      return;
    }

    const shiftResponse = await getShift(idShift).unwrap();

    if (shiftResponse) {
      dispatch(setShift(shiftResponse.Data));
    }
  };

  return {
    checkShift,
    updateShiftStatus,
    toggleShift,
    setUserAndShiftStatus,
    setCurrentShift,
    loadingOpenShift,
  };
};

export { useShift };
