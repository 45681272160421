import { Outlet } from 'react-router-dom';

import { Footer } from '../MainLayout/Footer';

import LogoFiscal from '@/assets/images/full-logo.svg?react';

const BillLayout = () => {
  return (
    <div className="flex flex-col justify-between items-center min-h-[calc(100vh-_var(--safe-area-inset-top))] relative bg-lightGray text-center">
      <LogoFiscal className="h-8 text-black mt-8 mx-auto" />
      <div className="flex-shrink">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export { BillLayout };
