import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, type ReactNode } from 'react';
import { useToggle } from 'react-use';

import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props = {
  btnName: string;
  children: ReactNode;
  btnClassName?: string;
  containerClassName?: string;
  allOpened?: boolean;
};
const DisclosureCustom = ({
  btnName,
  children,
  btnClassName,
  containerClassName,
  allOpened,
}: Props) => {
  const [isOpen, toogleOpen] = useToggle(allOpened || false);

  useEffect(() => {
    toogleOpen(allOpened);
  }, [allOpened, toogleOpen]);

  return (
    <>
      <div className="hidden lg:flex flex-col gap-2 print:flex">
        <div className={twMergeClsx('font-semibold', btnClassName)}>{btnName}</div>
        {children}
      </div>
      <div className="lg:hidden print:hidden">
        <Disclosure>
          <DisclosureButton
            className={twMergeClsx(
              'flex items-center gap-2 justify-between font-semibold w-full',
              btnClassName,
            )}
            onClick={toogleOpen}
          >
            {btnName}
            <ChevronDownIcon className={twMergeClsx('w-5 transition', isOpen && 'rotate-180')} />
          </DisclosureButton>

          <DisclosurePanel
            static
            unmount={false}
            className={twMergeClsx(
              'origin-top transition duration-200 ease-out',
              !isOpen && 'h-0 opacity-0 invisible mt-0',
              isOpen && containerClassName,
            )}
          >
            {children}
          </DisclosurePanel>
        </Disclosure>
      </div>
    </>
  );
};

export { DisclosureCustom };
