import { useMemo } from 'react';

import { CustomTabs } from '../../shared/Tabs';

import { ArticlesSettings } from './Articles';
import { CashierSettings } from './Cashier';
import { SettingsError } from './Error';
import useContainer from './hook';
import { KassaSettings } from './Kassa';
import { OfdSettings } from './Ofd';
import { SectionsSettings } from './Sections';

import { SETTINGS_TABS } from '@/constants/settings';
import { BackButtonWithHeader } from '@/views/shared/BackButtonWithHeader';

const Settings = () => {
  const {
    t,
    headSnackbarName,
    shiftStatus,
    activeTabId,
    kkmId,
    setActiveTabId,
    goToShift,
    refetch,
    refetchSections,
  } = useContainer();

  const tabList = useMemo(
    () => [
      {
        id: SETTINGS_TABS.KASSA,
        label: t('kassa_settings.cash_management'),
        Component: <KassaSettings onSave={refetch} />,
      },
      {
        id: SETTINGS_TABS.OFD,
        label: t('kassa_settings.ofd_settings'),
        Component: <OfdSettings onSave={refetch} />,
      },
      {
        id: SETTINGS_TABS.SECTIONS,
        label: t('kassa_settings.sections'),
        Component: <SectionsSettings refetch={refetchSections} />,
      },
      {
        id: SETTINGS_TABS.CASHIER,
        label: t('kassa_settings.cashiers'),
        Component: <CashierSettings />,
      },
      {
        id: SETTINGS_TABS.NOMENCLATURE,
        label: t('kassa_settings.nom'),
        Component: <ArticlesSettings />,
      },
    ],
    [refetch, refetchSections, t],
  );

  return (
    <div>
      <div className="mb-10 flex gap-4 items-center justify-between">
        <BackButtonWithHeader onClick={() => goToShift(kkmId)} title="kassa.settings" />
        <div className="rounded-lg bg-lightGray-five py-1 px-4 text-base hidden lg:block">
          {headSnackbarName}
        </div>
      </div>
      {shiftStatus?.shiftOpen || shiftStatus?.shiftOpenByAnotherCashier ? (
        <SettingsError />
      ) : (
        <CustomTabs
          tabList={tabList}
          activeTabId={activeTabId}
          onChange={setActiveTabId}
          tabClassName="[&:nth-child(3)]:border-l-0 md:[&:nth-child(3)]:border-l basis-1/2 md:basis-1/3 lg:w-auto last:border-l-0 md:last:border-l md:[&:nth-child(4)]:border-l-0 lg:[&:nth-child(4)]:border-l lg:basis-[content] lg:w-full"
        />
      )}
    </div>
  );
};

export { Settings };
