import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { ArrowDownIcon, ArrowUpIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Button } from '../Button';

import { ROUTES } from '@/constants';
import { useAppSelector } from '@/hooks';
import type { AppRoutes } from '@/types';
import { ButtonKinds } from '@/types';
import { buildAppRouteUrl } from '@/utils';

const OperationsMenuModal = () => {
  const { openedShift } = useAppSelector((state) => state.shift);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!openedShift) {
    return null;
  }

  const goToOperation = (link: AppRoutes) => {
    navigate(buildAppRouteUrl(link, { idKkm: openedShift.IdKkm }));
    toggleIsOpen();
  };

  return (
    <>
      <button type="button" className="w-11 h-11 bg-blue rounded-full" onClick={toggleIsOpen}>
        <PlusIcon className="w-6 h-6 mx-auto text-white" />
      </button>
      <Dialog
        open={isOpen}
        onClose={toggleIsOpen}
        transition
        className="fixed z-50 inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30" />
        <div className="fixed inset-0 flex w-screen items-end justify-center">
          <DialogPanel className="bg-white rounded-t-lg w-full p-4 flex flex-col gap-3">
            <Button
              onClick={() => goToOperation(ROUTES.SALE)}
              className="w-full"
              text={t('main.new_sale')}
            />
            <Button
              onClick={() => goToOperation(ROUTES.PURCHASE)}
              className="w-full"
              kind={ButtonKinds.secondary}
              text={t('main.purchase')}
            />
            <div className="flex gap-3 flex-1">
              <Button
                onClick={() => goToOperation(ROUTES.INCOME)}
                className="w-full h-[64px] flex-col"
                Icon={<ArrowDownIcon className="w-4 h-4 text-darkGray-five" />}
                kind={ButtonKinds.secondary}
                text={t('main.income')}
              />
              <Button
                onClick={() => goToOperation(ROUTES.EXPENSE)}
                className="w-full h-[64px] flex-col"
                Icon={<ArrowUpIcon className="w-4 h-4 text-darkGray-five" />}
                kind={ButtonKinds.secondary}
                text={t('main.expense')}
              />
              <Button
                onClick={() => goToOperation(ROUTES.PURCHASE_REFUND)}
                className="w-full h-[64px] flex-col"
                Icon={<ArrowUturnLeftIcon className="w-4 h-4 text-darkGray-five" />}
                kind={ButtonKinds.secondary}
                text={t('main.refund')}
              />
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export { OperationsMenuModal };
