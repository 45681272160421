import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { OperationType } from '@/constants/operations';
import { useNavigation } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useLazyGetUidQuery } from '@/store/auth/service';
import { setOpenedBalances } from '@/store/balance/slice';
import {
  useRefundPurchaseMutation,
  useRefundSaleMutation,
  useSendPurchaseMutation,
  useSendSaleMutation,
} from '@/store/operations/service';
import { setOperationErrors } from '@/store/operations/slice';
import type { SaleSendForm } from '@/types/form';
import { deleteTrimFromString } from '@/utils/delete-string-trim';
import { filterPositionsForRefund } from '@/utils/operations';

const paymentMethods: ('cash' | 'nonCash' | 'mobile')[] = ['cash', 'nonCash', 'mobile'];

export type Props = {
  operationType: OperationType;
  withSimpleMode?: boolean;
};

const useContainer = ({ operationType }: Props) => {
  const dispatch = useAppDispatch();
  const { positions, isSimpleMode } = useAppSelector((state) => state.operations);
  const [sendSale, { isLoading: isLoadingSale }] = useSendSaleMutation();
  const [sendPurchase, { isLoading: isLoadingPurchase }] = useSendPurchaseMutation();
  const [refundPurchase, { isLoading: isLoadingPurchaseRefund }] = useRefundPurchaseMutation();
  const [refundSale, { isLoading: isLoadingRefundSale }] = useRefundSaleMutation();
  const { operationInfo, SN } = useAppSelector((state) => state.operations);
  const { openedShift } = useAppSelector((state) => state.shift);
  const { goToOperationSuccess } = useNavigation();

  const [getUid] = useLazyGetUidQuery();
  const params = useParams();
  const idKkm = Number(params?.idKkm);
  const [showReportOperationsModal, setShowReportOperationsModal] = useState(false);

  const [selectedOperationId, setSelectedOperationId] = useState(0);

  const showOperationDuplicateModal = useCallback(
    (reportId: number) => {
      if (!openedShift) {
        return;
      }

      goToOperationSuccess(openedShift.IdKkm, reportId);
    },
    [openedShift?.IdKkm],
  );

  const handleCloseReportOperationsModal = useCallback(() => {
    setShowReportOperationsModal(false);
    setSelectedOperationId(0);
  }, []);

  const form = useForm<SaleSendForm>({
    defaultValues: {
      cash: '',
      nonCash: '',
      mobile: '',
      iin: '',
    },
  });

  const { setValue, watch } = form;

  const formChanges = watch();

  const result = useMemo(() => {
    const total = positions.reduce((a, b) => (b.storno ? a : a + b.total), 0);
    const currentValue =
      Number(formChanges.cash) + Number(formChanges.mobile) + Number(formChanges.nonCash);

    return {
      total,
      currentValue,
      changes: total - currentValue < 0 ? Math.abs(total - currentValue) : 0,
    };
  }, [positions, formChanges]);

  const isValidForm = useMemo(() => {
    return result.currentValue < 1 || result.total > result.currentValue || positions.length < 1;
  }, [result, positions]);

  useEffect(() => {
    setValue('cash', result.total > 0 ? String(result.total) : '');
    setValue('nonCash', '');
  }, [result.total, setValue]);

  const swap = useCallback(() => {
    const { cash, nonCash } = formChanges;

    setValue('nonCash', cash);
    setValue('cash', nonCash);
  }, [formChanges, setValue]);

  const salePositions = useMemo(() => {
    return positions.map((item) => ({
      Discount: item.discount,
      IdSection: item.idSection,
      IdUnit: item.idUnit,
      IsFixedDiscountOrMarkup: item.isFixedDiscountOrMarkup,
      Markup: item.markup,
      Name: item.name,
      Price: item.price,
      ProductCode: item.productCode,
      Qty: item.qty,
      Storno: item.storno,
    }));
  }, [positions]);

  const onSubmit: SubmitHandler<SaleSendForm> = async (data) => {
    if (!positions[0]) return;

    try {
      const uidResponse = await getUid(idKkm).unwrap();
      let operationResponse = null;

      const operationBody = {
        Amount: 0,
        Cash: Number(data.cash),
        CustomerIin: data.iin || '',
        GenerateCheck: true,
        IdDomain: positions[0].idDomain,
        IdKkm: idKkm,
        Mobile: Number(data.mobile),
        NonCash: Number(data.nonCash),
        Total: result.total,
        Positions: salePositions,
        Uid: uidResponse.Data.Uid,
      };

      if (operationType === OperationType.SALE) {
        operationResponse = await sendSale({
          IdKkm: idKkm,
          Uid: uidResponse.Data.Uid,
          body: operationBody,
        }).unwrap();
      } else if (operationType === OperationType.PURCHASE) {
        operationResponse = await sendPurchase({
          IdKkm: idKkm,
          Uid: uidResponse.Data.Uid,
          body: operationBody,
        }).unwrap();
      } else if (
        operationType === OperationType.PURCHASE_REFUND ||
        operationType === OperationType.REFUND
      ) {
        operationBody.Cash = operationInfo?.Document.NonCash === 0 ? Number(data.cash) : 0;
        operationBody.NonCash =
          operationInfo?.Document.NonCash && operationInfo?.Document.NonCash > 0
            ? Number(data.cash)
            : 0;
        operationBody.Positions = filterPositionsForRefund(salePositions);
        operationBody.CustomerIin = deleteTrimFromString(operationBody.CustomerIin);

        const { Uid, IdKkm, ...operationBodyWithoudUid } = operationBody;
        const updatedOperationBody = { ...operationBodyWithoudUid, SN };

        if (operationType === OperationType.PURCHASE_REFUND) {
          await refundPurchase({
            IdKkm: idKkm,
            Uid: uidResponse.Data.Uid,
            body: updatedOperationBody,
          })
            .unwrap()
            .then((response) => {
              if (response) {
                showOperationDuplicateModal(response.Data.IdDocument);
              }
            })
            .catch((e) => {
              console.log(e);
              dispatch(setOperationErrors({ text: e.data.Message }));
            });
        } else if (operationType === OperationType.REFUND) {
          await refundSale({
            IdKkm: idKkm,
            Uid: uidResponse.Data.Uid,
            body: updatedOperationBody,
          })
            .unwrap()
            .then((response) => {
              if (response) {
                showOperationDuplicateModal(response.Data.IdDocument);
              }
            })
            .catch((e) => {
              console.log(e);
              dispatch(setOperationErrors({ text: e.data.Message }));
            });
        }
      }

      if (operationResponse) {
        dispatch(setOpenedBalances(operationResponse.Data.Balances));
        showOperationDuplicateModal(operationResponse.Data.IdDocument);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTabClick = (index: number) => {
    paymentMethods.forEach((idx) => setValue(idx, ''));
    setValue(paymentMethods[index], String(result.total));
  };

  return {
    result,
    isSimpleMode,
    isLoading: isLoadingSale || isLoadingPurchase || isLoadingPurchaseRefund || isLoadingRefundSale,
    isValidForm,
    form,
    showReportOperationsModal,
    selectedOperationId,
    onSubmit,
    swap,
    showOperationDuplicateModal,
    handleCloseReportOperationsModal,
    handleTabClick,
  };
};

export { useContainer };
