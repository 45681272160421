import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from './useRedux';

import { useGetKkmChartQuery } from '@/store/cashbox/service';
import { setKkmChartData, setTotalReceiptCount } from '@/store/cashbox/slice';

const useReceiptData = () => {
  const { openedShift } = useAppSelector((state) => state.shift);
  const dispatch = useAppDispatch();

  const { data: chartData } = useGetKkmChartQuery(openedShift?.IdKkm || 0, {
    skip: !openedShift,
  });

  useEffect(() => {
    if (chartData?.Data) {
      const kkmChartData = chartData?.Data;

      dispatch(setKkmChartData(kkmChartData));

      const totalReceiptCount =
        (kkmChartData?.Refunds.TotalReceipts || 0) + (kkmChartData?.Sells.TotalReceipts || 0);

      dispatch(setTotalReceiptCount(totalReceiptCount));
    }
  }, [chartData, dispatch]);
};

export { useReceiptData };
