import { Field, Label, Switch } from '@headlessui/react';

type Props = {
  label: string;
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const SimpleSwitch = ({ label, name, checked, onChange }: Props) => {
  return (
    <Field className="flex justify-between items-center gap-2">
      <Label className="md:text-sm text-xs text-right whitespace-pre-line">{label}</Label>

      <Switch
        name={name}
        checked={checked}
        onChange={onChange}
        className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition border border-darkGray-five/50"
      >
        <span className="size-4 translate-x-1 rounded-full bg-lightGray-second transition group-data-[checked]:translate-x-6 group-data-[checked]:bg-blue " />
      </Switch>
    </Field>
  );
};

export { SimpleSwitch };
