import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddPosition } from '../modules/AddPosition';
import { ErrorModal } from '../modules/ErrorModal';
import { OperationHead } from '../modules/Head';
import { Pays } from '../modules/Pays';
import { PreCheck } from '../PreCheck';

import { ERROR_DEFAULT_STATE, OperationType } from '@/constants/operations';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setOperationErrors } from '@/store/operations/slice';

const Sale = () => {
  const { isSimpleMode, errors } = useAppSelector((state) => state.operations);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCloseErrorModal = useCallback(() => {
    dispatch(setOperationErrors(ERROR_DEFAULT_STATE));
  }, [dispatch]);

  return (
    <div>
      <OperationHead withSimpleMode title={t('receipt.new_sale')} />
      <ErrorModal onClose={handleCloseErrorModal} isOpen={!!errors.text} error={errors} />
      {isSimpleMode ? (
        <div className="flex flex-col gap-2 bg-white lg:max-w-[360px] w-full mx-auto">
          <AddPosition operationType={OperationType.SALE} withSimpleMode />
          <Pays operationType={OperationType.SALE} withSimpleMode />
        </div>
      ) : (
        <div className="flex flex-1 lg:flex-row flex-col items-start md:gap-6 gap-3">
          <AddPosition operationType={OperationType.SALE} />
          <PreCheck />
          <Pays operationType={OperationType.SALE} />
        </div>
      )}
    </div>
  );
};

export { Sale };
