import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { useAppSelector } from '@/hooks/useRedux';
import { useGetSectionsReportQuery } from '@/store/reports/service';

const useContainer = () => {
  const { t } = useTranslation();
  const [isOpenSections, setIsOpenSections] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const { shift } = useAppSelector((state) => state.shift);

  const { data, isLoading, isFetching } = useGetSectionsReportQuery(shift?.Id || 0, {
    skip: !shift?.Id,
  });
  const reportSections = useMemo(() => data?.Data?.Sections || {}, [data?.Data?.Sections]);

  const sectionSubtitles = [
    t('report.sales'),
    t('report.sales_refunds'),
    t('report.purchase_refunds'),
    t('report.service_income'),
    t('report.service_expense'),
  ];

  const printSection = async () => {
    await new Promise((resolver) => {
      setIsOpenSections(true);

      setTimeout(resolver, 500);
    });
  };

  return {
    sectionSubtitles,
    reportSections,
    printRef,
    isLoading: isFetching || isLoading,
    t,
    isOpenSections,
    printSection,
    reactToPrintFn,
  };
};

export default useContainer;
