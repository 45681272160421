import { CapacitorBarcodeScanner } from '@capacitor/barcode-scanner';

export const openScanBarcode = async () => {
  try {
    const result = await CapacitorBarcodeScanner.scanBarcode({
      hint: 3,
      cameraDirection: 1,
    });

    return result.ScanResult;
  } catch (e) {
    console.log(e);
  }

  return false;
};
