import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks/useRedux';
import { getFormattedFullDate } from '@/utils/dates';
import { formatAmount } from '@/utils/format-amount';
import { DefinitionSection } from '@/views/shared/Definition/DefinitionSection';
import { Table } from '@/views/shared/Table';

const OperationsList = () => {
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);
  const tableHead = useMemo(
    () => [
      { label: t('report.operation_type'), code: 'DocumentType' },
      { label: t('report.date'), code: 'Date' },
      { label: t('report.receipt_number'), code: 'ReceiptNumber' },
      { label: t('report.amount'), code: 'Amount' },
    ],
    [t],
  );
  const rowsList = useMemo(() => {
    return (
      selectedZReportData?.Operations?.map((operation) => ({
        DocumentType: operation.TypeDocument.Name,
        Date: getFormattedFullDate(operation.DateDocument),
        ReceiptNumber: operation.NumberDoc || t('other.not_specified'),
        Amount: formatAmount(operation.Value, true),
      })) || []
    );
  }, [selectedZReportData?.Operations, t]);

  if (!selectedZReportData?.Operations) {
    return (
      <div className="mt-4">
        <DefinitionSection title={t('report.operation_list')}>
          <div className="p-3">{t('report.no_operations')}</div>
        </DefinitionSection>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <DefinitionSection title={t('report.operation_list')}>
        <Table
          heads={tableHead}
          rows={rowsList}
          classCellName="[&:nth-child(1)]:basis-1/2 [&:nth-child(2)]:text-darkGray [&:nth-child(1)]:lg:w-auto [&:nth-child(2)]:lg:text-black "
        />
      </DefinitionSection>
    </div>
  );
};

export { OperationsList };
