import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import useContainer from './hook';

import LogoGray from '@/assets/images/logo-gray.svg?react';
import { NODE_ENV } from '@/constants/app';
import { PULT_URL } from '@/constants/routes';
import { ButtonType } from '@/types/button';
import { Footer } from '@/views/layout/MainLayout/Footer';
import { LangChecker } from '@/views/layout/MainLayout/LangChecker';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import { MaskedInput } from '@/views/shared/Form/MaskInput';

const LoginForm = () => {
  const {
    isError,
    methods,
    isLoading,
    errors,
    control,
    reset,
    watch,
    handleSubmit,
    register,
    signIn,
    t,
  } = useContainer();

  return (
    <div className="flex-grow basis-0 flex flex-col justify-between items-center h-screen">
      <div className="my-7 lg:self-end px-7">
        <LangChecker />
        <div className="mt-7 lg:hidden">
          <LogoGray />
        </div>
      </div>

      <div className="flex w-80 text-center">
        <Form onSubmit={handleSubmit(signIn)} form={methods}>
          <Fragment>
            <h1 className="text-2xl text-darkGray-fourth font-semibold">{t('auth.log_in')}</h1>
            <div className="mt-7 mb-10 flex flex-col gap-2 text-left">
              <div className="relative">
                <MaskedInput
                  format="+7 ### ### ## ##"
                  mask=""
                  control={control}
                  name="phone"
                  aria-invalid={!!(errors.phone || isError)}
                  placeholder="+7 (000) 000 00 00"
                  label={t('auth.phone_number')}
                  errorText="other.incorrect_login_or_password"
                />
                {watch('phone').replace(/\s/g, '').length > 2 && (
                  <XMarkIcon
                    onClick={() => reset({ phone: '' })}
                    className="text-lightGray-third w-5 absolute right-0 top-1/2 mr-3 cursor-pointer"
                  />
                )}
              </div>

              <div className="relative">
                <Link
                  to={PULT_URL[NODE_ENV].FORGOT_PASSWORD}
                  className="text-blue absolute right-0 cursor-pointer z-[1]"
                  target="_blank"
                >
                  {t('auth.forgot_password')}
                </Link>
                <Input
                  id="password"
                  register={register('password')}
                  type="password"
                  aria-invalid={!!(errors.password || isError)}
                  placeholder={t('auth.password')}
                  label={t('auth.password')}
                  errorText={errors.password?.message || t('other.incorrect_login_or_password')}
                />
              </div>
            </div>
            <Button
              className="!w-full"
              disabled={isLoading}
              text="auth.log_in"
              isLoading={isLoading}
              type={ButtonType.submit}
            />
            <Link
              className="block mt-5 text-lg text-darkGray font-semibold hover:text-blue"
              to={PULT_URL[NODE_ENV].REGISTER}
              target="_blank"
            >
              {t('auth.register')}
            </Link>
          </Fragment>
        </Form>
      </div>
      <Footer />
    </div>
  );
};

export { LoginForm };
