import { useTranslation } from 'react-i18next';
import type { TooltipProps } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import type { ChartOperation } from '@/types/cashbox';

const OperationInfo = ({ name, value }: { name: string; value: string | number }) => {
  return (
    <dl>
      <dt className="text-[10px] text-white/50">{name}</dt>
      <dd>{value} ₸</dd>
    </dl>
  );
};

const CustomizedTooltip = ({ payload }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#343434] p-4 rounded text-white text-xs">
      {payload?.map((p) => {
        const customPayload = p.payload as ChartOperation;

        return p.name === 'SellTotal' ? (
          <div key={p.dataKey}>
            <div className="mb-2">
              <p className="text-sm font-medium">{t('kassa.sales')}</p>
              {/* <div className={style.amount}>1 чек</div> */}
            </div>
            <div className="grid grid-cols-4 gap-2 pb-4 border-b border-white/20">
              <OperationInfo name={t('kassa.non_cash')} value={customPayload.SellCash} />
              <OperationInfo
                name={t('kassa.top_sales_of_shift')}
                value={customPayload.SellNonCash}
              />
              <OperationInfo name={t('kassa.transfer')} value={customPayload.SellMobile} />
              <OperationInfo name={t('kassa.total')} value={customPayload.SellTotal} />
            </div>
          </div>
        ) : (
          <div key={p.dataKey} className="mt-4">
            <div className="mb-2">
              <p className="text-sm font-medium">{t('kassa.refunds')}</p>
              {/* <div className={style.amount}>1 чек</div> */}
            </div>
            <div className="grid grid-cols-4 gap-2">
              <OperationInfo name={t('kassa.cash')} value={customPayload.RefundCash} />
              <OperationInfo name={t('kassa.non_cash')} value={customPayload.RefundNonCash} />
              <OperationInfo name={t('kassa.transfer')} value={customPayload.RefundMobile} />
              <OperationInfo name={t('kassa.total')} value={customPayload.RefundTotal} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { CustomizedTooltip };
