export const getBase64Img = (base: string) => {
  return `data:image/png;base64, ${base}`;
};

export const getFileFormattedSize = (size: number) => {
  const sizeBytes = size;

  if (!+size) return '0 B';

  const k = 1024;
  const dm = 1;
  const sizes = ['B', 'Kb', 'Mb'];

  const i = Math.floor(Math.log(sizeBytes) / Math.log(k));

  return `${parseFloat((sizeBytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
