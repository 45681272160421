import { useCallback, useRef, useState } from 'react';

import ArrowRightSLine from '@/assets/icons/arrow-right-s-line.svg?react';
import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props = {
  onChange: (value: string | null) => void;
  className?: string;
  placeholder?: string;
};

const SearchPageInput = ({ className, placeholder, onChange }: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);

  const onFocus = () => {
    ref.current?.focus();
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const handleChangePageNum = useCallback(() => {
    onChange(ref?.current?.value ?? null);
  }, [onChange]);

  return (
    <div
      className={twMergeClsx(
        'border border-lightGray-second rounded-lg overflow-hidden flex h-[30px] transition-shadow w-20',
        focus && 'ring-1 ring-blue',
        className,
      )}
    >
      <input
        ref={ref}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        className="outline-none px-4 w-[50px]"
      />
      <button
        onClick={handleChangePageNum}
        type="button"
        className="border-l border-lightGray-second w-[30px] bg-lightGray hover:bg-lightGray-fourth"
      >
        <ArrowRightSLine className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
      </button>
    </div>
  );
};

export { SearchPageInput };
