import { useCallback, useMemo } from 'react';

import { TotalItem } from '../TotalItem';

import useContainer from './hook';

import ArrowDownCircleLine from '@/assets/icons/arrow-down-circle-line.svg?react';
import ArrowRightSLine from '@/assets/icons/arrow-right-s-line.svg?react';
import ArrowUpCircleLine from '@/assets/icons/arrow-up-circle-line.svg?react';
import ArrowUpDownFill from '@/assets/icons/arrow-up-down-fill.svg?react';
import DownloadFill from '@/assets/icons/download-fill.svg?react';
import ReplyFill from '@/assets/icons/reply-fill.svg?react';
import ReplyMirrorFill from '@/assets/icons/reply-mirror-fill.svg?react';
import { useAppDispatch } from '@/hooks/useRedux';
import { setSelectedZReportsData } from '@/store/reports/slice';
import { ButtonKinds } from '@/types/button';
import { getFormattedFullDate } from '@/utils/dates';
import { formatAmount } from '@/utils/format-amount';
import { Button } from '@/views/shared/Button';
import { DashedItem } from '@/views/shared/DashedItem';
import { Table } from '@/views/shared/Table';
import type { Items } from '@/views/shared/Table/hook';

const ZReportList = () => {
  const dispatch = useAppDispatch();
  const { t, tableHead, reportZTotalAmounts, zReportList, isMobile } = useContainer();

  const getMobileRows = useCallback(() => {
    const newData: Items[] = [];
    zReportList?.map((report) =>
      newData.push({
        ShiftIndex: (
          <div className="font-semibold">
            {report.ShiftIndex} {t('report.shift')}
          </div>
        ),
        DateOpen: (
          <span className="text-darkGray flex items-center text-xs justify-end">
            {getFormattedFullDate(report.DateOpen)}
            <ArrowRightSLine />
            <span className="text-darkGray text-xs">{getFormattedFullDate(report.DateOpen)}</span>
          </span>
        ),
        DateClose: '',
        BalanceOpen: (
          <DashedItem label="report.received" value={formatAmount(report.BalanceOpen, true)} />
        ),
        BalanceClose: (
          <DashedItem label="report.handed_over" value={formatAmount(report.BalanceClose, true)} />
        ),
        Count: (
          <div className="text-darkGray">
            {t('report.operations')}
            {report.Count} • {t('main.cashier')}: {report.User.Name}
          </div>
        ),
        CashierName: '',
        Operations: (
          <Button
            kind={ButtonKinds.secondaryGray}
            onClick={() => dispatch(setSelectedZReportsData(report))}
            className="text-sm py-1 w-full"
          >
            <span>{t('report.duplicate')}</span>
          </Button>
        ),
      }),
    );

    return newData;
  }, [dispatch, t, zReportList]);
  const getRows = useCallback(() => {
    const newData: Items[] = [];
    zReportList?.map((report) =>
      newData.push({
        ShiftIndex: report.ShiftIndex,
        DateOpen: getFormattedFullDate(report.DateOpen),
        DateClose: getFormattedFullDate(report.DateOpen),
        BalanceOpen: report.BalanceOpen,
        BalanceClose: report.BalanceClose,
        Count: report.Count,
        CashierName: report.User.Name,
        Operations: (
          <Button
            kind={ButtonKinds.secondaryGray}
            onClick={() => dispatch(setSelectedZReportsData(report))}
            className="text-sm py-1 basis-1/2 "
          >
            <span>{t('report.duplicate')}</span>
          </Button>
        ),
      }),
    );

    return newData;
  }, [dispatch, t, zReportList]);

  const tableTotalsContent = useMemo(
    () => (
      <div className="flex gap-2 items-center overflow-x-auto lg:overflow-hidden">
        <TotalItem
          label="report.sale"
          icon={<ArrowUpCircleLine />}
          amount={reportZTotalAmounts.SalesAmount}
        />
        <TotalItem
          label="report.purchase"
          icon={<ArrowDownCircleLine />}
          amount={reportZTotalAmounts.PurchasesAmount}
        />
        <TotalItem
          label="report.income"
          icon={<DownloadFill />}
          amount={reportZTotalAmounts.IncomesAmount}
        />
        <TotalItem
          label="report.expense_and_indexation"
          icon={<ArrowUpDownFill />}
          amount={reportZTotalAmounts.ExpensesAmount}
        />
        <TotalItem
          label="report.sales_return"
          icon={<ReplyFill />}
          amount={reportZTotalAmounts.RefundsAmount}
        />
        <TotalItem
          label="report.refund_of_purchase"
          icon={<ReplyMirrorFill />}
          amount={reportZTotalAmounts.PurchaseRefundsAmount}
        />
      </div>
    ),
    [reportZTotalAmounts],
  );

  if (!zReportList?.length) {
    return (
      <p className="mt-4 text-darkGray text-center">
        {t('report.no_z_reports_found_for_current_date')}
      </p>
    );
  }

  return (
    <div>
      <Table
        heads={tableHead}
        rows={isMobile ? getMobileRows() : getRows()}
        contentBeforeHead={tableTotalsContent}
        className="tableZRowList"
      />
    </div>
  );
};

export { ZReportList };
