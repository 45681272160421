import type { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import type { EXPORT_FILE_TYPES } from '@/constants/export';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { ExportExcelButton } from '@/views/shared/ExportExcelButton';
import { PeriodFilter } from '@/views/shared/PeriodFilter';
import { SearchInput } from '@/views/shared/SearchInput';

type Props = {
  totalCount: number;
  searchValue?: string | number;
  withSearch?: boolean;
  exportFileName: string;
  exportType: (typeof EXPORT_FILE_TYPES)[keyof typeof EXPORT_FILE_TYPES];
  onSearch?: ChangeEventHandler<HTMLInputElement>;
};

const FilterPanel = ({
  searchValue,
  totalCount,
  withSearch = true,
  exportFileName,
  exportType,
  onSearch,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={twMergeClsx(
        'flex justify-between items-center my-4 flex-col lg:flex-row gap-2',
        !withSearch && 'justify-end',
      )}
    >
      {withSearch && onSearch && (
        <SearchInput
          value={searchValue || ''}
          withoutHide
          onChange={onSearch}
          placeholder={t('report.search_on_receipt_number')}
          className="w-full lg:w-auto"
        />
      )}

      <div className="flex gap-2 flex-col lg:flex-row lg:gap-4 w-full lg:w-auto">
        <div className="order-2 lg:order-1">
          <PeriodFilter />
        </div>
        <ExportExcelButton
          disabled={!totalCount}
          className="flex w-full lg:w-auto order-1 lg:order-2"
          fileName={exportFileName}
          type={exportType}
        />
      </div>
    </div>
  );
};

export { FilterPanel };
