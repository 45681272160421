import { combineReducers } from '@reduxjs/toolkit';

import app from './app/slice';
import balances from './balance/slice';
import cashbox from './cashbox/slice';
import companies from './companies/slice';
import { fiscalApi } from './fiscalApi';
import operations from './operations/slice';
import { pultApi } from './pultApi';
import reports from './reports/slice';
import settings from './settings/slice';
import shift from './shift/slice';
import user from './user/slice';

const rootReducer = combineReducers({
  app,
  cashbox,
  companies,
  user,
  shift,
  reports,
  balances,
  settings,
  operations,
  [fiscalApi.reducerPath]: fiscalApi.reducer,
  [pultApi.reducerPath]: pultApi.reducer,
});

export default rootReducer;
