import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Languages } from '@/constants';
import { useIsMobile, useAppDispatch, useAppSelector } from '@/hooks';
import { useGetUnitsQuery } from '@/store/operations/service';
import { useGetArticlesListQuery, useLazySearchArticlesQuery } from '@/store/settings/service';
import { setArticleList } from '@/store/settings/slice';
import type { ArticleItem } from '@/types';
import { sortArrayByParam } from '@/utils';

const useContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { isMobile } = useIsMobile();
  const kkmId = useMemo(() => Number(params?.idKkm), [params.idKkm]);
  const { refetch, isLoading } = useGetArticlesListQuery(kkmId, {
    skip: !params.idKkm,
  });
  const [searchArticle, { isLoading: isLoadingSearch }] = useLazySearchArticlesQuery();
  useGetUnitsQuery(kkmId, {
    skip: !params.idKkm,
  });

  const { articleList, sectionList, unitList } = useAppSelector((state) => state.settings);
  const { locale } = useAppSelector((state) => state.app);
  const [searchValue, setSearchValue] = useState('');
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [checkedArticle, setCheckedArticle] = useState<ArticleItem | null>(null);
  const [showImportModal, setShowImportModal] = useState(false);

  const totalCount = useMemo(() => articleList?.length || 0, [articleList?.length]);

  const tableHead = useMemo(
    () => [
      {
        label: t('kassa_settings.barcode'),
        code: 'Qr',
      },
      { label: t('kassa_settings.product_name'), code: 'Name' },
      { label: t('kassa_settings.unit_of_measurement'), code: 'Unit' },
      { label: t('kassa_settings.price_alt'), code: 'Price' },
      { label: t('kassa_settings.section'), code: 'Section' },
      { label: t('kassa_settings.discount_alt'), code: 'Discount' },
      { label: t('kassa_settings.markup_alt'), code: 'Markup' },
      { label: t('kassa_settings.in_sale'), code: 'InSale' },
      { label: t('kassa_settings.redact'), code: 'Operations' },
    ],
    [t],
  );

  const handleSearchArticleList = useCallback(() => {
    searchArticle({ idKkm: kkmId, searchValue })
      .unwrap()
      .then((result) => dispatch(setArticleList(result?.Data)))
      .catch((error) => console.log(error));
  }, [dispatch, kkmId, searchArticle, searchValue]);

  const toggleShowEditModal = useCallback(
    () => setShowArticleModal(!showArticleModal),
    [showArticleModal],
  );

  const setArticleAndOpenEditModal = useCallback(
    (article: ArticleItem | null) => {
      setCheckedArticle(article);
      toggleShowEditModal();
    },
    [toggleShowEditModal],
  );
  const getUnit = useCallback(
    (articleUnit: number) => {
      const unitData = unitList?.find((unit) => unit.Id === articleUnit);

      if (unitData) return locale === Languages.RU ? unitData?.NameRU : unitData.NameKAZ;

      return t('other.not_specified');
    },
    [locale, t, unitList],
  );
  const sortedArticles = useMemo(
    () => sortArrayByParam([...articleList], 'Name', true),
    [articleList],
  );

  return {
    t,
    isLoading: isLoading || isLoadingSearch,
    showArticleModal,
    articleList: sortedArticles,
    tableHead,
    totalCount,
    searchValue,
    sectionList,
    unitList,
    checkedArticle,
    isMobile,
    showImportModal,
    setShowArticleModal,
    refetch,
    setSearchValue,
    handleSearchArticleList,
    setArticleAndOpenEditModal,
    toggleShowEditModal,
    getUnit,
    setShowImportModal,
  };
};

export default useContainer;
