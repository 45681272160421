import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { REPORT_DATE_TYPE, REPORT_TYPES } from '@/constants/reports';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useGetOperationsReportMutation } from '@/store/reports/service';
import { clearReportState, setReportsList } from '@/store/reports/slice';
import type { Operations } from '@/types/report';
import { getDataToString } from '@/utils/dates';

const useContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { dateFrom, dateTo, reportList } = useAppSelector((state) => state.reports);
  const { idShift } = useParams();
  const { shift } = useAppSelector((state) => state.shift);
  const [getOperationsList, { isLoading }] = useGetOperationsReportMutation();
  const [operationsList, setOperationsList] = useState<Operations[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(clearReportState());
  }, [dispatch]);

  useEffect(() => {
    if (!shift || shift.Id !== Number(idShift)) {
      return;
    }

    getOperationsList({
      body: {
        FromDate: getDataToString({
          id: REPORT_DATE_TYPE.DATE_FROM,
          date: dateFrom,
          type: REPORT_TYPES.OPERATIONS,
        }),
        ToDate: getDataToString({
          id: REPORT_DATE_TYPE.DATE_TO,
          date: dateTo,
          type: REPORT_TYPES.OPERATIONS,
        }),
      },
      idKkm: shift.IdKkm,
    })
      .unwrap()
      .then((res) => {
        setOperationsList(res.Data.Operations);

        dispatch(setReportsList(res.Data.Operations));
      });
  }, [dateFrom, dateTo, dispatch, getOperationsList, idShift, shift]);

  const handleSearchOperations = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const searchQuery = e.target.value;
      setSearchValue(searchQuery);
      const filteredList = operationsList.filter((reportOperation) =>
        String(reportOperation.Id).includes(searchQuery.toLowerCase()),
      );
      dispatch(setReportsList(filteredList));
    },
    [dispatch, operationsList],
  );

  return {
    t,
    isLoading,
    searchValue,
    reportList,
    handleSearchOperations,
  };
};

export default useContainer;
