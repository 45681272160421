import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { PERIOD_TYPES } from '@/constants/date';
import type { Operations, ZReportData } from '@/types/report';
import { getCurrentDateForFilter } from '@/utils/dates';

const { today, weekdayFromToday } = getCurrentDateForFilter();
type InitialState = {
  periodType: string;
  dateFrom: Date;
  dateTo: Date;
  reportList?: Operations[];
  zReportList?: ZReportData[];
  selectedZReportData?: ZReportData | null;
};

const initialState: InitialState = {
  periodType: PERIOD_TYPES.DEFAULT,
  dateFrom: weekdayFromToday,
  dateTo: today,
  reportList: [],
  selectedZReportData: null,
};

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setDateFilter: (state, { payload }: PayloadAction<InitialState>) => {
      state.periodType = payload.periodType;
      state.dateFrom = payload.dateFrom;
      state.dateTo = payload.dateTo;
    },

    setReportsList: (state, { payload }: PayloadAction<Operations[]>) => {
      state.reportList = payload;
    },
    setZReportsList: (state, { payload }: PayloadAction<ZReportData[]>) => {
      state.zReportList = payload;
    },
    setSelectedZReportsData: (state, { payload }: PayloadAction<ZReportData | null>) => {
      state.selectedZReportData = payload;
    },
    clearReportState: () => {
      return initialState;
    },
  },
});

export default reportSlice.reducer;

export const {
  setDateFilter,
  setReportsList,
  clearReportState,
  setZReportsList,
  setSelectedZReportsData,
} = reportSlice.actions;
