import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { format } from 'date-fns';
import { useCallback } from 'react';

import FileExcel2FillBig from '@/assets/icons/fill-excel-2-fill-big.svg?react';
import { EXPORT_FILE_TYPES } from '@/constants/export';
import { useAppSelector } from '@/hooks/useRedux';
import {
  useDownloadReportFileMutation,
  useDownloadZReportFileMutation,
} from '@/store/reports/service';
import { getFormattedDate } from '@/utils/dates';
import { shareFile } from '@/utils/shareFile';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { Button } from '@/views/shared/Button';

type Props = {
  disabled: boolean;
  fileName: string;
  className: string;
  type: (typeof EXPORT_FILE_TYPES)[keyof typeof EXPORT_FILE_TYPES];
};

const ExportExcelButton = ({ disabled, className, fileName, type }: Props) => {
  const { dateFrom, dateTo } = useAppSelector((state) => state.reports);
  const { shift } = useAppSelector((state) => state.shift);
  const [downloadXLSFile] = useDownloadReportFileMutation();
  const [downloadZReportFile] = useDownloadZReportFileMutation();

  const downloadFile = useCallback(async () => {
    if (!shift) {
      return;
    }

    const dateFromFormat = getFormattedDate(dateFrom);
    const dateToFormat = getFormattedDate(dateTo);
    const currentDateFrom = new Date(dateFrom);
    const currentDateTo = new Date(dateTo);
    currentDateFrom.setHours(0, 0, 0, 0);
    currentDateTo.setHours(23, 59, 0, 0);
    let request;
    // change  to switch case , if more 2 condition
    try {
      if (type === EXPORT_FILE_TYPES.OPERATIONS_REPORT) {
        request = await downloadXLSFile({
          idKkm: shift?.IdKkm,
          dateFrom: format(currentDateFrom, "yyyy-LL-dd'T'HH:mm:ss"),
          dateTo: format(currentDateTo, "yyyy-LL-dd'T'HH:mm:ss"),
        }).unwrap();
      } else if (type === EXPORT_FILE_TYPES.Z_REPORT) {
        request = await downloadZReportFile({
          idKkm: shift?.IdKkm,
          dateFrom: format(currentDateFrom, "yyyy-LL-dd'T'HH:mm:ss"),
          dateTo: format(currentDateTo, "yyyy-LL-dd'T'HH:mm:ss"),
        }).unwrap();
      }

      if (request) {
        const timestamp = new Date().getTime();
        const outputFilename = `${fileName}_${dateFromFormat}-${dateToFormat}-${timestamp}.xlsx`;
        const blob = new Blob([request]);

        if (Capacitor?.isNativePlatform()) {
          await write_blob({
            path: outputFilename,
            directory: Directory.Documents,
            blob,
          });

          const fileUri = await Filesystem.getUri({
            directory: Directory.Documents,
            path: outputFilename,
          });

          await shareFile(fileUri.uri, outputFilename);
        } else {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (e) {
      console.error('Error downloading or saving file:', e);
    }
  }, [dateFrom, dateTo, downloadXLSFile, downloadZReportFile, fileName, shift, type]);

  return (
    <Button
      disabled={disabled}
      className={twMergeClsx('flex w-full lg:w-auto', className)}
      Icon={<FileExcel2FillBig className="fill-white" />}
      text="report.export_to_excel"
      onClick={downloadFile}
    />
  );
};

export { ExportExcelButton };
