import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Shift, ShiftStatus } from '@/types/shift';

type InitialState = {
  shift: Shift | null;
  openedShift: Shift | null;
  shiftStatus: ShiftStatus;
};

const initialState: InitialState = {
  openedShift: null,
  shift: null,
  shiftStatus: {
    shiftOpen: false,
    shiftBlocked: false,
    shiftErrorText: '',
    shiftOpenByAnotherCashier: false,
    shiftExpired: false,
  },
};

const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    setShift: (state, { payload }: PayloadAction<Shift | null>) => {
      state.shift = payload;
    },
    setOpenedShift: (state, { payload }: PayloadAction<Shift | null>) => {
      state.openedShift = payload;
    },
    setShiftStatus: (state, { payload }: PayloadAction<ShiftStatus>) => {
      state.shiftStatus = payload;
    },
    resetShiftStatus: (state) => {
      state.shiftStatus = initialState.shiftStatus;
    },
  },
});

export default shiftSlice.reducer;

export const { setShift, setOpenedShift, setShiftStatus, resetShiftStatus } = shiftSlice.actions;
