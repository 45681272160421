import arrowDown from '@/assets/icons/arrowDown.svg?react';
import bankCardLine from '@/assets/icons/bank-card-line.svg?react';
import barcode from '@/assets/icons/barcode.svg?react';
import copied from '@/assets/icons/copied.svg?react';
import danger from '@/assets/icons/danger.svg?react';
import info from '@/assets/icons/info.svg?react';
import minus from '@/assets/icons/minus.svg?react';
import shiftWarning from '@/assets/icons/newCloseShift.svg?react';
import openShift from '@/assets/icons/open-shift.svg?react';
import plus from '@/assets/icons/plus.svg?react';
import printLine from '@/assets/icons/print-line.svg?react';
import reload from '@/assets/icons/reload.svg?react';
import returnIcon from '@/assets/icons/return.svg?react';
import search from '@/assets/icons/search.svg?react';
import settings from '@/assets/icons/settings.svg?react';
import shiftGreenStatus from '@/assets/icons/shiftGreenStatus.svg?react';
import shiftGreyStatus from '@/assets/icons/shiftGreyStatus.svg?react';
import user from '@/assets/icons/shiftHolder.svg?react';
import shiftRedStatus from '@/assets/icons/shiftRedStatus.svg?react';
import statsDown from '@/assets/icons/statsDown.svg?react';
import statsNoChange from '@/assets/icons/statsNoChange.svg?react';
import statsUp from '@/assets/icons/statsUp.svg?react';
import storno from '@/assets/icons/storno.svg?react';
import success from '@/assets/icons/success.svg?react';
import swap from '@/assets/icons/swap.svg?react';
import wallet3Line from '@/assets/icons/wallet-3-line.svg?react';
import warning from '@/assets/icons/warning-2.svg?react';
import whiteLogo from '@/assets/icons/white-logo.svg?react';

export const iconMap = {
  arrowDown,
  barcode,
  copied,
  danger,
  openShift,
  success,
  warning,
  search,
  info,
  reload,
  statsUp,
  statsDown,
  statsNoChange,
  settings,
  user,
  shiftWarning,
  printLine,
  whiteLogo,
  minus,
  plus,
  swap,
  storno,
  returnIcon,
  wallet3Line,
  bankCardLine,
  shiftGreenStatus,
  shiftGreyStatus,
  shiftRedStatus,
} as const;
