import { LoginForm } from './LoginForm';
import { Slider } from './Slider';

const SignIn = () => {
  return (
    <main className="flex bg-lightGray">
      <Slider />
      <LoginForm />
    </main>
  );
};

export { SignIn };
