import { ROUTES } from './routes';

export const APP_MODE = {
  PRODUCTIOM: 'production',
  DEVELOPMENT: 'development',
};

export const MAIN_SALE_MENU = [
  { link: ROUTES.SALE, name: 'main.sale', withIntl: true },
  { link: ROUTES.PURCHASE, name: 'main.purchase', withIntl: true },
  { link: ROUTES.INCOME, name: 'main.income', withIntl: true },
  { link: ROUTES.EXPENSE, name: 'main.expense_and_indexation', withIntl: true },
  { link: ROUTES.REFUND, name: 'main.refund_of_sale', withIntl: true },
  { link: ROUTES.PURCHASE_REFUND, name: 'main.refund_of_purchase', withIntl: true },
];

export const NODE_ENV = import.meta.env.MODE;

export enum Languages {
  RU = 'ru',
  KZ = 'kk',
}

export const DEFAULT_LANGUAGE = Languages.RU;

export const APP_LANGUAGES = [
  { label: 'Рус', code: Languages.RU },
  { label: 'Қаз', code: Languages.KZ },
];

export const OPERATION_TYPES = {
  SALE: { ID: 1, KEY: 'sale' }, // продажа
  INCOME: { ID: 2, KEY: 'income' }, // служебный приход
  OUTCOME: { ID: 3, KEY: 'outcome' }, //  служебный расход
  PURCHASE: { ID: 4, KEY: 'purchase' }, // покупка
  REFUND: { ID: 5, KEY: 'refund' }, // возврат продажи
  PURCHASE_REFUND: { ID: 6, KEY: 'purchase_refund' }, // возврат покупки
  CLOSE_SHIFT: { ID: 7, KEY: '' }, // закрытие смены
  CLOSE_SHIFT_WITHDRAWAL: { ID: 10, KEY: '' }, // закрытие смены с изьятием денег, не используется на фронте
};

export const DIGITAL_OCEAN_URL = 'https://k24-frontend-public.hb.kz-ast.bizmrg.com';

export const ARTICLE_EXAMPLE_IMPORT_FILE = `${DIGITAL_OCEAN_URL}/public-documents/Zagruzka-nomenklatury-primer-v3.xlsx`;

export const LS_KEYS = {
  USER: 'USER',
  CURRENT_KKM_ID: 'CURRENT_KKM_ID',
  OPEN_SHIFT_KKM_ID: 'OPEN_SHIFT_KKM_ID',
  SHIFT: 'SHIFT',
  DASHBOARD_MAP: 'DASHBOARD_MAP',
};

export const MEDIA_POINTS = {
  LG: '(max-width: 1024px)',
  MD: '(max-width: 768px)',
  SM: '(max-width: 640px)',
};
