import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks/useRedux';
import { formatAmount } from '@/utils/format-amount';
import { DefinitionItem } from '@/views/shared/Definition/DefinitionItem';
import { DefinitionList } from '@/views/shared/Definition/DefinitionList';
import { DefinitionSection } from '@/views/shared/Definition/DefinitionSection';
import { DisclosureCustom } from '@/views/shared/Disclosure';

const Sections = () => {
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);

  const filteredSections = useMemo(() => {
    if (selectedZReportData?.Sections)
      return Object.keys(selectedZReportData?.Sections).filter(
        (reportZSectionId) =>
          selectedZReportData.Sections[Number(reportZSectionId)].Sales ||
          selectedZReportData.Sections[Number(reportZSectionId)].Purchases ||
          selectedZReportData.Sections[Number(reportZSectionId)].Refunds ||
          selectedZReportData.Sections[Number(reportZSectionId)].PurchaseRefunds,
      );

    return [];
  }, [selectedZReportData?.Sections]);

  return (
    <DefinitionSection title={t('report.section_report')}>
      {!filteredSections.length || !selectedZReportData?.Sections ? (
        <div className="p-2">{t('report.no_operations')}</div>
      ) : (
        filteredSections?.map((reportSection) => {
          const sectionId = Number(reportSection);
          const sections = selectedZReportData?.Sections;

          return (
            <DefinitionList key={reportSection}>
              <DisclosureCustom
                btnName={`${t('report.section')}: ${sectionId} ${sections[sectionId]?.Name}`}
              >
                <DefinitionItem
                  title={t('report.sales')}
                  value={formatAmount(sections[sectionId]?.Sales || 0, true)}
                />
                <DefinitionItem
                  title={t('report.sales_refunds')}
                  value={formatAmount(sections[sectionId]?.Refunds || 0, true)}
                />
                <DefinitionItem
                  title={t('report.purchases')}
                  value={formatAmount(sections[sectionId]?.Purchases || 0, true)}
                />
                <DefinitionItem
                  title={t('report.purchase_refunds')}
                  value={formatAmount(sections[sectionId]?.PurchaseRefunds || 0, true)}
                />
              </DisclosureCustom>
            </DefinitionList>
          );
        })
      )}
    </DefinitionSection>
  );
};

export { Sections };
