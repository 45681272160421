import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import type { InputHTMLAttributes } from 'react';
import { useCallback, useState } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import { Error } from '../Error';
import { Label } from '../Label';

import { twMergeClsx } from '@/utils/tw-with-clsx';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegisterReturn<string>;
  errorText?: string;
  label?: string;
  type: 'text' | 'number' | 'email' | 'password' | 'datetime-local';
  'aria-invalid'?: boolean;
  className?: string;
}

const Input = ({
  name,
  label,
  type = 'text',
  className,
  disabled,
  errorText,
  register,
  ...props
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisible = useCallback(
    () => setPasswordVisible(!passwordVisible),
    [passwordVisible],
  );

  const getLabel = useCallback(() => {
    if (props['aria-invalid']) {
      return <Error text={errorText} />;
    }

    return <Label name={name || ''} text={label || ''} />;
  }, [errorText, label, name, props]);

  return (
    <div>
      {getLabel()}

      <input
        {...props}
        {...register}
        type={passwordVisible ? 'text' : type}
        className={twMergeClsx(
          'px-4 py-3 w-full bg-white border border-lightGray-second h-11 rounded-xl outline-none focus:border-blue appearance-none',
          className,
          disabled && 'bg-lightGray text-darkGray',
        )}
      />
      {type === 'password' &&
        (!passwordVisible ? (
          <EyeSlashIcon
            className="text-lightGray-third w-5 absolute right-0 top-1/2 mr-3 cursor-pointer "
            onClick={togglePasswordVisible}
          />
        ) : (
          <EyeIcon
            className="text-lightGray-third w-5 absolute right-0 top-1/2 mr-3 cursor-pointer"
            onClick={togglePasswordVisible}
          />
        ))}
    </div>
  );
};

export { Input };
