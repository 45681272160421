import { ArrowLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ROUTES } from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setIsSimpleMode } from '@/store/operations/slice';
import { ButtonKinds } from '@/types/button';
import { buildAppRouteUrl } from '@/utils/build-route-url';
import { Button } from '@/views/shared/Button';
import { SimpleSwitch } from '@/views/shared/SimpleSwitch';

type Props = {
  withSimpleMode?: boolean;
  title?: string;
};

const OperationHead = ({ withSimpleMode, title }: Props) => {
  const { isSimpleMode } = useAppSelector((state) => state.operations);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openedShift } = useAppSelector((state) => state.shift);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const dispatch = useAppDispatch();

  if (!openedShift) {
    return <div />;
  }

  const goBack = () => {
    navigate(buildAppRouteUrl(ROUTES.SHIFT, { idKkm: openedShift.IdKkm }));
  };

  const handleSwitchChange = () => {
    setTooltipVisible(true);
  };

  const handleTooltipAction = (confirm: boolean) => {
    setTooltipVisible(false);

    if (confirm) {
      dispatch(setIsSimpleMode(!isSimpleMode));
    }
  };

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="hidden lg:flex m-0">
        <Button onClick={goBack} kind={ButtonKinds.secondaryIcon}>
          <ArrowLeftIcon className="w-4" />
          {t('receipt.return_to_cash_register')}
        </Button>
      </div>
      <div className="flex justify-center items-center lg:hidden">
        <Button onClick={goBack} className="lg:hidden m-0 pl-0 pr-6" kind={ButtonKinds.iconOnly}>
          <ChevronLeftIcon className="w-4" />
          <span className="text-xl md:text-2xl font-semibold text-left">
            {title || t('receipt.new_sale')}
          </span>
        </Button>
      </div>
      {withSimpleMode && (
        <>
          <div data-tooltip-id="simple-sale" className="flex justify-center lg:justify-end">
            <SimpleSwitch
              checked={isSimpleMode}
              onChange={handleSwitchChange}
              label={t('other.simplified_mode')}
            />
          </div>
          <Tooltip
            id="simple-sale"
            isOpen={isTooltipVisible}
            place="bottom-end"
            clickable
            noArrow
            style={{
              zIndex: 5,
              padding: 0,
              borderRadius: 12,
            }}
          >
            <div className="flex flex-col items-center max-w-[280px] text-center text-sm rounded-2xl font-normal z-20">
              <div className="p-2 border-b border-white/10">
                <p className="mb-2">
                  {isSimpleMode
                    ? t('other.switch_advanced_mode_warning')
                    : t('other.switch_simple_mode_warning')}
                </p>
                <p>
                  {isSimpleMode
                    ? t('other.confirm_switch_mode.advanced')
                    : t('other.confirm_switch_mode.simple')}
                </p>
              </div>

              <div className="flex w-full flex-1 p-2">
                <button
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded w-full"
                  onClick={() => handleTooltipAction(true)}
                >
                  {t('other.proceed')}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded w-full"
                  onClick={() => handleTooltipAction(false)}
                >
                  {t('other.cancel')}
                </button>
              </div>
            </div>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export { OperationHead };
