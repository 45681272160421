import { CreditCardIcon, WalletIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import { DuplicateModal } from '../DuplicateModal';

import useContainer from './hook';

import MobileTransfer from '@/assets/icons/mobile-transfer.svg?react';
import { OPERATION_TYPES } from '@/constants/app';
import { SORT_TYPES } from '@/constants/common';
import { REPORT_DOCS_TYPE_WITHOUT_DUPLICATE } from '@/constants/reports';
import { ButtonKinds } from '@/types/button';
import { getFormattedFullDate } from '@/utils/dates';
import { formatAmount } from '@/utils/format-amount';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { Button } from '@/views/shared/Button';
import { Table } from '@/views/shared/Table';
import type { Items } from '@/views/shared/Table/hook';

const renderCell = (isMobile: boolean, value: number, icon: ReactNode, align: string) => {
  if (isMobile)
    return (
      <div className={twMergeClsx('flex gap-1 text-darkGray', align)}>
        {icon}
        {formatAmount(value, true)}
      </div>
    );

  return formatAmount(value, true);
};

const OperationsList = () => {
  const {
    isMobile,
    selectedOperationId,
    showReportOperationsModal,
    reportList,
    t,
    isHasOpenedShift,
    showOperationDuplicateModal,
    handleCloseReportOperationsModal,
    goToFund,
  } = useContainer();

  const getRows = useCallback(() => {
    const newData: Items[] = [];
    reportList?.map((reportOperation) =>
      newData.push({
        TypeDocumentName: reportOperation.TypeDocument.Name,
        DateDocument: getFormattedFullDate(reportOperation.DateDocument),
        DateDocumentNotFormatted: reportOperation.DateDocument,
        Id: reportOperation.Id,
        Cash: renderCell(
          isMobile,
          reportOperation.Cash,
          <WalletIcon className="w-4" />,
          'items-center',
        ),
        NonCash: renderCell(
          isMobile,
          reportOperation.NonCash,
          <CreditCardIcon className="w-4" />,
          'justify-center',
        ),
        Mobile: renderCell(isMobile, reportOperation.Mobile, <MobileTransfer />, 'justify-end'),
        Value: formatAmount(reportOperation.Value, true),
        Operations: (
          <div className="flex justify-between lg:justify-start gap-2">
            {reportOperation.TypeDocument.Id !== REPORT_DOCS_TYPE_WITHOUT_DUPLICATE && (
              <Button
                kind={ButtonKinds.secondaryGray}
                onClick={showOperationDuplicateModal(reportOperation.Id)}
                className="text-sm py-1 basis-1/2 mx-0"
              >
                <span>{t('report.duplicate')}</span>
              </Button>
            )}
            {isHasOpenedShift &&
              (reportOperation.TypeDocument.Id === OPERATION_TYPES.SALE.ID ||
                reportOperation.TypeDocument.Id === OPERATION_TYPES.PURCHASE.ID) && (
                <Button
                  className="text-sm py-1 basis-1/2 mx-0"
                  onClick={goToFund(
                    reportOperation.Id,
                    reportOperation.TypeDocument.Id,
                    reportOperation.IdKkm,
                  )}
                  kind={ButtonKinds.warningSecondary}
                >
                  <span>{t('report.return')}</span>
                </Button>
              )}
          </div>
        ),
      }),
    );

    return newData;
  }, [goToFund, isMobile, reportList, showOperationDuplicateModal, t]);

  return (
    <div>
      {!reportList?.length ? (
        <p className="mt-4 text-darkGray text-center">
          {t('report.no_operations_found_for_current_date')}
        </p>
      ) : (
        <>
          <DuplicateModal
            isOpen={showReportOperationsModal}
            reportId={selectedOperationId}
            onClose={handleCloseReportOperationsModal}
          />
          <Table
            heads={[
              { label: t('report.operation_type'), code: 'TypeDocumentName' },
              {
                label: t('report.date'),
                sortProp: 'DateDocumentNotFormatted',
                sortType: SORT_TYPES.DATE,
                code: 'DateDocument',
              },
              {
                label: t('report.receipt_number'),
                sortProp: 'Id',
                sortType: SORT_TYPES.NUMBER,
                code: 'Id',
              },
              { label: t('report.cash'), code: 'Cash' },
              { label: t('report.non_cash'), code: 'NonCash' },
              { label: t('report.transfer'), code: 'Mobile' },
              { label: t('report.total'), code: 'Value' },
              { label: '', code: 'Operations' },
            ]}
            rows={getRows()}
            className="tableRowList"
          />
        </>
      )}
    </div>
  );
};

export { OperationsList };
