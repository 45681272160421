import { type MouseEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CashboxItem } from './CashboxItem';

import { useGetCompanies, useAppDispatch, useAppSelector } from '@/hooks';
import { setKkmPage } from '@/store/cashbox/slice';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { CustomPagination } from '@/views/shared/CustomPagination';

const Cashbox = () => {
  const { kkmList, kkmSearch, kkmMetadata, kkmStatus } = useAppSelector((state) => state.cashbox);
  const { companyItem } = useAppSelector((state) => state.companies);
  const { loadKkms } = useGetCompanies();
  const [znmValue, setZnmValue] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const copyZnm = (e: MouseEvent<HTMLDivElement>, value: number) => {
    e.stopPropagation();

    if (znmValue !== null && znmValue !== value) {
      setZnmValue(null);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    setZnmValue(value);
    navigator.clipboard.writeText(String(value));

    timeoutRef.current = setTimeout(() => {
      setZnmValue(null);
      timeoutRef.current = null;
    }, 3000);
  };

  useEffect(() => {
    loadKkms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkmSearch, kkmStatus?.value]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const renderTitle = () => {
    if (kkmSearch) {
      return t('main.found_cash');
    }

    return `${t('main.cash_registers')} ${companyItem?.ShortName}`;
  };

  const handleChangePage = (page: number) => {
    dispatch(setKkmPage(page));
    loadKkms({
      page: page + 1,
    });
  };

  const renderEmptyState = () => {
    if (kkmSearch || kkmStatus?.value) {
      return t('main.not_found');
    }

    return t('main.no_cash');
  };

  return (
    <div className={twMergeClsx('lg:bg-white lg:rounded-lg overflow-hidden w-full')}>
      <h3 className="text-darkGray hidden lg:block text-sm px-4 py-2.5 border-b border-lightGray">
        {renderTitle()}
      </h3>
      <div className="h-[calc(100vh-386px)] overscroll-contain overflow-y-scroll lg:h-auto lg:overflow-visible">
        {kkmList?.length ? (
          kkmList.map((item) => (
            <CashboxItem key={item.Id} item={item} copyZnm={copyZnm} znmValue={znmValue} />
          ))
        ) : (
          <div className="text-center text-darkGray-five font-medium py-8">
            {renderEmptyState()}
          </div>
        )}
      </div>
      {kkmMetadata.totalPages > 1 && (
        <div className="p-4 flex justify-center lg:justify-end">
          <CustomPagination
            setCurrentPage={handleChangePage}
            currentPage={kkmMetadata.currentPage - 1}
            totalPages={kkmMetadata.totalPages}
          />
        </div>
      )}
    </div>
  );
};

export { Cashbox };
