import { useCallback, useEffect, useState } from 'react';

const useContainer = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const closeModal = useCallback(() => {
    onClose();
    setIsOpen(false);
  }, [onClose]);

  return { isOpen, setIsOpen, closeModal };
};

export default useContainer;
