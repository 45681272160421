import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Balance } from '@/types/balance';

type InitialState = {
  balances: Balance[] | null;
  openedBalances: Balance[] | null;
  totalBalance: number;
};

const initialState: InitialState = {
  balances: [],
  openedBalances: [],
  totalBalance: 0,
};

const balanceSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    setBalances: (state, { payload }: PayloadAction<Balance[] | null>) => {
      state.balances = payload;
    },
    setOpenedBalances: (state, { payload }: PayloadAction<Balance[] | null>) => {
      state.openedBalances = payload;
    },
    setTotalBalance: (state, { payload }: PayloadAction<number>) => {
      state.totalBalance = payload;
    },
  },
});

export default balanceSlice.reducer;

export const { setBalances, setOpenedBalances, setTotalBalance } = balanceSlice.actions;
