import { twMerge } from 'tailwind-merge';

import useContainer from './hook';

import { APP_LANGUAGES } from '@/constants/app';
import type { AppLangsType } from '@/types/app';

const LangChecker = () => {
  const { lang, checkLang } = useContainer();

  return (
    <div className="inline-flex rounded-xl bg-lightGray-second text-darkGray-second overflow-hidden items-center leading-10 justify-between">
      {APP_LANGUAGES.map((langItem: AppLangsType) => {
        return (
          <div
            key={langItem.code}
            className={twMerge(lang === langItem.code && 'bg-blue text-white', 'px-4')}
            role="button"
            onClick={checkLang(langItem.code)}
          >
            {langItem.label}
          </div>
        );
      })}
    </div>
  );
};

export { LangChecker };
