import { yupResolver } from '@hookform/resolvers/yup';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAppSelector } from '@/hooks/useRedux';
import { useAddArticleMutation } from '@/store/settings/service';
import type { ArticleItem } from '@/types/article';
import type { ArticleForm } from '@/types/form';
import { getSectionsOptions, getUnitsOptions } from '@/utils/getOptions';

export type Props = {
  isOpen: boolean;
  article: ArticleItem | null;
  onClose: (item: ArticleItem | null) => void;
  onSave: () => void;
};
const useContainer = ({ article, onClose, onSave }: Props) => {
  const [addArticle, { isLoading }] = useAddArticleMutation();
  const params = useParams();
  const { t } = useTranslation();
  const kkmId = useMemo(() => Number(params?.idKkm), [params.idKkm]);

  const { locale } = useAppSelector((state) => state.app);
  const { unitList, sectionList } = useAppSelector((state) => state.settings);
  const unitsOptions = getUnitsOptions(unitList, locale);
  const sectionOptions = getSectionsOptions(sectionList);
  const [showScanner, setShowScanner] = useState(false);

  const [firstSection] = sectionOptions;
  const [firstUnit] = unitsOptions;

  const defaultValues = useMemo(
    () => ({
      Qr: article?.Qr ?? '',
      Name: article?.Name ?? '',
      Unit: unitsOptions?.find((unit) => unit.id === article?.IdUnit) || firstUnit || null,
      Section:
        sectionOptions?.find((section) => section.id === article?.IdSection) ||
        firstSection ||
        null,
      Price: article?.Price ?? 0,
      Discount: article?.Discount ?? 0,
      Markup: article?.Markup ?? 0,
      InSale: article?.Active || false,
    }),
    [article, sectionOptions, unitsOptions],
  );

  const schema = Yup.object().shape({
    Qr: Yup.string(),
    Name: Yup.string().required(t('other.enter_item_name')).typeError(t('other.enter_item_name')),
    Price: Yup.number()
      .required(t('other.enter_item_price'))
      .typeError(t('kassa_settings.article_price_type_error'))
      .min(1, t('kassa_settings.article_price_type_error')),
    Discount: Yup.number()
      .required(t('other.article_discount_error'))
      .typeError(t('kassa_settings.article_price_type_error')),
    Markup: Yup.number()
      .required(t('other.article_markup_error'))
      .typeError(t('kassa_settings.article_price_type_error')),
    InSale: Yup.boolean().required(),
  });

  const methods = useForm<ArticleForm>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleCloseModal = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const toggleScanner = useCallback(() => {
    setShowScanner((prev) => !prev);
  }, []);

  const setBarcode = useCallback(
    (code: string) => {
      methods.setValue('Qr', code);
    },
    [methods],
  );

  const updateArticle: SubmitHandler<ArticleForm> = useCallback(
    (values) => {
      const body = {
        Name: values.Name,
        Qr: values.Qr ?? '',
        IdSection: Number(values.Section?.id) || 0,
        IdUnit: Number(values.Unit?.id) || 0,
        Price: values.Price,
        Discount: values.Discount,
        Markup: values.Markup,
        Active: values.InSale,
        Id: article?.Id ?? undefined,
      };

      addArticle({ body, idKkm: kkmId })
        .unwrap()
        .then((res) => {
          if (res.Status === StatusCodes.OK) {
            onSave();
            onClose(null);
            toast.success(
              article?.Id
                ? t('other.item_successfully_updated')
                : t('other.item_successfully_added'),
              { theme: 'dark' },
            );
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(t('error.something_went_wrong'), { theme: 'dark' });
        });
    },
    [addArticle, article?.Id, kkmId, onClose, onSave, t],
  );

  return {
    t,
    methods,
    showScanner,
    unitsOptions,
    sectionOptions,
    isLoading,
    setBarcode,
    setShowScanner,
    updateArticle,
    handleCloseModal,
    toggleScanner,
  };
};

export default useContainer;
