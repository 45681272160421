import { yupResolver } from '@hookform/resolvers/yup';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAppSelector } from '@/hooks/useRedux';
import { useSaveKassaSettingsMutation } from '@/store/settings/service';
import type { KassaSettingsForm } from '@/types/settings';

const useContainer = ({ onSave }: { onSave: () => void }) => {
  const params = useParams();
  const { kkmItem } = useAppSelector((state) => state.cashbox);
  const [saveSettings, { isLoading }] = useSaveKassaSettingsMutation();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('other.enter_cash_register_name')),
    znm: Yup.number().required(),
    address: Yup.string().required(),
    placeUsed: Yup.number().required(),
    autorenew: Yup.boolean().required(),
  });

  const values = useMemo(() => {
    const kkm = kkmItem;

    return {
      name: kkm?.Name || '',
      znm: kkm?.Znm || 0,
      address: `${kkm?.Address?.Town?.Name} ${kkm?.Address.Street} - ${kkm?.Address?.House} ${kkm?.Address?.Flat}`,
      placeUsed: kkm?.PlaceUsed?.Id || 0,
      autorenew: kkm?.Autorenew || false,
    };
  }, [kkmItem]);

  const methods = useForm<KassaSettingsForm>({
    resolver: yupResolver(validationSchema),
    values,
  });

  const saveKassaSettings: SubmitHandler<KassaSettingsForm> = useCallback(
    (formValues) => {
      const formData = new FormData();
      formData.append('Name', formValues.name);
      formData.append('Autorenew', JSON.stringify(formValues.autorenew));

      if (params?.idKkm) {
        saveSettings({ body: formData, idKkm: Number(params.idKkm) })
          .unwrap()
          .then((res) => {
            if (res.Status === StatusCodes.OK) {
              onSave();
              toast.success(t('kassa_settings.cash_settings_saved'), {
                theme: 'dark',
              });
            }
          })
          .catch((e) => console.error(e));
      }
    },
    [onSave, params.idKkm, saveSettings, t],
  );

  return { t, methods, isLoading, saveKassaSettings };
};

export default useContainer;
