import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LS_KEYS } from '@/constants/app';
import { useAppSelector } from '@/hooks';
import { getFromLocalStorage } from '@/utils';

const useContainer = () => {
  const { t } = useTranslation();

  const { shift, openedShift } = useAppSelector((state) => state.shift);
  const { totalReceiptCount } = useAppSelector((state) => state.cashbox);
  const { openedBalances, totalBalance } = useAppSelector((state) => state.balances);
  const [isOpenShiftModal, setIsOpenShiftModal] = useState(false);
  const openedKassaKkmId = getFromLocalStorage(LS_KEYS.OPEN_SHIFT_KKM_ID);
  const [cashBalance, cashlessBalance] = openedBalances || [];

  const toggleCloseShiftModal = useCallback(() => {
    setIsOpenShiftModal(!isOpenShiftModal);
  }, [isOpenShiftModal]);

  return {
    openedShift,
    shift,
    t,
    totalBalance,
    openedBalances,
    totalReceiptCount,
    isOpenShiftModal,
    openedKassaKkmId,
    cashBalance,
    cashlessBalance,
    toggleCloseShiftModal,
  };
};

export default useContainer;
