import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import type { RefresherEventDetail } from '@ionic/react';
import {
  IonApp,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  setupIonicReact,
  useIonRouter,
} from '@ionic/react';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import '@capacitor-community/safe-area';
import '@/styles/global.css';
import { Slide, ToastContainer } from 'react-toastify';

import { ROUTES } from './constants/routes';
import { BillLayout } from './views/layout/BillLayout';
import { MainLayout } from './views/layout/MainLayout';
import { Billcheck } from './views/pages/Billcheck';
import { CloseShift } from './views/pages/CloseShift';
import { Icons } from './views/pages/Icons';
import { Main } from './views/pages/Main';
import { Expense } from './views/pages/Operations/Expense';
import { Income } from './views/pages/Operations/Income';
import { Purchase } from './views/pages/Operations/Purchase';
import { PurchaseRefund } from './views/pages/Operations/PurchaseRefund';
import { Sale } from './views/pages/Operations/Sale';
import { SaleRefund } from './views/pages/Operations/SaleRefund';
import { Reports } from './views/pages/Reports';
import { Settings } from './views/pages/Settings';
import { ShiftContainer } from './views/pages/Shift';
import { Showcheck } from './views/pages/Showcheck';
import { SignIn } from './views/pages/Sign-In';
import { Success } from './views/pages/Success';

import ToastIcon from '@/assets/icons/toasticon.svg?react';

setupIonicReact({ mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md' });

const App = () => {
  const navigate = useNavigate();
  const ionRouter = useIonRouter();

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          CapacitorApp.exitApp();
        }
      });
    });
  }, [ionRouter]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      navigate(0);
      event.detail.complete();
    }, 500);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        transition={Slide}
        icon={<ToastIcon />}
        closeButton={false}
        hideProgressBar
        limit={1}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        className={`toastify-container-${Capacitor.getPlatform()} z-[999999]`}
      />
      <IonApp>
        <IonContent className="h-[calc(100vh-_var(--safe-area-inset-top))] bg-lightGray">
          <IonRefresher slot="fixed" className="custom-for-ios" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>

          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route element={<BillLayout />}>
              <Route path={ROUTES.BILLCHECK} element={<Billcheck />} />
              <Route path={ROUTES.SHOWCHECK} element={<Showcheck />} />
            </Route>
            <Route path={ROUTES.ICONS} element={<Icons />} />
            <Route path="www" element={<MainLayout />}>
              <Route path={ROUTES.MAIN} element={<Main />} />
              <Route path={ROUTES.SHIFT} element={<ShiftContainer />} />
              <Route path={ROUTES.REPORT} element={<Reports />} />
              <Route path={ROUTES.SALE} element={<Sale />} />
              <Route path={ROUTES.PURCHASE} element={<Purchase />} />
              <Route path={ROUTES.PURCHASE_REFUND} element={<PurchaseRefund />} />
              <Route path={ROUTES.REFUND} element={<SaleRefund />} />
              <Route path={ROUTES.OPERATION_REFUND} element={<SaleRefund />} />
              <Route path={ROUTES.OPERATION_PURCHASE_REFUND} element={<PurchaseRefund />} />
              <Route path={ROUTES.SETTINGS} element={<Settings />} />
              <Route path={ROUTES.CLOSE_SHIFT} element={<CloseShift />} />
              <Route path={ROUTES.INCOME} element={<Income />} />
              <Route path={ROUTES.EXPENSE} element={<Expense />} />
              <Route path={ROUTES.OPERATION_SUCCESS} element={<Success />} />
            </Route>
          </Routes>
        </IonContent>
      </IonApp>
    </>
  );
};

export default App;
