import { useMemo } from 'react';
import { Pagination as HeadlessPagination } from 'react-headless-pagination';
import { useTranslation } from 'react-i18next';

import { SearchPageInput } from './SearchPageInput';

import ArrowLeftSLine from '@/assets/icons/arrow-left-s-line.svg?react';
import ArrowRightSLine from '@/assets/icons/arrow-right-s-line.svg?react';
import { REPORT_COUNT } from '@/constants/reports';

type Props = {
  totalPages: number;
  currentPage: number;
  showTotalCount?: boolean;
  showGoPage?: boolean;
  totalItemCount?: number;
  itemOnPage?: number;
  setCurrentPage: (page: number) => void;
};

const CustomPagination = ({
  currentPage,
  totalPages,
  showTotalCount,
  showGoPage,
  totalItemCount = 0,
  itemOnPage = REPORT_COUNT,
  setCurrentPage,
}: Props) => {
  const { t } = useTranslation();
  const from = useMemo(() => currentPage * itemOnPage + 1, [currentPage, itemOnPage]);
  const to = useMemo(
    () =>
      totalItemCount < (currentPage + 1) * itemOnPage
        ? totalItemCount
        : (currentPage + 1) * itemOnPage,
    [currentPage, itemOnPage, totalItemCount],
  );

  return (
    <>
      {showTotalCount && itemOnPage && (
        <div className="text-darkGray font-semibold text-xs hidden lg:block">
          {from}-{to} {t('report.from')} {totalItemCount}
        </div>
      )}
      {showGoPage && (
        <>
          <div className="text-darkGray font-semibold text-xs hidden lg:block">
            {t('other.go_to_page')}
          </div>
          <SearchPageInput
            onChange={(value) => setCurrentPage(Number(value) - 1)}
            placeholder="1"
          />
          <div className="mx-4 bg-lightGray h-5 w-[1px]" />
        </>
      )}

      <HeadlessPagination
        edgePageCount={2}
        totalPages={totalPages}
        middlePagesSiblingCount={1}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        className="bg-lightGray inline-flex rounded-lg overflow-hidden"
      >
        <HeadlessPagination.PrevButton className="w-[30px] h-[30px] flex items-center justify-center hover:bg-lightGray-fourth active:bg-lightGray-second">
          <ArrowLeftSLine className="w-3.5 h-3.5" />
        </HeadlessPagination.PrevButton>
        <nav className="flex justify-center">
          <ul className="flex items-center">
            <HeadlessPagination.PageButton
              activeClassName="bg-blue text-white hover:bg-blue"
              inactiveClassName="hover:bg-lightGray-fourth active:bg-lightGray-second"
              className="w-[30px] h-[30px] flex items-center justify-center font-semibold cursor-pointer"
            />
          </ul>
        </nav>
        <HeadlessPagination.NextButton className="w-[30px] h-[30px] flex items-center justify-center hover:bg-lightGray-fourth active:bg-lightGray-second">
          <ArrowRightSLine className="w-3.5 h-3.5" />
        </HeadlessPagination.NextButton>
      </HeadlessPagination>
    </>
  );
};

export { CustomPagination };
