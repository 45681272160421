import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';

const useContainer = () => {
  const [showSlider, setShowSlider] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [emblaRef, embla] = useEmblaCarousel({ loop: false }, [Autoplay({ stopOnLastSnap: true })]);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);

  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;

    onSelect();

    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const handleHideSlider = useCallback(() => {
    setShowSlider(false);
  }, []);

  return {
    selectedIndex,
    scrollSnaps,
    emblaRef,
    embla,
    nextBtnEnabled,
    showSlider,
    scrollTo,
    scrollNext,
    handleHideSlider,
  };
};

export default useContainer;
