import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { OperationType } from '@/constants/operations';
import { useAppSelector } from '@/hooks';
import { useLazyGetUidQuery } from '@/store/auth/service';
import { useSendExpenseMutation, useSendIncomeMutation } from '@/store/operations/service';
import type { IncomeSendForm } from '@/types/form';

type Props = {
  operationType: OperationType;
};

const useContainer = ({ operationType }: Props) => {
  const { openedBalances } = useAppSelector((state) => state.balances);
  const [sendIncome, { isLoading: isLoadingIncome }] = useSendIncomeMutation();
  const [sendExpense, { isLoading: isLoadingExpense }] = useSendExpenseMutation();

  const totalBalance = useMemo(
    () =>
      openedBalances?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.Amount;
      }, 0),
    [openedBalances],
  );

  const [getUid] = useLazyGetUidQuery();
  const params = useParams();
  const idKkm = Number(params?.idKkm);

  const [showReportOperationsModal, setShowReportOperationsModal] = useState(false);

  const [selectedOperationId, setSelectedOperationId] = useState(0);

  const showOperationDuplicateModal = useCallback((reportId: number) => {
    setShowReportOperationsModal(true);
    setSelectedOperationId(reportId);
  }, []);

  const handleCloseReportOperationsModal = useCallback(() => {
    setShowReportOperationsModal(false);
    setSelectedOperationId(0);
  }, []);

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    amount: Yup.string()
      .test(
        'test-amount',
        () => t('receipt.expense_amount'),
        (value) => {
          if (!value) return false;

          return operationType === OperationType.EXPENSE
            ? Number(value) < Number(totalBalance)
            : true;
        },
      )
      .required(
        operationType === OperationType.EXPENSE
          ? t('receipt.expense_amount')
          : t('receipt.income_amount'),
      ),
  });

  const form = useForm<IncomeSendForm>({
    defaultValues: {
      amount: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<IncomeSendForm> = async (data) => {
    try {
      const uidResponse = await getUid(idKkm).unwrap();
      let operationResponse = null;

      const operationBody = {
        IdDomain: '',
        Cash: 0,
        NonCash: 0,
        Positions: [],
        Total: 0,
        Mobile: 0,
        Amount: data.amount,
        GenerateCheck: true,
        CustomerIin: '',
      };

      if (operationType === OperationType.INCOME) {
        operationResponse = await sendIncome({
          IdKkm: idKkm,
          Uid: uidResponse.Data.Uid,
          body: operationBody,
        }).unwrap();
      }

      if (operationType === OperationType.EXPENSE) {
        operationResponse = await sendExpense({
          IdKkm: idKkm,
          Uid: uidResponse.Data.Uid,
          body: operationBody,
        }).unwrap();
      }

      if (operationResponse) {
        showOperationDuplicateModal(operationResponse.Data.IdDocument);
      }
    } catch (e) {
      console.log(e);
    } finally {
      form.reset();
      setTimeout(() => {
        form.clearErrors();
      }, 0);
    }
  };

  return {
    isLoading: isLoadingIncome || isLoadingExpense,
    form,
    showReportOperationsModal,
    selectedOperationId,
    onSubmit,
    showOperationDuplicateModal,
    handleCloseReportOperationsModal,
  };
};

export { useContainer };
