import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { REPORT_DATE_TYPE, REPORT_TYPES } from '@/constants/reports';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useGetZReportMutation } from '@/store/reports/service';
import { clearReportState, setZReportsList } from '@/store/reports/slice';
import { getDataToString } from '@/utils/dates';

const useContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [getZReport, { isLoading }] = useGetZReportMutation();
  const { idShift } = useParams();
  const { shift } = useAppSelector((state) => state.shift);
  const { dateFrom, dateTo, zReportList } = useAppSelector((state) => state.reports);

  useEffect(() => {
    dispatch(clearReportState());
  }, [dispatch]);

  useEffect(() => {
    if (!shift || shift.Id !== Number(idShift)) {
      return;
    }

    const currDateFrom = getDataToString({
      id: REPORT_DATE_TYPE.DATE_FROM,
      date: dateFrom,
      type: REPORT_TYPES.Z_REPORT_ARCHIVE,
    });
    const currDateTo = getDataToString({
      id: REPORT_DATE_TYPE.DATE_TO,
      date: dateTo,
      type: REPORT_TYPES.Z_REPORT_ARCHIVE,
    });
    getZReport({ idKkm: shift.IdKkm, dateFrom: currDateFrom, dateTo: currDateTo })
      .unwrap()
      .then((res) => {
        dispatch(setZReportsList(res?.Data));
      })
      .catch((e) => console.error(e));
  }, [dateFrom, dateTo, dispatch, getZReport, idShift, shift]);

  return { isLoading, zReportList, t };
};

export default useContainer;
