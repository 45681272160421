import { PieChart as Chart, Pie, Cell } from 'recharts';

import type { PieChart as PieChartType } from '@/types/cashbox';

const COLORS = ['#00ADEE', '#C2EEFF', '#C3C2FF'];

const INNER_RADIUS = 60;
const OUTER_RADIUS = 72;
const CHART_WIDTH = 200;
const CHART_HEIGHT = 150;

type Props = {
  data: PieChartType;
};

const PieChart = ({ data }: Props) => {
  const pie = [
    { name: 'Cash', value: Math.round(data.Cash) },
    { name: 'NonCash', value: Math.round(data.NonCash) },
    { name: 'Mobile', value: Math.round(data.Mobile) },
  ];

  return (
    <Chart width={CHART_WIDTH} height={CHART_HEIGHT}>
      <Pie data={pie} innerRadius={INNER_RADIUS} outerRadius={OUTER_RADIUS} dataKey="value">
        {pie.map((entry, index) => (
          <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </Chart>
  );
};

export { PieChart };
