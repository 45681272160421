import { XCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useNavigation } from '@/hooks/useNavigate';
import { useAppSelector } from '@/hooks/useRedux';
import { useLazyGetUserQuery } from '@/store/user/service';
import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';

const SettingsError = () => {
  const { t } = useTranslation();
  const { idKkm } = useParams();
  const { goToCloseShift } = useNavigation();
  const [getUser] = useLazyGetUserQuery();
  const [userName, setUserName] = useState('');
  const { shiftStatus, shift } = useAppSelector((state) => state.shift);

  useEffect(() => {
    if (shift?.IdUser && shiftStatus.shiftOpenByAnotherCashier) {
      getUser(shift.IdUser)
        .unwrap()
        .then((result) => {
          setUserName(result?.Data.Name);
        });
    }
  }, [getUser, shift?.IdUser, shiftStatus.shiftOpenByAnotherCashier]);

  return (
    <div className="bg-white rounded-lg shadow-gray p-3 m-auto mt-6 w-auto max-w-md text-center text-base flex flex-col gap-3">
      <h2 className="font-semibold text-xl">{t('settings.settings_unavailable')}</h2>
      <p>{t('settings.settings_close_shift')}</p>
      {shiftStatus.shiftOpenByAnotherCashier ? (
        <div className="bg-lightGray-five p-2 rounded-xl">
          <p className="flex justify-between">
            {t('settings.shift_opened_by_cashier')}
            <span className="font-semibold">{userName}</span>
          </p>
          <p className="flex justify-between">
            {t('settings.on_cash_register')}
            <span className="font-semibold">{idKkm}</span>
          </p>
        </div>
      ) : (
        <Button
          text="settings.close_shift"
          onClick={goToCloseShift}
          kind={ButtonKinds.danger}
          Icon={<XCircleIcon className="w-4" />}
        />
      )}
    </div>
  );
};

export { SettingsError };
