import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect, useMedia } from 'react-use';

import { Languages } from '@/constants/app';
import { DISCOUNT_TYPES, DOMAIN_TITLES, type OperationType } from '@/constants/operations';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  useGetArticlesQuery,
  useGetDomainsQuery,
  useGetSectionsQuery,
  useGetUnitsQuery,
} from '@/store/operations/service';
import {
  addPosition,
  clearPositions,
  setOperationErrors,
  updatePosition,
} from '@/store/operations/slice';
import type { OptionType, SaleAddProductForm } from '@/types/form';
import type { Article, Domain, PrecheckPosition, Section, Unit } from '@/types/operations';
import { calculateDiscountOrMarkup } from '@/utils/format-amount';
import { getPositionTotal } from '@/utils/operations';

type Props = {
  operationType: OperationType;
};

const useContainer = ({ operationType }: Props) => {
  const { locale } = useAppSelector((state) => state.app);
  const { positions, isSimpleMode } = useAppSelector((state) => state.operations);
  const params = useParams();
  const idKkm = Number(params?.idKkm);
  const { data: articlesData } = useGetArticlesQuery(idKkm);
  const { data: unitsData } = useGetUnitsQuery(idKkm);
  const { data: domainsData } = useGetDomainsQuery('');
  const { data: sectionsData } = useGetSectionsQuery(idKkm);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const form = useForm<SaleAddProductForm>({
    defaultValues: {
      article: null,
      count: 1,
      price: '',
      unit: null,
      domain: null,
      section: null,
      isExcise: false,
      exciseCode: '',
      discountType: DISCOUNT_TYPES.PERCENT,
      discount: '',
      markup: '',
      name: '',
    },
  });
  const [showScanner, setShowScanner] = useState(false);
  const [code, setCode] = useState('');

  const isMobile = useMedia('(max-width: 768px)');

  const { setValue, reset, watch } = form;

  const isExciseWatch = watch('isExcise');
  const discountTypeWatch = watch('discountType');

  const toggleScanner = () => {
    setShowScanner((prev) => !prev);
  };

  const isHasBarcode = useMemo(() => {
    return articlesData?.Data.Articles.some((item) => item.Qr !== '');
  }, [articlesData]);

  const articlesOptions: OptionType<Article>[] | [] = useMemo(() => {
    return (
      articlesData?.Data.Articles.map((item) => ({
        id: item.Id,
        label: item.Name,
        value: item,
      })) || []
    );
  }, [articlesData]);

  const unitsOptions: OptionType<Unit>[] | [] = useMemo(() => {
    return (
      unitsData?.Data?.map((item) => ({
        id: item.Id,
        label: locale === Languages.KZ ? item.NameKAZ : item.NameRU,
        value: item,
      })) || []
    );
  }, [unitsData, locale]);

  const domainsOptions: OptionType<Domain>[] | [] = useMemo(() => {
    return (
      domainsData?.Data.Domains?.map((item) => ({
        id: item.Id,
        label: DOMAIN_TITLES[item.Name],
        value: item,
      })) || []
    );
  }, [domainsData]);

  const sectionsOptions: OptionType<Section>[] | [] = useMemo(() => {
    return (
      sectionsData?.Data.Sections?.map((item) => ({
        id: item.Id,
        label: item.Name,
        value: item,
      })) || []
    );
  }, [sectionsData]);

  const clearForm = useCallback(() => {
    reset();

    if (isSimpleMode) {
      dispatch(clearPositions());
    }
  }, [dispatch, isSimpleMode, reset]);

  useEffect(() => {
    reset();
    dispatch(clearPositions());
  }, [clearForm, dispatch, isSimpleMode, reset]);

  useEffect(() => {
    if (unitsOptions.length && domainsOptions.length && sectionsOptions.length) {
      reset({
        article: null,
        count: 1,
        price: '',
        unit: unitsOptions[0],
        domain: domainsOptions[0],
        section: sectionsOptions[0],
        isExcise: false,
        exciseCode: '',
        discountType: DISCOUNT_TYPES.PERCENT,
        discount: '',
        markup: '',
        name: '',
      });
    }
  }, [unitsOptions, domainsOptions, sectionsOptions, reset]);

  useEffect(() => {
    if (code.length) {
      const findedArticle = articlesData?.Data?.Articles.find((item) => item.Qr === code);

      if (findedArticle) {
        setValue('article', {
          id: findedArticle.Id,
          label: findedArticle.Name,
          value: findedArticle,
        });
        setValue('price', String(findedArticle.Price));
      }
    }
  }, [code, articlesData, setValue]);

  useEffect(() => {
    if (sectionsData && !sectionsData?.Data?.Sections) {
      dispatch(
        setOperationErrors({
          text: t('error.empty_sections'),
        }),
      );
    }
  }, [sectionsData?.Data]);

  const formChanges: SaleAddProductForm = watch();

  const preparePositionData = (
    formChangesValues: SaleAddProductForm,
    calculatedDiscount: number,
    calculatedMarkup: number,
    positionsValue: PrecheckPosition[] | [],
    operationTypeValue: OperationType,
  ) => ({
    id: positionsValue[0] ? positionsValue[0].id + 1 : 1,
    discount: calculatedDiscount,
    markup: calculatedMarkup,
    discountPercent: Number(formChangesValues.discount),
    markupPercent: Number(formChangesValues.markup),
    idUnit: Number(formChangesValues.unit?.id),
    unitShortName: formChangesValues.unit?.value?.ShortName || '',
    discountType: formChangesValues.discountType,
    idSection: Number(formChangesValues.section?.id),
    sectionName: formChangesValues.section?.label || '',
    isFixedDiscountOrMarkup: formChangesValues.discountType === DISCOUNT_TYPES.FIXED,
    name: formChangesValues.article?.label || formChangesValues.name || '',
    price: Number(formChangesValues.price) || 0,
    productCode: formChangesValues.exciseCode || '',
    qty: formChangesValues.count || 1,
    storno: false,
    idDomain: Number(formChangesValues.domain?.id),
    total: getPositionTotal(
      {
        Discount: calculatedDiscount,
        DiscountType: formChangesValues.discountType,
        Markup: calculatedMarkup,
        Price: Number(formChangesValues.price),
        Qty: formChangesValues.count,
      },
      operationTypeValue,
    ),
  });

  const calculateDiscountAndMarkup = (formChangesValues: SaleAddProductForm) => {
    const calculatedDiscount = calculateDiscountOrMarkup(
      Number(formChangesValues.price),
      Number(formChangesValues.discount),
      formChangesValues.discountType,
    );

    const calculatedMarkup = calculateDiscountOrMarkup(
      Number(formChangesValues.price),
      Number(formChangesValues.markup),
      formChangesValues.discountType,
    );

    return { calculatedDiscount, calculatedMarkup };
  };

  useDeepCompareEffect(() => {
    if (
      !isMobile &&
      (formChanges.article?.value || formChanges.name) &&
      formChanges.unit?.value &&
      formChanges.domain?.value &&
      formChanges.section?.value
    ) {
      const { calculatedDiscount, calculatedMarkup } = calculateDiscountAndMarkup(formChanges);

      dispatch(
        updatePosition(
          preparePositionData(
            formChanges,
            calculatedDiscount,
            calculatedMarkup,
            positions,
            operationType,
          ),
        ),
      );
    }
  }, [formChanges]);

  const onSubmit: SubmitHandler<SaleAddProductForm> = (data) => {
    reset();

    if (
      isMobile &&
      (formChanges.article?.value || formChanges.name) &&
      formChanges.unit?.value &&
      formChanges.domain?.value &&
      formChanges.section?.value
    ) {
      const { calculatedDiscount, calculatedMarkup } = calculateDiscountAndMarkup(formChanges);

      dispatch(
        addPosition(
          preparePositionData(
            formChanges,
            calculatedDiscount,
            calculatedMarkup,
            positions,
            operationType,
          ),
        ),
      );
    } else {
      dispatch(
        addPosition({
          id: positions[0] ? positions[0].id + 1 : 1,
          discount: 0,
          markup: 0,
          discountPercent: 0,
          markupPercent: 0,
          idUnit: Number(data?.unit?.id),
          unitShortName: data?.unit?.value?.ShortName || '',
          discountType: data.discountType,
          idSection: Number(data.section?.id),
          idDomain: Number(data?.domain?.id),
          sectionName: data.section?.label || '',
          isFixedDiscountOrMarkup: data.discountType === DISCOUNT_TYPES.FIXED,
          name: '',
          price: 0,
          productCode: '',
          qty: 1,
          storno: false,
          total: 0,
        }),
      );
    }
  };

  return {
    showScanner,
    isExciseWatch,
    discountTypeWatch,
    isHasBarcode,
    articlesOptions,
    domainsOptions,
    unitsOptions,
    sectionsOptions,
    form,
    isSimpleMode,
    isMobile,
    onSubmit,
    toggleScanner,
    setShowScanner,
    setCode,
    clearForm,
  };
};

export { useContainer };
