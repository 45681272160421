import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-center text-darkGray py-8">
      {t('receipt.online_cash_register_kassa24')} © {currentYear}
    </div>
  );
};

export { Footer };
