import { Icon } from '@/views/shared/Icon';
import { iconMap } from '@/views/shared/Icon/icon-map';

const Icons = () => {
  return (
    <div className="max-w-[680px] mt-8 mx-auto grid gap-4 grid-cols-5">
      {(Object.keys(iconMap) as Array<keyof typeof iconMap>).map((item) => {
        return (
          <div className="p-4">
            <Icon type={item} className="w-10 h-10 fill-black mx-auto" />
            <p className="text-center mt-2">{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export { Icons };
