import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { BalanceList } from '@/types/balance';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const balanceService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getBalances: build.query<ApiResponse<BalanceList>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_BALANCES, { idKkm }),
      providesTags: ['Operations'],
    }),
  }),
});

export const { useGetBalancesQuery, useLazyGetBalancesQuery } = balanceService;
