import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { LS_KEYS, PHONE_LENGTH, ROUTES } from '@/constants';
import { useNavigation, useAppDispatch, useAppSelector } from '@/hooks';
import { useAuthMutation } from '@/store/auth/service';
import { setUser } from '@/store/user/slice';
import type { AuthFormValuesType } from '@/types/auth';
import { setToLocalStorage } from '@/utils';
import { setStorageValue } from '@/utils/capacitor-storage';

const useContainer = () => {
  const { user } = useAppSelector((state) => state.user);
  const [auth, { isLoading }] = useAuthMutation();
  const { t } = useTranslation();
  const { goToMainPage } = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const prevPage =
    location.state && location.state.from && location.state.from.pathname
      ? location.state.from.pathname
      : ROUTES.MAIN;

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(prevPage);
    }
  }, [navigate, prevPage, user]);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .transform((currentValue) => currentValue.replace(/\s/g, '').replace('+', ''))
      .required(t('other.enter_phone'))
      .min(PHONE_LENGTH, t('other.check_phone_number_correctness')),
    password: Yup.string()
      .required(t('other.enter_password'))
      .min(6, t('other.at_least_6_characters')),
  });

  const defaultValues = useMemo(() => {
    return {
      phone: localStorage.getItem('phone') || '',
      password: '',
    };
  }, []);

  const methods = useForm<AuthFormValuesType>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const {
    formState: { errors },
    control,
    reset,
    watch,
    handleSubmit,
    register,
  } = methods;

  const signIn: SubmitHandler<AuthFormValuesType> = useCallback(
    (values) => {
      setIsError(false);
      auth({
        Login: values.phone.replace(/\s/g, ''),
        Password: values.password,
      })
        .unwrap()
        .then((response) => {
          const { Token, User } = response.Data;

          if (Capacitor.isNativePlatform()) {
            setStorageValue('token', Token);
          } else {
            Cookies.set('token', Token);
          }

          dispatch(setUser(User));

          const m = new Map();
          setToLocalStorage(LS_KEYS.DASHBOARD_MAP, Array.from(m.entries()));
          goToMainPage();
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
        });
    },
    [auth, dispatch, goToMainPage],
  );

  return {
    methods,
    isError,
    isLoading,
    errors,
    control,
    reset,
    watch,
    handleSubmit,
    register,
    signIn,
    t,
  };
};

export default useContainer;
