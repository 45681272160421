import { useTranslation } from 'react-i18next';

import { CASH_TITLE, NON_CASH_TITLE } from '@/constants/shift';
import { useAppSelector } from '@/hooks/useRedux';
import { useShift } from '@/hooks/useShift';
import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { Icon } from '@/views/shared/Icon';
import Modal from '@/views/shared/Modal';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const CloseShiftModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { toggleShift } = useShift();
  const { kkmItem: kkm } = useAppSelector((state) => state.cashbox);
  const { openedBalances } = useAppSelector((state) => state.balances);

  const getBalanceText = (text: string) => {
    if (text === CASH_TITLE) {
      return t('close_shift.cash_funds');
    }

    if (text === NON_CASH_TITLE) {
      return t('close_shift.cashless_funds');
    }

    return text;
  };

  const closeShift = async () => {
    if (kkm) await toggleShift(kkm);

    onClose();
  };

  return (
    <Modal onClose={onClose} open={isOpen} className="p-0">
      <div className="flex gap-2 items-center p-4">
        <Icon className="w-8 h-8" type="shiftWarning" />
        <p className="text-base font-semibold">{t('close_shift.attention_closing_shift')}</p>
      </div>
      <div className="text-sm text-darkGray-fourth px-4 mb-4">
        <p className="mb-1">{t('close_shift.shift_closure_results')}</p>
        <p>{t('close_shift.please_check_cash_register_balances')}</p>
      </div>
      <div className="p-4 bg-lightGray">
        {openedBalances?.map((balance) => (
          <div key={balance.Id} className="flex justify-between items-center mb-1 last:mb-0">
            <p>{getBalanceText(balance.TypeBalance.Name)}</p>
            <p className="font-semibold">
              {new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸
            </p>
          </div>
        ))}
      </div>
      <div className="p-4 flex gap-3 item-center">
        <Button onClick={onClose} className="w-full mb-2" kind={ButtonKinds.secondary}>
          {t('close_shift.cancel')}
        </Button>
        <Button onClick={closeShift} className="w-full" kind={ButtonKinds.danger}>
          {t('close_shift.close_shift')}
        </Button>
      </div>
    </Modal>
  );
};

export { CloseShiftModal };
