import { useTranslation } from 'react-i18next';

import { NEGATIVE_FULL, NO_CHANGE, POSITIVE_FULL } from '@/constants/shift';
import type { KkmChart } from '@/types/cashbox';
import { Icon } from '@/views/shared/Icon';

type Props = {
  chart: KkmChart;
};

const CLASS_STATS_CONTAINER = 'flex flex-col items-center justify-center flex-grow gap-1 pl-4';
const CLASS_TEXT_STATS = 'text-[#787878] font-semibold text-xs';

const calculateProportion = (curr: number, prev: number): string => {
  if (prev === 0 && curr > 0) {
    return POSITIVE_FULL;
  }

  if (curr === 0 && prev > 0) {
    return NEGATIVE_FULL;
  }

  if (prev === 0 && curr === 0) {
    return '0.00';
  }

  const proportion = ((curr * 100) / prev - 100).toFixed(2);

  return proportion;
};

const GetStats = ({ curr, prev }: { curr: number; prev: number }) => {
  if (curr < prev) {
    const proportion = calculateProportion(curr, prev);

    return (
      <div className={CLASS_STATS_CONTAINER}>
        <Icon type="statsDown" />
        <p className={CLASS_TEXT_STATS}>{proportion}%</p>
      </div>
    );
  }

  if (curr > prev) {
    const proportion = calculateProportion(curr, prev);

    return (
      <div className={CLASS_STATS_CONTAINER}>
        <Icon type="statsUp" />
        <p className={CLASS_TEXT_STATS}>+{proportion}%</p>
      </div>
    );
  }

  return (
    <div className={CLASS_STATS_CONTAINER}>
      <Icon type="statsNoChange" />
      <p className={CLASS_TEXT_STATS}>{NO_CHANGE}</p>
    </div>
  );
};

const Stats = ({ chart }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between gap-2 mb-4 overflow-x-scroll">
      <div className="bg-[#f1f4fd] px-4 py-2.5 rounded-lg flex items-center w-[232px]">
        <div className="border-r border-[#000000]/10 min-w-[133px] flex-grow">
          <p className="text-sm leading-4">{t('kassa.revenue')}</p>
          <p className="text-base font-bold">{chart.Sells.Total.toFixed(2)} ₸</p>
        </div>
        <GetStats curr={Math.round(chart.Sells.Total)} prev={Math.round(chart.OldSells)} />
      </div>
      <div className="bg-[#ecf9fe] px-4 py-2.5 rounded-lg flex items-center w-[232px]">
        <div className="border-r border-[#000000]/10 min-w-[133px] flex-grow">
          <p className="text-sm leading-4">{t('kassa.refunds')}</p>
          <p className="text-base font-bold">{chart.Refunds.Total.toFixed(2)} ₸</p>
        </div>
        <GetStats curr={Math.round(chart.Refunds.Total)} prev={Math.round(chart.OldRefunds)} />
      </div>
      <div className="bg-[#f7effe] px-4 py-2.5 rounded-lg flex items-center w-[232px]">
        <div className="border-r border-[#000000]/10 min-w-[133px] flex-grow">
          <p className="text-sm leading-4">{t('kassa.average_receipt')}</p>
          <p className="text-base font-bold">{chart.AvgCheck.toFixed(2)} ₸</p>
        </div>
        <GetStats curr={Math.round(chart.AvgCheck)} prev={Math.round(chart.OldAvgCheck)} />
      </div>
    </div>
  );
};

export { Stats };
