import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { formatAmount } from '@/utils/format-amount';

type Props = {
  label: string;
  icon: ReactNode;
  amount: number;
};
const TotalItem = ({ label, icon, amount }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="bg-lightGray rounded-md py-2 px-4 flex gap-2 items-center w-auto">
      {icon}
      <div>
        <p className="text-darkGray-five text-xs">{t(label)}</p>
        <p className="text-black text-sm font-semibold">{formatAmount(amount, true)}</p>
      </div>
    </div>
  );
};

export { TotalItem };
