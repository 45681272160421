import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { KKM_STATUS_DEFAULT } from '@/constants';
import type { OptionType } from '@/types';
import type { Kkm, KkmMetadata, KkmChart } from '@/types/cashbox';

type InitialState = {
  kkmList: Kkm[];
  kkmSearch?: string;
  kkmStatus: OptionType<number[] | null> | null;
  kkmMetadata: KkmMetadata;
  kkmId: number;
  kkmChartData: KkmChart | null;
  kkmItem: Kkm | null;
  totalReceiptCount: number;
};

const initialState: InitialState = {
  kkmList: [],
  kkmSearch: undefined,
  kkmStatus: KKM_STATUS_DEFAULT,
  kkmMetadata: {
    currentPage: 0,
    pageSize: 0,
    total: 0,
    totalPages: 0,
  },
  kkmId: 0,
  kkmChartData: null,
  kkmItem: null,
  totalReceiptCount: 0,
};

const cashboxSlice = createSlice({
  name: 'cashbox',
  initialState,
  reducers: {
    setKkmList: (state, { payload }: PayloadAction<Kkm[]>) => {
      state.kkmList = payload;
    },
    setKkmSearchValue: (state, { payload }: PayloadAction<string | undefined>) => {
      state.kkmSearch = payload;
    },
    setKkmMetadata: (state, { payload }: PayloadAction<KkmMetadata>) => {
      state.kkmMetadata = payload;
    },
    setKkmPage: (state, { payload }: PayloadAction<number>) => {
      state.kkmMetadata = {
        ...state.kkmMetadata,
        currentPage: payload,
      };
    },
    setKkmStatus: (state, { payload }: PayloadAction<OptionType<number[] | null> | null>) => {
      state.kkmStatus = payload;
    },
    setKkmId: (state, { payload }: PayloadAction<number>) => {
      state.kkmId = payload;
    },
    setKkmChartData: (state, { payload }: PayloadAction<KkmChart>) => {
      state.kkmChartData = payload;
    },
    setKkmItem: (state, { payload }: PayloadAction<Kkm | null>) => {
      state.kkmItem = payload;
    },
    setTotalReceiptCount: (state, { payload }: PayloadAction<number>) => {
      state.totalReceiptCount = payload;
    },
  },
});

export default cashboxSlice.reducer;

export const {
  setKkmList,
  setKkmSearchValue,
  setKkmStatus,
  setKkmMetadata,
  setKkmPage,
  setKkmId,
  setKkmChartData,
  setKkmItem,
  setTotalReceiptCount,
} = cashboxSlice.actions;
