import { StatusCodes } from 'http-status-codes';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  useLazySearchSectionsQuery,
  useUpdateSectionStatusMutation,
} from '@/store/settings/service';
import { setSectionList } from '@/store/settings/slice';

const useContainer = ({ refetch }: { refetch: () => void }) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [searchSection, { isLoading: isLoadingSearch }] = useLazySearchSectionsQuery();
  const [updateSectionStatus] = useUpdateSectionStatusMutation();
  const { t } = useTranslation();

  const { sectionList } = useAppSelector((state) => state.settings);
  const [searchValue, setSearchValue] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);

  const kkmId = useMemo(() => Number(params?.idKkm), [params.idKkm]);
  const tableHead = useMemo(
    () => [
      {
        label: t('kassa_settings.name'),
        code: 'Name',
      },
      { label: t('kassa_settings.vat_rate'), code: 'Nds' },
      { label: t('kassa_settings.actions'), code: 'Operations' },
    ],
    [t],
  );

  const handleSearchSectionList = useCallback(() => {
    searchSection({ idKkm: kkmId, searchValue })
      .unwrap()
      .then((result) => dispatch(setSectionList(result?.Data?.Sections)))
      .catch((error) => console.log(error));
  }, [dispatch, kkmId, searchSection, searchValue]);

  const changeSectionStatus = useCallback(
    (id: number) => () => {
      updateSectionStatus({ idKkm: kkmId, id })
        .unwrap()
        .then((result) => {
          if (result?.Status === StatusCodes.OK) {
            refetch();
          }
        });
    },
    [kkmId, refetch, updateSectionStatus],
  );
  const handleAddSection = useCallback(() => refetch(), [refetch]);

  return {
    tableHead,
    t,
    isLoading: isLoadingSearch,
    sectionList,
    searchValue,
    showAddModal,
    handleSearchSectionList,
    changeSectionStatus,
    setSearchValue,
    setShowAddModal,
    handleAddSection,
  };
};

export default useContainer;
