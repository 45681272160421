import type { EnvModeUrlType } from '@/types/app';

export const ROUTES = {
  PURCHASE: '/www/kkms/:idKkm/purchase',
  INCOME: '/www/kkms/:idKkm/income',
  EXPENSE: '/www/kkms/:idKkm/expense',
  REFUND: '/www/kkms/:idKkm/refund',
  PURCHASE_REFUND: '/www/kkms/:idKkm/purchase_refund',
  MAIN: '/www/comps',
  SIGNIN: '/',
  SHIFT: '/www/kkms/:idKkm/shift',
  SALE: '/www/kkms/:idKkm/sale',
  FORBIDDEN_DEFAULT: '/www/kkms/:idKkm/settings/forbiden_default',
  FORBIDDEN_OTHER: '/www/kkms/:idKkm/settings/forbiden_other',
  SETTINGS: '/www/kkms/:idKkm/settings',
  REPORT: '/www/shifts/:idShift/report',
  OPERATION_REFUND: '/www/kkms/:idKkm/refund/:id',
  OPERATION_PURCHASE_REFUND: '/www/kkms/:idKkm/purchase_refund/:id',
  ICONS: '/icons',
  CLOSE_SHIFT: '/www/kkms/:idKkm/close',
  BILLCHECK: '/www/billcheck',
  SHOWCHECK: '/www/showcheck',
  OPERATION_SUCCESS: '/www/kkms/:idKkm/:idReport/success',
  REPORT_ITEM: '/www/shifts/:idShift/report/:reportItem',
} as const;

export const ROUTES_MENU_LINKS = [
  ROUTES.SALE,
  ROUTES.PURCHASE,
  ROUTES.INCOME,
  ROUTES.EXPENSE,
  ROUTES.REFUND,
  ROUTES.PURCHASE_REFUND,
] as const;

export const PULT_URL: EnvModeUrlType = {
  production: {
    REGISTER: 'https://business.kassa24.kz/cabinet/registration',
    FORGOT_PASSWORD: 'https://business.kassa24.kz/cabinet/reset',
  },
  development: {
    REGISTER: 'https://business-test.kassa24pay.kz/cabinet/registration',
    FORGOT_PASSWORD: 'https://business-test.kassa24pay.kz/cabinet/reset',
  },
};
