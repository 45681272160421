import { FormProvider } from 'react-hook-form';

import type { FormPropsType } from '@/types/form';

const Form = ({ onSubmit, children, form }: FormPropsType) => {
  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} aria-label="form" className="w-full">
        {children}
      </form>
    </FormProvider>
  );
};

export { Form };
