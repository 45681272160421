import { useTranslation } from 'react-i18next';

import { SpinnerIcon } from '@/views/shared/Spinner';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="text-blue w-full h-full text-base flex justify-center items-center gap-2 mt-4">
      <SpinnerIcon className="stroke-blue w-6 h-6" />
      <div>{t('main.loading')}</div>
    </div>
  );
};

export { Loading };
