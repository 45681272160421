import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants/routes';
import { buildAppRouteUrl } from '@/utils/build-route-url';

const useNavigation = () => {
  const navigate = useNavigate();

  const goToMainPage = useCallback(() => navigate(ROUTES.MAIN), [navigate]);

  const goToShift = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.SHIFT, { idKkm })),
    [navigate],
  );

  const goToSale = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.SALE, { idKkm })),
    [navigate],
  );

  const goToForbiddenDefault = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.FORBIDDEN_DEFAULT, { idKkm })),
    [navigate],
  );

  const goToForbiddenOther = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.FORBIDDEN_OTHER, { idKkm })),
    [navigate],
  );

  const goToSettings = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.SETTINGS, { idKkm })),
    [navigate],
  );

  const goToReport = useCallback(
    (idShift: string | number) => navigate(buildAppRouteUrl(ROUTES.REPORT, { idShift })),
    [navigate],
  );

  const goToRefundSale = useCallback(
    (idKkm: number, id: number) =>
      navigate(buildAppRouteUrl(ROUTES.OPERATION_REFUND, { idKkm, id })),
    [navigate],
  );

  const goToRefundPurchase = useCallback(
    (idKkm: number, id: number) =>
      navigate(buildAppRouteUrl(ROUTES.OPERATION_PURCHASE_REFUND, { idKkm, id })),
    [navigate],
  );

  const goToCloseShift = useCallback(
    (idKkm: string | number) => navigate(buildAppRouteUrl(ROUTES.CLOSE_SHIFT, { idKkm })),
    [navigate],
  );

  const goToOperationSuccess = useCallback(
    (idKkm: string | number, idReport: string | number) =>
      navigate(buildAppRouteUrl(ROUTES.OPERATION_SUCCESS, { idKkm, idReport })),
    [navigate],
  );

  return {
    goToMainPage,
    goToShift,
    goToSale,
    goToForbiddenDefault,
    goToForbiddenOther,
    goToReport,
    goToSettings,
    goToRefundSale,
    goToCloseShift,
    goToRefundPurchase,
    goToOperationSuccess,
  };
};

export { useNavigation };
