import useContainer from './hook';

import type { OperationType } from '@/constants/operations';
import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { SearchInput } from '@/views/shared/SearchInput';

const SearchReceipt = ({ operationType }: { operationType: OperationType }) => {
  const { t, openedShift, searchValue, searchReceipt, setSearchValue, goToReport } = useContainer({
    operationType,
  });

  if (!openedShift) {
    return null;
  }

  return (
    <div className="bg-white rounded-lg outline-none w-full">
      <div className="flex justify-between items-center p-4 border-b border-lightGray-five">
        <h3 className="font-semibold text-lg leading-5"> {t('main.title_search_refund')}</h3>
      </div>
      <div className="p-4">
        <SearchInput
          placeholder={t('report.search_on_receipt_number')}
          onChange={(e) => setSearchValue(e.target.value.trim())}
          value={searchValue}
          onSearch={searchReceipt}
          withoutHide
        />
        <p className="text-lightGray-third font-sm text-center my-3">
          {t('main.info_search_refund')}
        </p>
        <Button
          className="text-blue mx-auto"
          onClick={() => goToReport(openedShift.Id)}
          text={t('main.go_to_operation_log')}
          kind={ButtonKinds.simple}
        />
      </div>
    </div>
  );
};

export { SearchReceipt };
