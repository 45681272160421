import { Capacitor } from '@capacitor/core';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

import { PULT_API_URL } from '@/constants/api';
import { getStorageValue } from '@/utils/capacitor-storage';

const baseQuery = fetchBaseQuery({
  baseUrl: PULT_API_URL,
  prepareHeaders: async (headers) => {
    let token: string | null | undefined;

    if (Capacitor.isNativePlatform()) {
      token = (await getStorageValue('token')).value;
    } else {
      token = Cookies.get('token');
    }

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  return baseQuery(args, api, extraOptions);
};

export const pultApi = createApi({
  reducerPath: 'pultApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: () => ({}),
});

export const enhancedApi = pultApi.enhanceEndpoints({
  endpoints: () => ({}),
});
