import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { OPERATION_TYPES, ROUTES } from '@/constants';
import { useIsMobile, useAppSelector } from '@/hooks';
import { buildAppRouteUrl } from '@/utils';

const useContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobile } = useIsMobile();
  const { reportList } = useAppSelector((state) => state.reports);
  const { openedShift } = useAppSelector((state) => state.shift);
  const idShift = Number(params.idShift);

  const isHasOpenedShift = useMemo(() => {
    if (!openedShift) {
      return false;
    }

    return idShift && idShift === openedShift.Id;
  }, [idShift, openedShift]);

  const [showReportOperationsModal, setShowReportOperationsModal] = useState(false);

  const [selectedOperationId, setSelectedOperationId] = useState(0);

  const showOperationDuplicateModal = useCallback(
    (reportId: number) => () => {
      setShowReportOperationsModal(true);
      setSelectedOperationId(reportId);
    },
    [],
  );

  const handleCloseReportOperationsModal = useCallback(() => {
    setShowReportOperationsModal(false);
    setSelectedOperationId(0);
  }, []);

  const goToFund = useCallback(
    (id: number, type: number, idKkm: number) => () => {
      return type === OPERATION_TYPES.SALE.ID
        ? navigate(buildAppRouteUrl(ROUTES.OPERATION_REFUND, { idKkm, id }))
        : navigate(buildAppRouteUrl(ROUTES.OPERATION_PURCHASE_REFUND, { idKkm, id }));
    },
    [navigate],
  );

  return {
    t,
    isMobile,
    reportList,
    showReportOperationsModal,
    selectedOperationId,
    isHasOpenedShift,
    handleCloseReportOperationsModal,
    showOperationDuplicateModal,
    goToFund,
  };
};

export default useContainer;
