import { useCallback } from 'react';

import { Button } from '../Button';
import { CustomPagination } from '../CustomPagination';

import type { Props, HeadItems, Items } from './hook';
import useContainer from './hook';

import ArrowDownSFill from '@/assets/icons/arrow-down-s-fill.svg?react';
import ArrowUpSFill from '@/assets/icons/arrow-up-s-fill.svg?react';
import { REPORT_COUNT } from '@/constants/reports';
import { ButtonKinds } from '@/types/button';
import { twMergeClsx } from '@/utils/tw-with-clsx';

const Table = ({
  heads,
  rows,
  pageCount = REPORT_COUNT,
  className,
  classCellName,
  classHeadCellName,
  tableClassName,
  contentBeforeHead,
}: Props) => {
  const {
    sortAscending,
    sortProperty,
    totalPages,
    currentPage,
    currentRowList,
    isPrint,
    setCurrentPage,
    sortRows,
  } = useContainer({
    heads,
    rows,
    pageCount,
  });

  const getSortIcon = useCallback(
    (prop: string | undefined) => {
      if (!prop) return null;

      if (prop === sortProperty && !sortAscending) {
        return <ArrowUpSFill className="inline-flex ml-1" />;
      }

      return <ArrowDownSFill className="inline-flex  ml-1" />;
    },
    [sortAscending, sortProperty],
  );

  return (
    <div className={twMergeClsx('shadow-[0_0_8px_#40404014] bg-white rounded-lg', tableClassName)}>
      {contentBeforeHead && <div className="p-3">{contentBeforeHead}</div>}
      <div className="table w-full ">
        <div className="hidden lg:table-row text-xs">
          {heads?.map((head: HeadItems) => (
            <div
              className={twMergeClsx(
                'table-cell text-darkGray-five p-3 border-b border-b-lightGray-second font-semibold',
                classHeadCellName,
              )}
              key={head.code}
            >
              {head?.sortProp ? (
                <Button
                  kind={ButtonKinds.iconOnly}
                  onClick={() => sortRows(head)}
                  className="text-xs text-left m-0 p-0"
                >
                  {head.label}
                  {getSortIcon(head.sortProp)}
                </Button>
              ) : (
                head.label
              )}
            </div>
          ))}
        </div>
        {currentRowList &&
          (!isPrint
            ? currentRowList.slice(pageCount * currentPage, pageCount * (currentPage + 1))
            : currentRowList
          ).map((row: Items) => (
            <div
              className={twMergeClsx(
                'flex flex-wrap lg:table-row hover:bg-white-second justify-between border-b border-b-lightGray lg:border-0 py-3 lg:p-0 items-center',
                className,
              )}
            >
              {heads?.map((head) => (
                <div
                  key={`${head.code}row`}
                  className={twMergeClsx(
                    'table lg:table-cell px-2 py-1 lg:p-3 lg:border-b border-b-lightGray-second font-semibold',
                    classCellName,
                  )}
                >
                  {row[head.code]}
                </div>
              ))}
            </div>
          ))}
      </div>
      {!isPrint && (
        <div className="p-3 flex items-center justify-center lg:justify-end gap-2">
          <CustomPagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
            showTotalCount
            showGoPage
            totalItemCount={rows.length}
            itemOnPage={pageCount}
          />
        </div>
      )}
    </div>
  );
};

export { Table };
