import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type {
  ArticleResponse,
  DomainResponse,
  RefundPurchaseResponse,
  SaleRequest,
  SaleResponse,
  SectionResponse,
  Unit,
} from '@/types/operations';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

export const operationsService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getArticles: build.query<ApiResponse<ArticleResponse>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_ARTICLES, { idKkm }),
    }),
    getUnits: build.query<ApiResponse<Unit[]>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_UNITS, { idKkm }),
    }),
    getDomains: build.query<ApiResponse<DomainResponse>, unknown>({
      query: () => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_DOMAINS),
    }),
    getSections: build.query<ApiResponse<SectionResponse>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SECTIONS, { idKkm }),
    }),
    getReceipt: build.query<ApiResponse<string>, string>({
      query: (queryString) => `${FISCAL_API_ENDPOINTS.GET_RECEIPT}?${queryString}`,
    }),
    sendSale: build.mutation<ApiResponse<SaleResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SEND_SALE, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
    sendPurchase: build.mutation<ApiResponse<SaleResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SEND_PURCHASES, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
    refundPurchase: build.mutation<ApiResponse<RefundPurchaseResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.PURCHASE_REFUND, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
    refundSale: build.mutation<ApiResponse<RefundPurchaseResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SALE_REFUND, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
    sendIncome: build.mutation<ApiResponse<SaleResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SEND_INCOMES, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
    sendExpense: build.mutation<ApiResponse<SaleResponse>, SaleRequest>({
      query: ({ IdKkm, Uid, body }: SaleRequest) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SEND_EXPENSES, { idKkm: IdKkm }),
        method: 'POST',
        body,
        headers: {
          uid: Uid,
        },
      }),
      invalidatesTags: ['Operations'],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useLazyGetArticlesQuery,
  useGetDomainsQuery,
  useLazyGetDomainsQuery,
  useGetSectionsQuery,
  useLazyGetSectionsQuery,
  useGetUnitsQuery,
  useLazyGetUnitsQuery,
  useSendSaleMutation,
  useGetReceiptQuery,
  useLazyGetReceiptQuery,
  useSendPurchaseMutation,
  useRefundPurchaseMutation,
  useSendIncomeMutation,
  useSendExpenseMutation,
  useRefundSaleMutation,
} = operationsService;
