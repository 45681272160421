import Modal from '@/views/shared/Modal';
import { OperationConfirmInfo } from '@/views/shared/OperationConfirmInfo';

type Props = {
  isOpen: boolean;
  reportId: number;
  onClose: () => void;
};

const DuplicateModal = ({ isOpen, reportId, onClose }: Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="bg-transparent max-w-full m-auto flex justify-center p-0"
    >
      <OperationConfirmInfo reportId={reportId} isModal onClose={onClose} />
    </Modal>
  );
};

export { DuplicateModal };
