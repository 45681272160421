import { twMerge } from 'tailwind-merge';

import type { IButton } from '@/types/button';
import { ButtonKinds } from '@/types/button';

const useContainer = ({ className, kind, disabled }: IButton) => {
  const buttonClassNames = twMerge(
    disabled && 'cursor-not-allowed',
    kind === ButtonKinds.primary && !disabled && 'bg-blue text-white hover:bg-darkBlue',
    kind === ButtonKinds.primary && disabled && 'bg-blue/50 text-white',
    kind === ButtonKinds.danger && !disabled && 'bg-red text-white',
    kind === ButtonKinds.danger && disabled && 'bg-red/50 text-black',
    kind === ButtonKinds.secondary &&
      !disabled &&
      'text-darkGray-third bg-lightGray hover:ring-1 ring-darkGray-second/50 transition font-semibold',
    kind === ButtonKinds.secondary && disabled && 'text-lightGray',
    kind === ButtonKinds.warning && !disabled && 'bg-lightViolet text-black',
    kind === ButtonKinds.success && !disabled && 'bg-green text-white',
    kind === ButtonKinds.secondaryIcon &&
      'border border-lightGray-second text-darkGray-fourth flex gap-1 items-center hover:bg-darkGray hover:text-white',
    className,
    kind === ButtonKinds.warningSecondary && 'bg-lightRed text-darkGray-fourth',
    kind === ButtonKinds.secondaryGray && 'bg-lightGray text-darkGray-fourth',
    kind === ButtonKinds.simple && 'bg-transparent hover:opacity-80 h-auto p-0 m-0 font-normal',
    className,
  );

  return {
    buttonClassNames,
  };
};

export default useContainer;
