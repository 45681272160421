import { TabGroup, TabList, TabPanels, TabPanel, Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { ReportHead } from './Head';
import useContainer, { tabList } from './hook';
import { Operations } from './Operations';
import { SectionsReport } from './Sections';
import { XReport } from './XReport';
import { ZReport } from './ZReport';
import { DuplicateZReportPage } from './ZReport/DuplicatePage';

import { useAppSelector } from '@/hooks/useRedux';

const Reports = () => {
  const { activeTabId, onChangeTab } = useContainer();
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);
  const { shift, openedShift } = useAppSelector((state) => state.shift);

  if (selectedZReportData) {
    return <DuplicateZReportPage />;
  }

  return (
    <>
      <ReportHead step={activeTabId} />
      <TabGroup selectedIndex={activeTabId} onChange={onChangeTab}>
        <TabList className="grid grid-cols-2 lg:grid-cols-4 rounded-lg border border-lightGray-second text-lg overflow-hidden bg-white">
          {tabList.map((tabItem) => (
            <Tab
              key={tabItem.id}
              disabled={openedShift?.Id !== shift?.Id && tabItem.id === 1}
              className={twMerge(
                ' text-blue p-3 outline-none lg:first-of-type:border-0 border-l border-lightGray-second hover:bg-white-second border-b lg:border-b-0 last-of-type:border-b-0 [&:nth-child(3)]:border-b-0 ',
                activeTabId === tabItem.id && 'bg-lightGray-five',
              )}
            >
              {t(tabItem.name)}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Operations />
          </TabPanel>
          <TabPanel>
            <XReport />
          </TabPanel>
          <TabPanel>
            <ZReport />
          </TabPanel>
          <TabPanel>
            <SectionsReport />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
};

export { Reports };
