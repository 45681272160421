import type { ErrorType } from '@/types/operations';

export const DOMAIN_TITLES = {
  DOMAIN_TRADING: 'kassa.trade',
  DOMAIN_SERVICES: 'kassa.services',
  DOMAIN_LOMBARD: 'kassa.lombard',
  DOMAIN_GASOIL: 'kassa.gasoil',
  DOMAIN_HOTELS: 'kassa.hotels',
  DOMAIN_TAXI: 'kassa.taxi',
  DOMAIN_PARKING: 'kassa.parking',
} as const;

export const DISCOUNT_TYPES = {
  PERCENT: 'percent',
  FIXED: 'fixed',
};

export enum OperationType {
  SALE = 1,
  PURCHASE = 2,
  REFUND = 3,
  PURCHASE_REFUND = 4,
  INCOME = 5,
  EXPENSE = 6,
}

export const ERROR_DEFAULT_STATE: ErrorType = { text: '', btnName: '', btnOnClick: () => {} };
