import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks/useRedux';
import { formatAmount } from '@/utils/format-amount';
import { getObjectElement } from '@/utils/getObjectElement';
import { DefinitionItem } from '@/views/shared/Definition/DefinitionItem';
import { DefinitionList } from '@/views/shared/Definition/DefinitionList';
import { DefinitionSection } from '@/views/shared/Definition/DefinitionSection';
import { DisclosureCustom } from '@/views/shared/Disclosure';

const Receipts = () => {
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);
  const reportZReceiptTitles = [
    {
      title: t('report.service_income'),
      property: 'Incomes',
      amount: 'IncomesQty',
      total: 'IncomesAmount',
    },
    {
      title: t('report.service_expense'),
      property: 'Expenses',
      amount: 'ExpensesQty',
      total: 'ExpensesAmount',
    },
    {
      title: t('report.sale'),
      property: 'Sales',
      amount: 'SalesQty',
      total: 'SalesAmount',
    },
    {
      title: t('report.sales_refunds'),
      property: 'Refunds',
      amount: 'RefundsQty',
      total: 'RefundsAmount',
    },
    {
      title: t('report.purchases'),
      property: 'Purchases',
      amount: 'PurchasesQty',
      total: 'PurchasesAmount',
    },
    {
      title: t('report.purchase_refunds'),
      property: 'PurchaseRefunds',
      amount: 'PurchaseRefundsQty',
      total: 'PurchaseRefundsAmount',
    },
  ];

  const renderDefinition = (item: {
    title: string;
    property: string;
    amount: string;
    total: string;
  }) => {
    const totalData = selectedZReportData?.Total
      ? getObjectElement(selectedZReportData.Total, item.property)
      : {
          Qty: getObjectElement(selectedZReportData, item.amount),
          Amount: getObjectElement(selectedZReportData, item.total),
        };

    return totalData.Qty > 0 ? (
      <>
        <DefinitionItem title={t('report.number_of_receipts')} value={totalData.Qty} />
        <DefinitionItem
          title={t('report.total_amount')}
          value={formatAmount(totalData.Amount, true)}
        />
      </>
    ) : (
      <div className="text-darkGray mt-1">{t('report.no_receipts')}</div>
    );
  };

  return (
    <DisclosureCustom
      btnName={t('report.registered_receipts')}
      btnClassName="bg-white px-3 py-2 border-b border-b-lightGray-second lg:hidden print:hidden"
    >
      <DefinitionSection title={t('report.registered_receipts')}>
        {reportZReceiptTitles?.map((reportZItem) => {
          return (
            <DefinitionList key={reportZItem.title} className="">
              <div className="font-semibold text-black">{reportZItem.title}</div>

              {renderDefinition(reportZItem)}
            </DefinitionList>
          );
        })}
      </DefinitionSection>
    </DisclosureCustom>
  );
};

export { Receipts };
