import { StatusCodes } from 'http-status-codes';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  useGetCashierListQuery,
  useLazySearchCashiersQuery,
  useUpdateCashierStatusMutation,
} from '@/store/settings/service';
import { setCashierList } from '@/store/settings/slice';

const useContainer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { refetch, isLoading } = useGetCashierListQuery(Number(params?.idKkm), {
    skip: !params.idKkm,
  });
  const [searchCashiers, { isLoading: isLoadingSearch }] = useLazySearchCashiersQuery();
  const [updateCashierStatus] = useUpdateCashierStatusMutation();
  const { t } = useTranslation();

  const { cashierList } = useAppSelector((state) => state.settings);
  const [searchValue, setSearchValue] = useState('');

  const kkmId = useMemo(() => Number(params.idKkm), [params.idKkm]);
  const tableHead = useMemo(
    () => [
      {
        label: t('kassa_settings.cashier'),
        code: 'UserName',
      },
      { label: t('kassa_settings.actions'), code: 'Operations' },
    ],
    [t],
  );

  const handleSearchCashierList = useCallback(() => {
    searchCashiers({ idKkm: kkmId, searchValue })
      .unwrap()
      .then((result) => dispatch(setCashierList(result?.Data)))
      .catch((error) => console.log(error));
  }, [dispatch, kkmId, searchCashiers, searchValue]);

  const changeCashierActivity = useCallback(
    (id: number) => () => {
      updateCashierStatus({ idKkm: kkmId, id })
        .unwrap()
        .then((result) => {
          if (result?.Status === StatusCodes.OK) {
            refetch();
          }
        });
    },
    [kkmId, refetch, updateCashierStatus],
  );

  return {
    tableHead,
    t,
    isLoading: isLoading || isLoadingSearch,
    cashierList,
    searchValue,
    handleSearchCashierList,
    changeCashierActivity,
    setSearchValue,
  };
};

export default useContainer;
