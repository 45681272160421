import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { Error } from '../Error';
import { Label } from '../Label';

import type { AmountInputFormPropsType } from '@/types/form';
import { twMergeClsx } from '@/utils/tw-with-clsx';

const AmountInput = ({
  name,
  control,
  label,
  decimalScale = 2,
  decimalSeparator = '.',
  fixedDecimalScale = false,
  placeholder,
  errorText = '',
  className,
  max,
  min,
  tooltipContent,
  tooltipId,
  isAllowed,
  ...rest
}: AmountInputFormPropsType) => {
  const {
    formState: { errors },
  } = useFormContext();

  const getLabel = useCallback(() => {
    if (rest['aria-invalid']) {
      return <Error text={errors.amount?.message?.toString() || errorText} />;
    }

    return <Label name={name} text={label} tooltipContent={tooltipContent} tooltipId={tooltipId} />;
  }, [errorText, errors, label, name, rest, tooltipContent, tooltipId]);

  return (
    <div className="w-full">
      {getLabel()}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <NumericFormat
            onBlur={onBlur}
            onValueChange={(val) => onChange(val.value)}
            thousandSeparator=" "
            decimalSeparator={decimalSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            id={name}
            placeholder={placeholder}
            value={value}
            max={max}
            min={min}
            isAllowed={isAllowed}
            {...rest}
            className={twMergeClsx(
              'focus:border-blue px-4 py-3 w-full bg-white border border-lightGray-second h-11 rounded-xl outline-none',
              className,
            )}
          />
        )}
      />
    </div>
  );
};

export { AmountInput };
