import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { SETTINGS_TABS } from '@/constants/settings';
import { useNavigation } from '@/hooks/useNavigate';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useShift } from '@/hooks/useShift';
import { useGetKkmsByIdQuery } from '@/store/cashbox/service';
import { useGetSectionsListQuery } from '@/store/settings/service';
import { setHeadSnackbarName } from '@/store/settings/slice';

const useContainer = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const { updateShiftStatus } = useShift();
  const { goToShift } = useNavigation();
  const { headSnackbarName } = useAppSelector((state) => state.settings);
  const { user } = useAppSelector((state) => state.user);
  const { shiftStatus } = useAppSelector((state) => state.shift);

  const kkmId = useMemo(() => Number(params?.idKkm), [params.idKkm]);

  const { data, refetch } = useGetKkmsByIdQuery(kkmId, {
    skip: !params?.idKkm,
  });
  const { refetch: refetchSections } = useGetSectionsListQuery(kkmId, {
    skip: !params.idKkm,
  });

  const [activeTabId, setActiveTabId] = useState(SETTINGS_TABS.KASSA);

  useEffect(() => {
    dispatch(setHeadSnackbarName(data?.Data.Kkm.Name ?? ''));

    if (data?.Data.Kkm && user) updateShiftStatus({ kkmItem: data?.Data.Kkm, userItem: user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, user]);

  return {
    t,
    activeTabId,
    shiftStatus,
    headSnackbarName,
    kkmId,
    goToShift,
    setActiveTabId,
    refetch,
    refetchSections,
  };
};

export default useContainer;
