export const REPORT_TYPES = {
  OPERATIONS: 0,
  X_REPORT: 1,
  Z_REPORT_ARCHIVE: 2,
  SECTIONS: 3,
};

export const REPORT_DATE_TYPE = {
  DATE_FROM: 1,
  DATE_TO: 2,
};

export const REPORT_COUNT = 10;

export const REPORT_DOCS_TYPE_WITHOUT_DUPLICATE = 7;

export const REPORT_SEND_TYPES = {
  EMAIL: 0,
  WHATAPP: 1,
};
