import type { ButtonHTMLAttributes, ReactNode } from 'react';

export enum ButtonKinds {
  primary = 'primary',
  secondary = 'secondary',
  secondaryGray = 'secondaryGray',
  iconOnly = 'iconOnly',
  danger = 'danger',
  warning = 'warning',
  success = 'success',
  secondaryIcon = 'secondaryIcon',
  warningSecondary = 'warningSecondary',
  simple = 'simple',
}

export enum ButtonType {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}

export interface IButton
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>, 'onClick'> {
  text?: string;
  className?: string;
  kind?: ButtonKinds;
  disabled?: boolean;
  type?: ButtonType;
  isLoading?: boolean;
  onClick?: (value: any) => void;
  href?: string;
  children?: ReactNode;
  withError?: boolean;
  Icon?: ReactNode;
}
