import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Analytics } from './Analytics';
import { Head } from './Head';
import { Info } from './Info';

import { MEDIA_POINTS } from '@/constants';
import { useAppSelector, useShift } from '@/hooks';
import { useGetBalancesQuery } from '@/store/balance/service';
import { useGetKkmChartQuery, useGetKkmsByIdQuery } from '@/store/cashbox/service';
import { useGetUserQuery } from '@/store/user/service';
import { twMergeClsx } from '@/utils';

const ShiftContainer = () => {
  const { user } = useAppSelector((state) => state.user);
  const params = useParams();
  const idKkm = Number(params?.idKkm);
  const userId = Number(user?.Id);

  const { data: kkmData } = useGetKkmsByIdQuery(idKkm);
  const { data: balancesData } = useGetBalancesQuery(idKkm);
  const { data: chartData } = useGetKkmChartQuery(idKkm);
  const { data: userData } = useGetUserQuery(userId);
  const { updateShiftStatus, setCurrentShift } = useShift();

  const { t } = useTranslation();

  const isMobile = useMedia(MEDIA_POINTS.LG);

  const operations = useMemo(() => {
    return (
      chartData?.Data.ChartsData.LineChart.Operations?.map((item) => ({
        ...item,
        Time: format(new Date(item.Time), 'HH:mm'),
      })) || []
    );
  }, [chartData]);

  useEffect(() => {
    if (kkmData && userData) {
      setCurrentShift({ idShift: kkmData.Data.Kkm.IdShift });
      updateShiftStatus({
        kkmItem: kkmData.Data.Kkm,
        userItem: userData.Data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkmData, userData]);

  const tabList = [
    {
      id: 0,
      name: t('other.cash_register_info'),
    },
    {
      id: 1,
      name: t('other.analytics'),
    },
  ];

  return (
    <>
      <Head />
      {isMobile ? (
        <TabGroup>
          <TabList className="grid grid-cols-2 rounded-lg border border-lightGray-second overflow-hidden bg-white mb-4">
            {tabList.map((item) => (
              <Tab
                key={item.id}
                className={twMergeClsx(
                  'text-blue p-3 outline-none lg:first-of-type:border-0 border-l border-lightGray-second hover:bg-white-second data-[selected]:bg-lightGray-five',
                )}
              >
                {item.name}
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            <TabPanel>
              {balancesData && kkmData && (
                <Info balances={balancesData?.Data.Balances} kkm={kkmData?.Data.Kkm} />
              )}
            </TabPanel>
            <TabPanel>
              {chartData?.Data && (
                <Analytics
                  idKkm={Number(params?.idKkm)}
                  chart={chartData?.Data}
                  operations={operations}
                />
              )}
            </TabPanel>
          </TabPanels>
        </TabGroup>
      ) : (
        <div className="flex gap-4 items-start">
          {chartData?.Data && (
            <Analytics
              idKkm={Number(params?.idKkm)}
              chart={chartData?.Data}
              operations={operations}
            />
          )}
          {balancesData && kkmData && (
            <Info balances={balancesData?.Data.Balances} kkm={kkmData?.Data.Kkm} />
          )}
        </div>
      )}
    </>
  );
};

export { ShiftContainer };
