import { APP_MODE, NODE_ENV } from './app';

export const FISCAL_API_URL = 'https://api.fiscalv3.tech24.kz';

export const PULT_API_URL = 'https://pult-test.kassa24pay.kz';

export const FISCAL_API_ENDPOINTS = {
  SIGN_IN: '/auth',
  GET_COMPANIES: '/companies',
  GET_KKMS_BY_COMPANY_ID: '/kkms',
  GET_KKM_BY_ID: '/kkms/:idKkm',
  GET_SHIFT: '/shifts/:idShift',
  GET_OPERATIONS_REPORT: '/kkms/:idKkm/reports',
  DOWNLOAD_REPORT_FILE: '/kkms/:idKkm/docs/xlsx/bytime',
  DOWNLOAD_Z_REPORT_FILE: '/kkms/:idKkm/zlog/xlsx',
  GET_BALANCES: '/kkms/:idKkm/balances',
  GET_KKM_CHART: '/kkms/:idKkm/chartsdata',
  OPEN_SHIFT: '/shifts',
  CLOSE_SHIFT: '/shifts/:idShift/z',
  X_REPORT: '/shifts/:idShift/x',
  GET_USER: '/users/:idUser',
  OPERATION_DETAILS: '/operations/',
  SEND_RECEIPT: '/www/sendcheck/:idReceipt',
  GET_UID: '/uid/:idKkm',
  GET_ARTICLES: '/kkms/:idKkm/articles',
  GET_UNITS: '/kkms/:idKkm/units',
  GET_DOMAINS: '/directories/domains',
  GET_SECTIONS: '/kkms/:idKkm/sections',
  SECTIONS_REPORT: '/shifts/:idShift/sections/reports',
  Z_REPORT: '/kkms/:idKkm/zlog',
  OFD_SETTINGS: '/kkms/:idKkm/settings/ofds',
  GET_CASHIERS: '/kkms/:idKkm/settings/cashiers',
  UPDATE_CASHIER_STATUS: '/kkms/:idKkm/settings/cashiers/:id/toggle',
  UPDATE_SECTION_STATUS: '/kkms/:idKkm/settings/sections/:id/toggle',
  GET_SETTINGS_ARTICLES: '/kkms/:idKkm/settings/articles',
  SEND_SALE: '/kkms/:idKkm/sales',
  GET_RECEIPT: '/receipt',
  SEND_PURCHASES: '/kkms/:idKkm/purchases',
  PURCHASE_REFUND: '/kkms/:idKkm/purchase_refunds',
  SALE_REFUND: '/kkms/:idKkm/refunds',
  SEND_INCOMES: '/kkms/:idKkm/incomes',
  SEND_EXPENSES: '/kkms/:idKkm/expenses',
} as const;

export const PULT_API_ENDPOINTS = {
  GET_KKMS_EXPIRED_LIST: '/fiscal/main/:companyId/kkms/expired-list',
} as const;

export const WHATSAPP_API_URL = 'https://api.whatsapp.com';

export const FISCAL_FRONT_URL_PROD = 'https://fiscal.kassa24.kz';

export const FISCAL_FRONT_URL_DEV = 'https://cabinet.fiscalv3.tech24.kz';

export const FISCAL_FRONT_URL =
  NODE_ENV === APP_MODE.PRODUCTIOM ? FISCAL_FRONT_URL_PROD : FISCAL_FRONT_URL_DEV;
