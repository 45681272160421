export const PERIOD_TYPES = {
  DEFAULT: 'default',
  YESTERDAY: 'yesterday',
  THREE_DAYS: 'three_days',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  HALF_YEAR: 'half_year',
  YEAR: 'year',
  PERIOD: 'period',
};
