import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerIcon } from '../Spinner';

import useContainer from './hook';

import type { IButton } from '@/types/button';
import { ButtonKinds, ButtonType } from '@/types/button';
import { twMergeClsx } from '@/utils/tw-with-clsx';

const Button: FC<IButton> = ({
  children,
  disabled,
  className,
  onClick,
  type,
  kind = ButtonKinds.primary,
  text,
  Icon,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const { buttonClassNames } = useContainer({ kind, className, disabled });

  return (
    <button
      className={twMergeClsx(
        'text-base rounded-xl h-11 px-3 leading-5 w-auto flex font-medium items-center gap-1 m-auto justify-center',
        className,
        buttonClassNames,
      )}
      type={type === ButtonType.submit ? 'submit' : 'button'}
      disabled={disabled || isLoading}
      onClick={onClick}
      {...props}
    >
      {!isLoading && Icon}
      {children || t(text || '')}
      {isLoading && <SpinnerIcon className="w-5 h-5" />}
    </button>
  );
};

export { Button };
