import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { Stats } from './Stats';

import { useLazyGetKkmChartQuery } from '@/store/cashbox/service';
import type { ChartOperation, KkmChart } from '@/types/cashbox';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { LineChart } from '@/views/shared/Charts/LineChart';
import { PieChart } from '@/views/shared/Charts/PieChart';
import { Icon } from '@/views/shared/Icon';

type Props = {
  chart: KkmChart;
  operations: ChartOperation[];
  idKkm: number;
};

const Analytics = ({ chart, operations, idKkm }: Props) => {
  const { t } = useTranslation();
  const [refreshChart, { isLoading, isFetching }] = useLazyGetKkmChartQuery();

  const refresh = () => {
    refreshChart(idKkm).unwrap();
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="p-4 bg-white rounded-lg">
        <div>
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center gap-1">
              <p>{t('kassa.shift_stats')}</p>
              <i data-tooltip-id="info-stats" data-tooltip-place="bottom">
                <Icon type="info" />
              </i>
            </div>
            <div className="flex items-center gap-1">
              <button onClick={refresh} type="button">
                <Icon
                  type="reload"
                  className={twMergeClsx((isLoading || isFetching) && 'animate-left-spin')}
                />
              </button>
              <p>
                {t('kassa.updated_at')} {format(new Date(chart.Time), 'HH:mm')}
              </p>
            </div>
            <Tooltip id="info-stats" className="max-w-[232px] z-50" noArrow>
              <div className="text-xs text-lightGray-second">
                <p className="mb-2">{t('kassa.shift_stats_explanation')}</p>
                <p>{t('kassa.shift_stats_explanation_alt')}</p>
              </div>
            </Tooltip>
          </div>
          <Stats chart={chart} />
        </div>
        {operations.length ? (
          <LineChart operations={operations} />
        ) : (
          <p>{t('kassa.make_operation_to_see_top_sales')}</p>
        )}
      </div>
      <div className="flex gap-4 lg:flex-row flex-col">
        <div className="p-4 bg-white rounded-lg flex-grow">
          <p>{t('kassa.top_sales_of_shift')}</p>
          <div className="mt-6">
            <div className="grid grid-cols-3 text-xs text-darkGray-fourth">
              <p>{t('kassa.product_name')}</p>
              <p className="text-center">{t('kassa.quantity')}</p>
              <p className="text-right">{t('kassa.amount')}</p>
            </div>
            <div className="mt-2">
              {Array.isArray(chart.TopPositions) && chart.TopPositions.length ? (
                chart.TopPositions.map((item) => (
                  <div
                    className="grid grid-cols-3 h-[44px] items-center"
                    key={item.IdUnit + item.Name}
                  >
                    <p>{item.Name}</p>
                    <p className="text-center">{item.Qty}</p>
                    <p className="text-right">{Number(item.Sum).toFixed(2)}</p>
                  </div>
                ))
              ) : (
                <p>{t('kassa.make_operation_to_see_top_sales')}</p>
              )}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white rounded-lg">
          <div className="relative flex justify-center">
            <PieChart data={chart.ChartsData.PieChart} />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <p className="text-xs">{t('kassa.check_for_log')}</p>
              <p className="text-xl font-semibold text-center">
                {chart.ChartsData.PieChart.TotalReceipts}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            <div className="flex justify-between items-center text-sm leading-4">
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-blue w-2.5 h-2.5" />
                <p className="text-darkGray-third">{t('kassa.cash')}</p>
              </div>

              <p className="text-black font-semibold">{chart.ChartsData.PieChart.Cash} ₸</p>
            </div>
            <div className="flex justify-between items-center text-sm leading-4">
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-[#c2eeff] w-2.5 h-2.5" />
                <p className="text-darkGray-third">{t('kassa.non_cash')}</p>
              </div>

              <p className="text-black font-semibold">{chart.ChartsData.PieChart.NonCash} ₸</p>
            </div>
            <div className="flex justify-between items-center text-sm leading-4">
              <div className="flex items-center gap-2">
                <div className="rounded-full bg-[#c3c2ff] w-2.5 h-2.5" />
                <p className="text-darkGray-third">{t('kassa.transfer')}</p>
              </div>

              <p className="text-black font-semibold">{chart.ChartsData.PieChart.Mobile} ₸</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Analytics };
