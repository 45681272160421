import { Controller } from 'react-hook-form';

import { Select } from '../Select';

import type { OptionType } from '@/types/form';

type Props<T> = {
  options: OptionType<T>[];
  name: string;
  label?: string;
  withIntl?: boolean;
  control: any;
};

const SelectWithControl = <T,>({ name, control, label, options, withIntl }: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select
          name={name}
          label={label}
          options={options}
          selectedOption={value}
          setSelectedOption={onChange}
          withIntl={withIntl}
        />
      )}
    />
  );
};

export { SelectWithControl };
