import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import type { ErrorType } from '@/types';
import { Button } from '@/views/shared/Button';
import Modal from '@/views/shared/Modal';

const ErrorModal = ({
  isOpen,
  error,
  onClose,
}: {
  isOpen: boolean;
  error: ErrorType;
  onClose: () => void;
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} title="" withCloseButton>
      <div className="">
        <div className="rounded-full p-3 bg-blue mb-2 w-14 h-14  m-auto">
          <ExclamationTriangleIcon className="w-8 text-white" />
        </div>
        <p className="text-center font-semibold text-base">{error.text}</p>
        {!!error?.btnName && (
          <Button className="mt-4" onClick={error?.btnOnClick} text={error.btnName} />
        )}
      </div>
    </Modal>
  );
};

export { ErrorModal };
