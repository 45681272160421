import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { ERROR_DEFAULT_STATE } from '@/constants/operations';
import type { ErrorType, PrecheckPosition, UpdateStorno } from '@/types/operations';
import type { OperationDetailsResponse } from '@/types/report';

type InitialState = {
  positions: PrecheckPosition[] | [];
  isSimpleMode: boolean;
  operationInfo: OperationDetailsResponse | null;
  errors: ErrorType;
  SN: string;
};

const initialState: InitialState = {
  positions: [],
  isSimpleMode: false,
  operationInfo: null,
  errors: ERROR_DEFAULT_STATE,
  SN: '',
};

const operationSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    addPosition: (state, { payload }: PayloadAction<PrecheckPosition>) => {
      state.positions = [payload, ...state.positions];
    },
    updatePosition: (state, { payload }: PayloadAction<PrecheckPosition>) => {
      state.positions[0] = payload;
    },
    changeStorno: (state, { payload }: PayloadAction<UpdateStorno>) => {
      const positionIdx = state.positions.findIndex((item) => item.id === payload.id);

      if (positionIdx >= 0) {
        state.positions[positionIdx].storno = !payload.storno;
      }
    },
    clearPositions: (state) => {
      state.positions = [];
    },
    setIsSimpleMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isSimpleMode = payload;
    },
    setOperationInfo: (state, { payload }: PayloadAction<OperationDetailsResponse | null>) => {
      state.operationInfo = payload;
    },
    setOperationErrors: (state, { payload }: PayloadAction<ErrorType>) => {
      state.errors = payload;
    },
    setSN: (state, { payload }: PayloadAction<string>) => {
      state.SN = payload;
    },
  },
});

export default operationSlice.reducer;

export const {
  addPosition,
  updatePosition,
  clearPositions,
  changeStorno,
  setIsSimpleMode,
  setOperationInfo,
  setOperationErrors,
  setSN,
} = operationSlice.actions;
