import type { ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { SORT_TYPES } from '@/constants/common';
import { useAppSelector } from '@/hooks';

export type Items = { [key: string]: string | number | string[] | ReactElement | ReactElement[] };

export type HeadItems = {
  label: string;
  sortProp?: string;
  sortType?: (typeof SORT_TYPES)[keyof typeof SORT_TYPES];
  code: string;
};

export type Props = {
  heads: HeadItems[];
  rows: Items[];
  pageCount?: number;
  classCellName?: string;
  className?: string;
  tableClassName?: string;
  contentBeforeHead?: ReactNode;
  classHeadCellName?: string;
};

const useContainer = ({ pageCount = 0, rows }: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortProperty, setSortProperty] = useState<string>('');
  const [sortAscending, setSortAscending] = useState(true);
  const [currentRowList, setCurrentRowList] = useState<Items[]>(rows);
  const totalPages = Math.ceil(rows.length / pageCount);
  const { isPrint } = useAppSelector((state) => state.app);

  useEffect(() => {
    setCurrentRowList(rows);
  }, [rows]);

  const sortRows = useCallback(
    (head: HeadItems) => {
      let sortedOperations: Items[] = [];

      if (head?.sortProp) {
        if (head.sortType === SORT_TYPES.DATE) {
          sortedOperations = rows.sort((a: any, b: any) =>
            sortAscending
              ? new Date(a[head.sortProp!]).getTime() - new Date(b[head.sortProp!]).getTime()
              : new Date(b[head.sortProp!]).getTime() - new Date(a[head.sortProp!]).getTime(),
          );
        }

        if (head.sortType === SORT_TYPES.NUMBER) {
          sortedOperations = rows.sort((a: any, b: any) =>
            sortAscending
              ? a[head.sortProp!] - b[head.sortProp!]
              : b[head.sortProp!] - a[head.sortProp!],
          );
        }

        if (head.sortType === SORT_TYPES.STRING) {
          sortedOperations = rows.sort((a: any, b: any) =>
            sortAscending
              ? a[head.sortProp!].localeCompare(b[head.sortProp!])
              : b[head.sortProp!].localeCompare(a[head.sortProp!]),
          );
        }

        setCurrentRowList(sortedOperations);
        setSortProperty(head.sortProp);
        setSortAscending(!sortAscending);
      }
    },
    [rows, sortAscending],
  );

  return {
    totalPages,
    currentPage,
    sortAscending,
    sortProperty,
    currentRowList,
    isPrint,
    setCurrentPage,
    sortRows,
  };
};

export default useContainer;
