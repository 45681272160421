import { Field, Label, Radio } from '@headlessui/react';

type Props = {
  label?: string;
  value?: string | number;
};

const RadioButton = ({ label, value }: Props) => {
  return (
    <Field className="flex items-center gap-2 mb-2 last:mb-0">
      <Radio
        value={value}
        className="group flex size-5 items-center justify-center rounded-full border-lightGray-fourth border-2 bg-lightGray-fourth data-[checked]:bg-white data-[checked]:border-blue transition-all outline-none"
      >
        <span className="invisible size-2 rounded-full bg-blue group-data-[checked]:visible" />
      </Radio>
      {label && <Label>{label}</Label>}
    </Field>
  );
};

export { RadioButton };
