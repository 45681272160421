import { Capacitor } from '@capacitor/core';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

import { setUser } from './user/slice';

import { FISCAL_API_URL } from '@/constants/api';
import { getStorageValue } from '@/utils/capacitor-storage';
import { clearAuthData } from '@/utils/clear-auth-data';

const baseQuery = fetchBaseQuery({
  baseUrl: FISCAL_API_URL,
  prepareHeaders: async (headers) => {
    let token: string | null | undefined;

    if (Capacitor.isNativePlatform()) {
      token = (await getStorageValue('token')).value;
    } else {
      token = Cookies.get('token');
    }

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    clearAuthData(() => {
      api.dispatch(setUser(null));
    });
  }

  return result;
};

export const fiscalApi = createApi({
  reducerPath: 'fiscalApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Shift', 'User', 'Operations'],
  endpoints: () => ({}),
});

export const enhancedApi = fiscalApi.enhanceEndpoints({
  endpoints: () => ({}),
});
