import { ArrowLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { REPORT_TYPES } from '@/constants/reports';
import { ROUTES } from '@/constants/routes';
import { useAppSelector } from '@/hooks/useRedux';
import { ButtonKinds } from '@/types/button';
import { buildAppRouteUrl } from '@/utils/build-route-url';
import { Button } from '@/views/shared/Button';

const ReportHead = ({ step }: { step: number }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { shift } = useAppSelector((state) => state.shift);
  const [dateInfo, setDateInfo] = useState(true);

  const currentDate = new Date();
  const date = format(currentDate, 'dd.MM.yyyy');
  const hours = format(currentDate, 'HH:mm');

  useEffect(() => {
    if (step === REPORT_TYPES.OPERATIONS || step === REPORT_TYPES.Z_REPORT_ARCHIVE) {
      setDateInfo(false);
    } else {
      setDateInfo(true);
    }
  }, [step]);

  const goBack = () => {
    if (shift) {
      navigate(buildAppRouteUrl(ROUTES.SHIFT, { idKkm: shift.IdKkm }));
    } else {
      navigate(buildAppRouteUrl(ROUTES.MAIN));
    }
  };

  return (
    <div className="lg:grid lg:grid-cols-3 items-center justify-between mb-4">
      <div>
        <Button onClick={goBack} className="hidden lg:flex m-0" kind={ButtonKinds.secondaryIcon}>
          <ArrowLeftIcon className="w-4" />
          {t('report.back')}
        </Button>
      </div>
      <div className="flex justify-center items-center">
        <Button onClick={goBack} className="lg:hidden m-0 pl-0 pr-6" kind={ButtonKinds.iconOnly}>
          <ChevronLeftIcon className="w-4" />
        </Button>
        <h1 className="text-2xl font-semibold text-center flex-grow lg:ml-0">
          {t('report.reports')}
        </h1>
        <div className="w-10" />
      </div>
      <div className="flex justify-center lg:justify-end">
        <div className="bg-darkGray rounded-2xl px-3 py-1 text-white flex gap-1">
          {dateInfo && (
            <>
              <span>{date}</span>|<span>{hours}</span>
            </>
          )}
          {!dateInfo && (
            <span>
              {t('report.last_shift')} {shift?.ShiftIndex}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export { ReportHead };
