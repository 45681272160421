import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Footer } from './Footer';
import { Header } from './Header';
import { HeaderWithoutShift } from './HeaderWithoutShift';
import { MobileFooterNav } from './MobileFooterNav';
import { MobileHeader } from './MobileHeader';

import { MEDIA_POINTS } from '@/constants';
import { ROUTES } from '@/constants/routes';
import { useBalance, useReceiptData } from '@/hooks';
import { useAppSelector } from '@/hooks/useRedux';

const MainLayout = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const { openedShift } = useAppSelector((state) => state.shift);
  const params = useParams();
  const isMobile = useMedia(MEDIA_POINTS.LG);
  useBalance();
  useReceiptData();

  const idKkm = Number(params.idKkm);
  const idShift = Number(params.idShift);

  useEffect(() => {
    if (!user) {
      navigate(ROUTES.SIGNIN);
    }
  }, [navigate, user]);

  const renderHeader = () => {
    if (isMobile) {
      return <MobileHeader />;
    }

    if (!openedShift) {
      return <HeaderWithoutShift />;
    }

    if (idShift && idShift !== openedShift.Id) {
      return <HeaderWithoutShift />;
    }

    if (!idKkm || openedShift.IdKkm === idKkm) {
      return <Header />;
    }

    return <HeaderWithoutShift />;
  };

  return (
    <div className="flex flex-col justify-between items-stretch min-h-[calc(100vh-_var(--safe-area-inset-top))] relative bg-lightGray">
      {renderHeader()}
      <main className="flex-grow flex flex-col md:flex-row z-10">
        <div className="max-w-6xl mx-auto my-5 w-full px-3 lg:px-3">
          <Outlet />
        </div>
      </main>
      <Footer />
      {isMobile && <MobileFooterNav />}
    </div>
  );
};

export { MainLayout };
