import { OptionType } from '@/types';

export const KKM_STATUSES = {
  REGISTER_IN_PROGRESS: 1, // в процессе регистрации
  ACTIVE: 2, // активный
  BLOCKED_BY_OFD: 3, // заблокирован в офд
  OTHER: 5,
} as const;

export const BALANCE_TYPES = {
  CASH: 1,
  NON_CASH: 2,
};

export const TOTAL_KKMS_COUNT = 7;

export const TOTAL_KKMS_COUNT_FILTER = 1000;

export const DEFAULT_PAGE = 1;

export const LABEL_TYPES = {
  DANGER: 'danger',
  SUCCESS: 'success',
  PRIMARY: 'primary',
  AVAILABLE: 'available',
  SECONDARY: 'secondary',
  WARNING: 'warning',
} as const;

export const SORT_TYPES = {
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
} as const;

export const KKM_STATUSES_FILTER = {
  ALL: null,
  ACTIVE: [KKM_STATUSES.ACTIVE],
  NO_ACTIVE: [KKM_STATUSES.REGISTER_IN_PROGRESS, KKM_STATUSES.BLOCKED_BY_OFD, KKM_STATUSES.OTHER],
};

export const KKM_STATUSES_OPTIONS: OptionType<number[]>[] = [
  { label: 'kassa.all_status', id: 1, value: null },
  {
    label: 'kassa.active_status',
    id: 2,
    value: KKM_STATUSES_FILTER.ACTIVE,
  },
  {
    label: 'kassa.not_active_status',
    id: 3,
    value: KKM_STATUSES_FILTER.NO_ACTIVE,
  },
];

export const KKM_STATUS_DEFAULT = KKM_STATUSES_OPTIONS[1];
