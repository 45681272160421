import { Capacitor } from '@capacitor/core';
import { Dialog, DialogBackdrop, DialogPanel, RadioGroup } from '@headlessui/react';
import { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useContainer } from './hook';

import { DISCOUNT_TYPES, OperationType } from '@/constants/operations';
import { ButtonKinds } from '@/types/button';
import { openScanBarcode } from '@/utils/scan-barcode';
import { BarcodeScanner } from '@/views/shared/BarcodeScanner';
import { Button } from '@/views/shared/Button';
import { Combobox } from '@/views/shared/Combobox';
import { Divider } from '@/views/shared/Divider';
import { Form } from '@/views/shared/Form';
import { AmountInput } from '@/views/shared/Form/AmountInput';
import { Checkbox } from '@/views/shared/Form/Checkbox';
import { CountInput } from '@/views/shared/Form/CountInput';
import { Input } from '@/views/shared/Form/Input';
import { RadioButton } from '@/views/shared/Form/RadioButton';
import { Select } from '@/views/shared/Form/Select';
import { Icon } from '@/views/shared/Icon';

type Props = {
  operationType: OperationType;
  withSimpleMode?: boolean;
};

const AddPosition = ({ operationType, withSimpleMode }: Props) => {
  const {
    articlesOptions,
    discountTypeWatch,
    showScanner,
    isExciseWatch,
    isHasBarcode,
    form,
    isSimpleMode,
    domainsOptions,
    sectionsOptions,
    unitsOptions,
    isMobile,
    clearForm,
    onSubmit,
    setCode,
    toggleScanner,
    setShowScanner,
  } = useContainer({
    operationType,
  });
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, errors },
  } = form;

  const [isOpen, setIsOpen] = useState(false);

  const renderAddPositionByType = useCallback(() => {
    if (operationType === OperationType.SALE) {
      return (
        <>
          <Divider />
          <div className="mb-4">
            <h3 className="font-semibold mb-3">{t('discount_type.label')}</h3>
            <Controller
              control={control}
              name="discountType"
              render={({ field: { onChange, value } }) => (
                <RadioGroup name="isExcise" value={value} onChange={onChange}>
                  <RadioButton label={t('discount_type.percent')} value={DISCOUNT_TYPES.PERCENT} />
                  <RadioButton label={t('discount_type.fixed')} value={DISCOUNT_TYPES.FIXED} />
                </RadioGroup>
              )}
            />
          </div>
          <div className="flex flex-1 w-full gap-2">
            <AmountInput
              name="markup"
              control={control}
              aria-invalid={!!errors.markup}
              label={t('receipt.markup_with_currency', {
                currency: discountTypeWatch === DISCOUNT_TYPES.FIXED ? '₸' : '%',
              })}
              placeholder="0.00"
              max={discountTypeWatch === DISCOUNT_TYPES.FIXED ? undefined : 100}
            />
            <AmountInput
              name="discount"
              control={control}
              aria-invalid={!!errors.discount}
              label={t('receipt.discount_with_currency', {
                currency: discountTypeWatch === DISCOUNT_TYPES.FIXED ? '₸' : '%',
              })}
              placeholder="0.00"
              max={discountTypeWatch === DISCOUNT_TYPES.FIXED ? undefined : 100}
            />
          </div>
        </>
      );
    }

    return null;
  }, [control, discountTypeWatch, errors.discount, errors.markup, operationType, t]);

  const openBarcodeScanner = useCallback(async () => {
    if (!isHasBarcode) {
      return;
    }

    try {
      if (Capacitor.isNativePlatform()) {
        const result = await openScanBarcode();

        if (result) {
          setCode(result);
        }
      } else {
        setShowScanner(true);
      }
    } catch (e) {
      console.log(e);
    }
  }, [isHasBarcode, setCode, setShowScanner]);

  const renderForm = useCallback(() => {
    return (
      <div className="bg-white rounded-lg outline-none w-full flex flex-col h-full">
        <div className="flex justify-between items-center p-4 border-b border-lightGray-five">
          <h3 className="font-semibold text-lg leading-5">{t('receipt.add_item')}</h3>
          <button
            onClick={() => reset()}
            className="text-darkRed font-semibold disabled:text-darkGray-five text-sm"
            type="button"
            disabled={!isDirty}
          >
            {t('receipt.clear_form')}
          </button>
        </div>
        <div className="p-4 flex-grow overflow-y-scroll md:overflow-visible">
          <Form onSubmit={handleSubmit(onSubmit)} form={form}>
            <>
              <div className="flex flex-col gap-2">
                <Controller
                  control={control}
                  name="article"
                  render={({ field: { onChange, value } }) => (
                    <Combobox
                      name="article"
                      label={t('receipt.item_name')}
                      options={articlesOptions}
                      selectedOption={value}
                      setSelectedOptions={(val) => {
                        onChange(val);
                        setValue('price', String(val?.value?.Price));
                      }}
                      setInputValue={(val) => {
                        setValue('name', val);
                      }}
                      onClickIcon={openBarcodeScanner}
                      iconType="barcode"
                    />
                  )}
                />
                <div className="flex flex-1 w-full gap-2">
                  <AmountInput
                    name="price"
                    control={control}
                    aria-invalid={!!errors.price}
                    label={t('receipt.price')}
                    placeholder="0.00"
                  />
                  <CountInput
                    name="count"
                    control={control}
                    aria-invalid={!!errors.count}
                    label={t('receipt.quantity')}
                    placeholder="1.00"
                    classNameInput="text-center"
                  />
                </div>
                <Controller
                  control={control}
                  name="unit"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="unit"
                      label={t('receipt.unit_of_measurement')}
                      options={unitsOptions}
                      selectedOption={value}
                      setSelectedOption={onChange}
                    />
                  )}
                />
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="isExcise"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        name="isExcise"
                        checked={value}
                        onChange={onChange}
                        label={t('receipt.excise_goods')}
                      />
                    )}
                  />
                  {isExciseWatch && (
                    <div className="mt-2">
                      <Input
                        type="text"
                        id="exciseCode"
                        errorText="receipt.excise_goods"
                        {...register('exciseCode')}
                        aria-invalid={!!errors.price}
                        placeholder={t('other.excise_code')}
                      />
                    </div>
                  )}
                </div>
                <Controller
                  control={control}
                  name="section"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="section"
                      label={t('receipt.section')}
                      options={sectionsOptions}
                      selectedOption={value}
                      setSelectedOption={onChange}
                      withIntl
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="domain"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="domain"
                      label={t('other.activity_type')}
                      options={domainsOptions}
                      selectedOption={value}
                      setSelectedOption={onChange}
                      withIntl
                    />
                  )}
                />
              </div>
              {renderAddPositionByType()}
              {isMobile ? (
                <div className="flex flex-col gap-3 mt-6">
                  <Button
                    onClick={() => setIsOpen(false)}
                    className="w-full"
                    kind={ButtonKinds.secondary}
                    Icon={<Icon type="plus" />}
                  >
                    {t('receipt.close')}
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    className="w-full"
                    kind={ButtonKinds.primary}
                    Icon={<Icon type="plus" />}
                  >
                    {t('other.add_to_precheck')}
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  className="w-full mt-6"
                  kind={ButtonKinds.secondary}
                  Icon={<Icon type="plus" />}
                >
                  {t('receipt.add_another')}
                </Button>
              )}
            </>
          </Form>
        </div>
      </div>
    );
  }, [
    articlesOptions,
    control,
    domainsOptions,
    errors.count,
    errors.price,
    form,
    handleSubmit,
    isDirty,
    isExciseWatch,
    isMobile,
    onSubmit,
    openBarcodeScanner,
    register,
    renderAddPositionByType,
    reset,
    sectionsOptions,
    setValue,
    t,
    unitsOptions,
  ]);

  const renderFormState = () => {
    if (withSimpleMode && isSimpleMode) {
      return (
        <div className="rounded-lg outline-none w-full">
          <div className="flex justify-between items-center p-4 border-b border-lightGray-five">
            <h3 className="font-semibold text-lg leading-5">{t('receipt.tota_amount')}</h3>
            <button
              onClick={clearForm}
              className="text-darkRed font-semibold disabled:text-darkGray-five text-sm"
              type="button"
              disabled={!isDirty}
            >
              {t('receipt.clear_form')}
            </button>
          </div>
          <div className="px-4 pt-4">
            <Form onSubmit={handleSubmit(onSubmit)} form={form}>
              <div className="flex flex-col gap-2">
                <Controller
                  control={control}
                  name="article"
                  render={({ field: { onChange, value } }) => (
                    <Combobox
                      name="article"
                      label={t('receipt.item_name')}
                      options={articlesOptions}
                      selectedOption={value}
                      setSelectedOptions={(val) => {
                        onChange(val);
                        setValue('price', String(val?.value?.Price));
                      }}
                      setInputValue={(val) => {
                        setValue('name', val);
                      }}
                      onClickIcon={isHasBarcode ? () => toggleScanner() : undefined}
                      iconType="barcode"
                    />
                  )}
                />
                <AmountInput
                  name="price"
                  control={control}
                  aria-invalid={!!errors.price}
                  label={t('receipt.price')}
                  placeholder="0.00"
                />
                <div className="mt-2">
                  <Controller
                    control={control}
                    name="isExcise"
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        name="isExcise"
                        checked={value}
                        onChange={onChange}
                        label={t('receipt.excise_goods')}
                      />
                    )}
                  />
                  {isExciseWatch && (
                    <div className="mt-2">
                      <Input
                        type="text"
                        id="exciseCode"
                        errorText="receipt.excise_goods"
                        {...register('exciseCode')}
                        aria-invalid={!!errors.price}
                        label="receipt.excise_goods"
                        placeholder={t('other.excise_code')}
                      />
                    </div>
                  )}
                </div>
                <Controller
                  control={control}
                  name="section"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="section"
                      label={t('receipt.section')}
                      options={sectionsOptions}
                      selectedOption={value}
                      setSelectedOption={onChange}
                      withIntl
                    />
                  )}
                />
              </div>
            </Form>
          </div>
        </div>
      );
    }

    if (isMobile) {
      return (
        <>
          <div className="bg-white rounded-lg outline-none p-4 w-full">
            <Button
              className="w-full"
              onClick={() => setIsOpen(true)}
              Icon={<Icon type="plus" className="fill-white" />}
            >
              {t('other.add_position')}
            </Button>
          </div>
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            transition
            className="fixed z-50 inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
          >
            <DialogBackdrop className="fixed inset-0 bg-black/30" />
            <div className="fixed inset-0 flex w-screen items-end justify-center">
              <DialogPanel className="max-w-lg bg-white rounded-t-lg max-h-[810px] h-full">
                {renderForm()}
              </DialogPanel>
            </div>
          </Dialog>
        </>
      );
    }

    return renderForm();
  };

  return (
    <>
      <BarcodeScanner setCode={setCode} setShowScanner={setShowScanner} showScanner={showScanner} />
      {renderFormState()}
    </>
  );
};

export { AddPosition };
