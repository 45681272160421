import { useWindowSize } from './useWindowSize';

import { MOBILE_SCREEN_WIDTH } from '@/constants/device';

const useIsMobile = () => {
  const { width } = useWindowSize();

  return {
    isMobile: !!(width && width < MOBILE_SCREEN_WIDTH),
  };
};

export { useIsMobile };
