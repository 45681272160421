import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import type { LegacyRef } from 'react';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { twMerge } from 'tailwind-merge';

import useContainer from './hook';

import ArrowDownSLine from '@/assets/icons/arrow-down-s-line.svg?react';
import ArrowRightSLine from '@/assets/icons/arrow-right-s-line.svg?react';
import ArrowUpSLine from '@/assets/icons/arrow-up-s-line.svg?react';
import { PERIOD_TYPES } from '@/constants/date';

type SelectOption = {
  label: string;
  value: string;
};

const PeriodFilter = () => {
  const {
    periodOptions,
    innerRef,
    dateRange,
    i18n,
    dateLabel,
    selectedOption,
    calendarIsOpen,
    handleChangeDateRange,
    onChangeOption,
  } = useContainer();

  return (
    <div className="relative">
      <Listbox value={selectedOption} onChange={onChangeOption}>
        {({ open }) => (
          <>
            <ListboxButton className="w-full lg:w-auto text-darkGray flex gap-1 items-center justify-between border border-lightGray-second rounded-lg overflow-hidden h-[44px] transition-shadow bg-white leading-10 px-3">
              {dateLabel.join(' - ')}
              {open ? <ArrowUpSLine /> : <ArrowDownSLine />}
            </ListboxButton>
            <ListboxOptions anchor="bottom" className="bg-white z-10 lg:w-[200px]">
              {periodOptions.map((option: SelectOption) => (
                <ListboxOption
                  key={option.value}
                  value={option.value}
                  className={twMerge(
                    selectedOption === option.value && 'bg-blue text-white',
                    'hover:bg-blue hover:text-white p-3 cursor-pointer flex items-center gap-2',
                  )}
                >
                  {option.label} {option.value === PERIOD_TYPES.PERIOD && <ArrowRightSLine />}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </>
        )}
      </Listbox>

      {calendarIsOpen && (
        <div
          className="bg-white p-4 absolute -left-1/2 rounded-lg shadow-gray mt-1 -ml-[80px]"
          ref={innerRef as LegacyRef<HTMLInputElement>}
        >
          <DateRange
            onChange={handleChangeDateRange}
            showSelectionPreview
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
            months={2}
            direction="horizontal"
            locale={locales[i18n.language][i18n.language]}
            preventSnapRefocus
            calendarFocus="backwards"
            shouldCloseOnSelect
          />
        </div>
      )}
    </div>
  );
};

export { PeriodFilter };
