import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props = {
  className?: string;
};

const Divider = ({ className }: Props) => {
  return <div className={twMergeClsx('w-full h-[1px] bg-lightGray-second my-6', className)} />;
};

export { Divider };
