import { ArrowLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { KKM_STATUSES } from '@/constants/common';
import { ROUTES } from '@/constants/routes';
import { useAppSelector } from '@/hooks/useRedux';
import { ButtonKinds } from '@/types/button';
import { buildAppRouteUrl } from '@/utils/build-route-url';
import { Button } from '@/views/shared/Button';
import { Icon } from '@/views/shared/Icon';

const Head = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { kkmId, kkmItem } = useAppSelector((state) => state.cashbox);

  const goBack = () => {
    navigate(buildAppRouteUrl(ROUTES.MAIN, { idKkm: kkmId }));
  };

  const renderStatus = useCallback(() => {
    switch (kkmItem?.IdStatusKkm) {
      case KKM_STATUSES.REGISTER_IN_PROGRESS:
        return {
          text: 'kassa.in_progress',
          iconType: <Icon type="shiftGreyStatus" />,
        };
      case KKM_STATUSES.ACTIVE:
        return {
          text: 'kassa.active',
          iconType: <Icon type="shiftGreenStatus" />,
        };
      case KKM_STATUSES.BLOCKED_BY_OFD:
        return {
          text: 'kassa.blocked',
          iconType: <Icon type="shiftRedStatus" />,
        };
      default:
        return null;
    }
  }, [kkmItem]);

  const status = renderStatus();

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="hidden lg:flex m-0">
        <Button onClick={goBack} kind={ButtonKinds.secondaryIcon}>
          <ArrowLeftIcon className="w-4" />
          {t('kassa.back_to_cash_register_selection')}
        </Button>
      </div>
      <div className="flex justify-center items-center lg:hidden">
        <Button onClick={goBack} className="lg:hidden m-0 px-0" kind={ButtonKinds.iconOnly}>
          <ChevronLeftIcon className="w-4" />
          <span className="text-xl md:text-2xl font-semibold text-left">{kkmItem?.Name}</span>
        </Button>
      </div>
      {status && (
        <div className="flex items-center gap-1 bg-white px-3 py-2.5 rounded-lg">
          {status.iconType}
          <p className="text-base font-medium">{t(status.text)}</p>
        </div>
      )}
    </div>
  );
};

export { Head };
