import type { Props } from './hook';
import useContainer from './hook';

import { ButtonKinds, ButtonType } from '@/types/button';
import { BarcodeScanner } from '@/views/shared/BarcodeScanner';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import { SelectWithControl } from '@/views/shared/Form/SelectWithControl';
import { SwitchCustom } from '@/views/shared/Form/Switch';
import { Icon } from '@/views/shared/Icon';
import Modal from '@/views/shared/Modal';

const ArticleModal = ({ isOpen, article, onClose, onSave }: Props) => {
  const {
    t,
    methods,
    showScanner,
    unitsOptions,
    sectionOptions,
    isLoading,
    handleCloseModal,
    updateArticle,
    setBarcode,
    setShowScanner,
    toggleScanner,
  } = useContainer({
    isOpen,
    article,
    onClose,
    onSave,
  });

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      withCloseButton
      title={t('kassa_settings.item_editor')}
    >
      {showScanner && (
        <BarcodeScanner
          setCode={setBarcode}
          setShowScanner={setShowScanner}
          showScanner={showScanner}
        />
      )}
      <Form form={methods} onSubmit={methods.handleSubmit(updateArticle)}>
        <div className="flex flex-col gap-3">
          <div className="flex items-end justify-between child:flex-grow">
            <Input
              type="text"
              name="Qr"
              register={methods.register('Qr')}
              placeholder={t('add_nom.enter_barcode')}
              label={t('add_nom.barcode')}
              aria-invalid={!!methods?.formState?.errors?.Qr}
              errorText={methods.formState.errors.Qr?.message}
              className="border-r-0 w-full rounded-tr-none rounded-br-none"
            />
            <Button
              onClick={toggleScanner}
              className="m-0 rounded-tl-none rounded-bl-none border border-lightGray-second"
              kind={ButtonKinds.secondary}
            >
              <Icon type="barcode" />
            </Button>
          </div>
          <Input
            type="text"
            name="Name"
            label={t('kassa_settings.product_name')}
            register={methods.register('Name')}
            aria-invalid={!!methods?.formState?.errors?.Name}
            errorText={methods.formState.errors.Name?.message}
            placeholder={t('add_nom.enter_name')}
          />
          <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 justify-between lg:items-center">
            <SelectWithControl
              name="Unit"
              control={methods.control}
              label={t('kassa_settings.unit_of_measurement')}
              options={unitsOptions}
            />
            <Input
              type="number"
              name="Price"
              label={t('kassa_settings.price_alt')}
              register={methods.register('Price')}
              aria-invalid={!!methods?.formState?.errors?.Price}
              errorText={methods.formState.errors.Price?.message}
              placeholder="00.00"
            />
          </div>
          <SelectWithControl
            name="Section"
            label={t('kassa_settings.section')}
            control={methods.control}
            options={sectionOptions}
          />
          <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 justify-between lg:items-center">
            <Input
              type="number"
              name="Discount"
              label={t('kassa_settings.discount_alt')}
              register={methods.register('Discount')}
              aria-invalid={!!methods?.formState?.errors?.Discount}
              errorText={methods.formState.errors.Discount?.message}
              placeholder="00.00"
            />
            <Input
              type="number"
              name="Markup"
              label={t('kassa_settings.markup_alt')}
              register={methods.register('Markup')}
              aria-invalid={!!methods?.formState?.errors?.Markup}
              errorText={methods.formState.errors.Markup?.message}
              placeholder="00.00"
            />
          </div>
          <div className="mt-4">
            <SwitchCustom
              label={t('add_nom.available_for_sale')}
              name="InSale"
              control={methods.control}
            />
            <p className="text-xs text-darkGray-fourth mt-2">
              {methods.watch().InSale
                ? t('kassa_settings.selection_list')
                : t('kassa_settings.not_displayed')}
            </p>
          </div>
          <Button
            text="kassa_settings.save"
            type={ButtonType.submit}
            className="mt-2 w-full"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </Modal>
  );
};

export { ArticleModal };
