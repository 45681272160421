import { DISCOUNT_TYPES } from '@/constants/operations';

export const formatAmount = (value: number, withCurrency?: boolean, currency?: string) => {
  const num = new Intl.NumberFormat('ru-RU', {
    minimumFractionDigits: 2,
  }).format(value);

  if (withCurrency) {
    return `${num} ${currency || '₸'}`;
  }

  return num;
};

export const getAmountWithZeroes = (value: number) => value.toFixed(2);

export const calculateDiscountOrMarkup = (
  positionAmount: number,
  valueOrPercent: number,
  DiscountType: (typeof DISCOUNT_TYPES)[keyof typeof DISCOUNT_TYPES],
) => {
  if (Number.isNaN(valueOrPercent) || !valueOrPercent || !positionAmount) {
    return 0;
  }

  if (DiscountType === DISCOUNT_TYPES.PERCENT) {
    // В задаче сказано: СуммаВсегоПоСтроке округляется вниз. Дробная часть от округления добавляется в СуммуСкидки
    // Пример: стоимость товара 30 тг. Скидка вышла 2.10 (7%). Итоговая сумма получается 27.90
    // Получается нужно вывести итого: 27 тг
    // а 0.90 я должна прибавить к скидке, итого у меня скидка будет 3.00, проще говоря, скидку можно округлить вверх
    return Math.ceil(Number((positionAmount * valueOrPercent) / 100));
  }

  return Number(valueOrPercent);
};
