import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SORT_TYPES } from '@/constants';
import { useIsMobile, useAppSelector } from '@/hooks';
import { getArraySum } from '@/utils';

const useContainer = () => {
  const { t } = useTranslation();
  const { zReportList } = useAppSelector((state) => state.reports);
  const { isMobile } = useIsMobile();

  const reportZTotalAmounts = useMemo(() => {
    const totalResult = {
      SalesAmount: getArraySum(zReportList, 'SalesAmount'),
      PurchasesAmount: getArraySum(zReportList, 'PurchasesAmount'),
      IncomesAmount: getArraySum(zReportList, 'IncomesAmount'),
      ExpensesAmount: getArraySum(zReportList, 'ExpensesAmount'),
      RefundsAmount: getArraySum(zReportList, 'RefundsAmount'),
      PurchaseRefundsAmount: getArraySum(zReportList, 'PurchaseRefundsAmount'),
    };

    return totalResult;
  }, [zReportList]);

  const tableHead = useMemo(
    () => [
      {
        label: t('report.shift_number'),
        code: 'ShiftIndex',
        sortProp: 'ShiftIndex',
        sortType: SORT_TYPES.NUMBER,
      },
      { label: t('report.open'), code: 'DateOpen' },
      { label: t('report.closed'), code: 'DateClose' },
      {
        label: t('report.received_alt'),
        code: 'BalanceOpen',
        sortProp: 'BalanceOpen',
        sortType: SORT_TYPES.NUMBER,
      },
      {
        label: t('report.handed_over_alt'),
        code: 'BalanceClose',
        sortProp: 'BalanceClose',
        sortType: SORT_TYPES.NUMBER,
      },
      {
        label: t('report.operation_count'),
        code: 'Count',
        sortProp: 'Count',
        sortType: SORT_TYPES.NUMBER,
      },
      {
        label: t('report.cashier'),
        code: 'CashierName',
        sortProp: 'User.Name',
        sortType: SORT_TYPES.STRING,
      },
      { label: '', code: 'Operations' },
    ],
    [t],
  );

  return {
    t,
    tableHead,
    reportZTotalAmounts,
    zReportList,
    isMobile,
  };
};

export default useContainer;
