import { Tab, TabGroup, TabList } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { DuplicateModal } from '../../../Reports/Operations/DuplicateModal';

import type { Props } from './hook';
import { useContainer } from './hook';

import { OperationType } from '@/constants/operations';
import { formatAmount } from '@/utils/format-amount';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { AmountInput } from '@/views/shared/Form/AmountInput';
import { MaskedInput } from '@/views/shared/Form/MaskInput';
import { Icon } from '@/views/shared/Icon';

const paymentMethodList = [
  { id: 0, title: 'receipt.cash_alt_2' },
  { id: 1, title: 'receipt.card_alt' },
];

const Pays = ({ operationType, withSimpleMode }: Props) => {
  const {
    form,
    isSimpleMode,
    isLoading,
    result,
    isValidForm,
    selectedOperationId,
    showReportOperationsModal,
    swap,
    onSubmit,
    handleCloseReportOperationsModal,
    handleTabClick,
  } = useContainer({
    operationType,
  });
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  const renderPaysByType = () => {
    if (operationType === OperationType.SALE) {
      return (
        <>
          <div className="flex items-end">
            <AmountInput
              aria-invalid={!!errors.cash}
              control={control}
              label={t('receipt.cash')}
              name="cash"
              placeholder="0.00"
              className="rounded-l-xl rounded-r-none"
            />
            <button
              onClick={swap}
              type="button"
              className="h-11 w-11 flex flex-shrink-0 items-center justify-center border-y border-lightGray-second"
            >
              <Icon type="swap" />
            </button>
            <AmountInput
              aria-invalid={!!errors.cash}
              control={control}
              label={t('receipt.card')}
              name="nonCash"
              placeholder="0.00"
              className="rounded-l-none rounded-r-xl"
            />
          </div>
          <MaskedInput
            mask=""
            placeholder="000 000 000 000"
            control={control}
            aria-invalid={!!errors.iin}
            format="### ### ### ###"
            label={t('receipt.customer_tax_id')}
            name="iin"
            tooltipContent={t('other.iin_bin_optional')}
            tooltipId="info-iin"
          />
        </>
      );
    }

    if (operationType === OperationType.PURCHASE) {
      return (
        <MaskedInput
          mask=""
          placeholder="000 000 000 000"
          control={control}
          aria-invalid={!!errors.iin}
          format="### ### ### ###"
          label={t('receipt.customer_tax_id')}
          name="iin"
          tooltipContent={t('other.iin_bin_optional')}
          tooltipId="info-iin"
        />
      );
    }

    if (operationType === OperationType.PURCHASE_REFUND || operationType === OperationType.REFUND) {
      return (
        <>
          <AmountInput
            aria-invalid={!!errors.cash}
            control={control}
            label={t('kassa.amount')}
            name="cash"
            placeholder="0.00"
          />
          <MaskedInput
            mask=""
            placeholder="000 000 000 000"
            control={control}
            aria-invalid={!!errors.iin}
            format="### ### ### ###"
            label={t('receipt.customer_tax_id')}
            name="iin"
            tooltipContent={t('other.iin_bin_optional')}
            tooltipId="info-iin"
          />
        </>
      );
    }

    return null;
  };

  const renderInfoAmountOrChange = () => {
    if (operationType === OperationType.SALE) {
      return (
        <div className="p-4 bg-lightGray-fourth/50 rounded-lg my-2">
          <dl className="flex items-center justify-between">
            <dt>{t('receipt.total_to_pay')}</dt>
            <dd className="text-xl font-semibold">{formatAmount(result.total, true)}</dd>
          </dl>
          <dl className="flex items-center justify-between">
            <dt>{t('receipt.change')}</dt>
            <dd className="text-xl font-semibold">{formatAmount(result.changes, true)}</dd>
          </dl>
        </div>
      );
    }

    if (
      operationType === OperationType.PURCHASE ||
      operationType === OperationType.PURCHASE_REFUND ||
      operationType === OperationType.REFUND
    ) {
      return (
        <div className="p-4 bg-lightGray-fourth/50 rounded-lg my-2">
          <dl className="flex items-center justify-between">
            <dt>{t('receipt.total_to_pay')}</dt>
            <dd className="text-xl font-semibold">{formatAmount(result.total, true)}</dd>
          </dl>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <DuplicateModal
        isOpen={showReportOperationsModal}
        reportId={selectedOperationId}
        onClose={handleCloseReportOperationsModal}
      />
      {withSimpleMode && isSimpleMode ? (
        <Form onSubmit={handleSubmit(onSubmit)} form={form}>
          <div className="flex flex-col gap-4 px-4 pb-4">
            <MaskedInput
              mask=""
              placeholder="000 000 000 000"
              control={control}
              aria-invalid={!!errors.iin}
              format="### ### ### ###"
              label={t('receipt.customer_tax_id')}
              name="iin"
              tooltipContent={t('other.iin_bin_optional')}
              tooltipId="info-iin"
            />
            <TabGroup defaultIndex={0} onChange={handleTabClick}>
              <TabList className="grid grid-cols-2 rounded-lg border border-lightGray-second text-lg overflow-hidden bg-white">
                {paymentMethodList.map((item) => (
                  <Tab as={Fragment} key={item.id}>
                    {({ selected }) => (
                      <button
                        className={twMerge(
                          'h-11 border-r border-lightGray-second last:border-none text-sm',
                          selected && 'bg-lightGray-third/30',
                        )}
                        type="button"
                      >
                        {t(item.title)}
                      </button>
                    )}
                  </Tab>
                ))}
              </TabList>
            </TabGroup>

            <Button
              disabled={isValidForm}
              isLoading={isLoading}
              className="w-full"
              onClick={handleSubmit(onSubmit)}
              text="receipt.issue_receipt"
            />
          </div>
        </Form>
      ) : (
        <div className="bg-white rounded-lg outline-none w-full lg:max-w-[360px]">
          <div className="flex justify-between items-center p-4 border-b border-lightGray-five">
            <h3 className="font-semibold text-lg leading-5">{t('receipt.payment')}</h3>
          </div>
          <div className="p-4">
            <Form onSubmit={handleSubmit(onSubmit)} form={form}>
              <div className="flex flex-col gap-2">
                {renderPaysByType()}

                {result.total > result.currentValue && (
                  <div className="px-4 py-2 bg-darkRed/10 text-darkRed rounded-lg mt-2">
                    {t('other.insufficient_funds_for_operation')}{' '}
                    <span className="font-semibold">
                      {formatAmount(Math.abs(result.currentValue - result.total), true)}
                    </span>
                  </div>
                )}
                {renderInfoAmountOrChange()}
                <Button
                  disabled={isValidForm}
                  isLoading={isLoading}
                  className="w-full mt-6"
                  onClick={handleSubmit(onSubmit)}
                  text="receipt.issue_receipt"
                />
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export { Pays };
