import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react/jsx-runtime';
import { Link } from 'react-router-dom';

import { FileItem } from './FileItem';
import type { Props } from './hook';
import useContainer from './hook';
import { UploadFile } from './UploadFile';

import { ARTICLE_EXAMPLE_IMPORT_FILE } from '@/constants/app';
import { IMPORT_TYPE } from '@/constants/import';
import { ButtonKinds } from '@/types/button';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { Button } from '@/views/shared/Button';
import Modal from '@/views/shared/Modal';

const ImportModal = ({ onClose, onSuccess }: Props) => {
  const { t, files, fileError, errorText, isLoading, deleteFile, onUpdateFile, checkUploadedFile } =
    useContainer({ onClose, onSuccess });

  return (
    <Modal open onClose={onClose} title={t('kassa_settings.import_items')} className="max-w-2xl">
      <Fragment>
        <div className={twMergeClsx('mb-4 px-2', fileError && 'fileUploadError')}>
          {files.length ? (
            <FileItem
              files={files}
              deleteFile={deleteFile}
              isLoading={isLoading}
              onCheck={checkUploadedFile}
              errorText={errorText}
            />
          ) : (
            <UploadFile onUpdate={onUpdateFile} errorText={errorText} files={files} />
          )}
        </div>
        {!files?.length && (
          <Fragment>
            <div className="flex items-center justify-between gap-3 flex-col lg:flex-row">
              <div className="flex gap-1 items-center ">
                <QuestionMarkCircleIcon className="w-4 text-darkGray flex-shrink" />
                {t('kassa_settings.download_template')}
              </div>
              <Link
                to={ARTICLE_EXAMPLE_IMPORT_FILE}
                className="rounded-xl p-2 border border-blue text-blue hover:bg-blue hover:text-white"
              >
                {t('kassa_settings.download_excel_template')}
              </Link>
            </div>
            <div className="flex gap-1 items-center mt-2 lg:mt-0">
              <QuestionMarkCircleIcon className="w-4 text-darkGray flex-shrink" />
              {t('kassa_settings.results')}
            </div>
          </Fragment>
        )}
        <div className="flex items-center justify-between gap-2 mt-4 flex-col lg:flex-row">
          <Button
            kind={ButtonKinds.danger}
            text={t('kassa_settings.cancel')}
            className="m-0 w-full lg:w-auto"
            onClick={onClose}
          />
          <Button
            text={t('kassa_settings.save')}
            className="m-0 w-full lg:w-auto"
            disabled={fileError && files.length > 0}
            onClick={() => checkUploadedFile(IMPORT_TYPE.SAVE)}
          />
        </div>
      </Fragment>
    </Modal>
  );
};

export { ImportModal };
