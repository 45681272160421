import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseShiftModal } from '../CloseShiftModal';

import { CASH_TITLE } from '@/constants/shift';
import { useNavigation } from '@/hooks/useNavigate';
import { useAppSelector } from '@/hooks/useRedux';
import { useShift } from '@/hooks/useShift';
import type { Balance } from '@/types/balance';
import { ButtonKinds } from '@/types/button';
import type { Kkm } from '@/types/cashbox';
import { renderAddress } from '@/utils/render-address';
import { Button } from '@/views/shared/Button';
import { Icon } from '@/views/shared/Icon';

type Props = {
  kkm: Kkm;
  balances: Balance[];
};

const Info = ({ kkm, balances }: Props) => {
  const { shiftStatus } = useAppSelector((state) => state.shift);
  const { t } = useTranslation();
  const { goToReport, goToSale, goToSettings } = useNavigation();
  const [isOpen, setIsOpen] = useState(false);
  const { toggleShift, loadingOpenShift } = useShift();

  const open = () => {
    toggleShift(kkm, true);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <CloseShiftModal isOpen={isOpen} onClose={toggleModal} />
      <div className="bg-white rounded-lg flex-grow overflow-hidden flex-shrink-0 lg:w-[368px] ml-auto">
        <div className="p-4 border-b border-darkGray/10">
          {shiftStatus.shiftOpen ? (
            <div className="bg-[#eaf9ee] inline-flex gap-1 px-3 py-2 rounded-lg text-sm leading-4 mb-3">
              <Icon className="w-4 h-4" type="user" />
              <p>
                {t('kassa.shift')} №{kkm?.ShiftIndex}
              </p>
            </div>
          ) : (
            <div className="bg-[#eaf9ee] inline-flex gap-1 px-3 py-2 rounded-lg text-sm leading-4 mb-3">
              <Icon type="user" />
              <p>{t('kassa.new_shift')}</p>
            </div>
          )}
          <div className="flex flex-col gap-2">
            {balances?.map((balance) => (
              <div key={balance.Id} className="flex justify-between items-center text-sm leading-4">
                <p className="text-darkGray-third">
                  {balance.TypeBalance.Name === CASH_TITLE
                    ? t('kassa.cash_resources')
                    : t('kassa.non_cash_resources')}
                </p>
                <p className="text-black font-semibold">
                  {new Intl.NumberFormat('ru-RU').format(balance.Amount)} ₸
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 border-b border-darkGray/10 flex flex-col gap-2">
          <div className="flex justify-between items-center text-sm leading-4">
            <p className="text-darkGray-third">Касса</p>
            <p className="text-black font-semibold">№{kkm?.Id}</p>
          </div>
          <div className="flex justify-between items-center text-sm leading-4">
            <p className="text-darkGray-third">ЗНМ</p>
            <p className="text-black font-semibold">{kkm?.Znm}</p>
          </div>
          <div className="flex justify-between items-center text-sm leading-4">
            <p className="text-darkGray-third">{t('kassa.company')}</p>
            <p className="text-black font-semibold">{kkm?.Company.FullName}</p>
          </div>
          {kkm?.Address && (
            <div className="flex justify-between items-center text-sm leading-4">
              <p className="text-darkGray-third">{t('kassa.address')}</p>
              <p className="text-black font-semibold">{renderAddress(kkm?.Address)}</p>
            </div>
          )}
        </div>
        <div className="p-4 flex flex-col gap-2">
          {!shiftStatus.shiftBlocked &&
            (shiftStatus.shiftOpen ? (
              !shiftStatus.shiftExpired && (
                <Button className="w-full" onClick={() => goToSale(kkm?.Id)}>
                  {t('kassa.start_issuing_receipts')}
                </Button>
              )
            ) : (
              <Button isLoading={loadingOpenShift} className="w-full" onClick={open}>
                {loadingOpenShift ? t('other.open_shift_proccess') : t('other.open_shift')}
              </Button>
            ))}
          <div className="flex flex-col gap-2">
            <div className="flex flex-1 gap-2">
              <Button
                kind={ButtonKinds.secondary}
                onClick={() => goToReport(kkm?.IdShift)}
                className="w-full whitespace-pre-line"
              >
                {t('kassa.operations_log')}
              </Button>
              <Button
                kind={ButtonKinds.secondary}
                onClick={() => goToReport(kkm?.IdShift)}
                className="w-full"
              >
                {t('kassa.all_reports')}
              </Button>
            </div>
            <Button
              className="w-full"
              kind={ButtonKinds.secondary}
              Icon={<Icon type="settings" className="fill-darkGray-second" />}
              text="kassa.settings"
              onClick={() => goToSettings(kkm?.Id)}
            />
            {shiftStatus.shiftOpen && (
              <Button
                className="w-full"
                kind={ButtonKinds.danger}
                onClick={toggleModal}
                text="kassa.close_shift"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Info };
