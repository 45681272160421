import { type ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Cashbox } from './Cashbox';
import { Companies } from './Companies';

import { KKM_STATUSES_OPTIONS } from '@/constants';
import { useAppSelector, useGetCompanies, useSearch } from '@/hooks';
import { setKkmSearchValue, setKkmStatus } from '@/store/cashbox/slice';
import { twMergeClsx } from '@/utils';
import { Select } from '@/views/shared/Form/Select';
import { SearchInput } from '@/views/shared/SearchInput';
import { SpinnerContainer } from '@/views/shared/SpinnerContainer';

const Main = () => {
  const { isLoadingKKms, isLoading, companyId, loadKkms, loadCompanies } = useGetCompanies();
  const { kkmStatus } = useAppSelector((state) => state.cashbox);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const { query, setQuery } = useSearch({
    queryCallback: (message) => dispatch(setKkmSearchValue(message)),
    emptyCallback: () => dispatch(setKkmSearchValue(undefined)),
  });

  useEffect(() => {
    loadCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    loadKkms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  if (isLoading) {
    return <SpinnerContainer text={t('main.loading')} />;
  }

  return (
    <>
      <div className="flex items-center lg:items-start justify-between mb-5 gap-2 lg:flex-nowrap flex-wrap">
        <div className="flex items-center w-full flex-grow lg:items-start justify-between">
          <h3
            className={twMergeClsx('text-xl lg:text-2xl font-semibold', isOpenSearch && 'hidden')}
          >
            {t('main.select_company_and_cash_register')}
          </h3>

          <SearchInput
            onChange={onHandleChange}
            setIsOpened={setIsOpenSearch}
            value={query}
            className="lg:max-w-[300px] w-full"
            placeholder={t('main.search_cash_register')}
          />
        </div>
        <Select
          name="status"
          selectedOption={kkmStatus}
          setSelectedOption={(value) => dispatch(setKkmStatus(value))}
          options={KKM_STATUSES_OPTIONS}
          containerClassName="lg:w-[140px] w-full flex-shrink-0"
          withIntl
        />
      </div>

      <div className="flex lg:flex-row flex-col items-start gap-4 lg:gap-6 flex-1">
        <Companies />
        {isLoadingKKms ? <SpinnerContainer text={t('main.cash_register_loading')} /> : <Cashbox />}
      </div>
    </>
  );
};

export { Main };
