import { useCallback, useEffect, useRef } from 'react';

function useOutsideClickEvent(actionOnClickOutside?: () => void) {
  const innerRef = useRef<Element | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (innerRef.current && !innerRef.current.contains(event.target as Node)) {
        actionOnClickOutside?.();
      }
    },
    [actionOnClickOutside],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return {
    innerRef,
    handleClickOutside,
  };
}

export { useOutsideClickEvent };
