import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddPosition } from '../modules/AddPosition';
import { ErrorModal } from '../modules/ErrorModal';
import { OperationHead } from '../modules/Head';
import { Pays } from '../modules/Pays';
import { PreCheck } from '../PreCheck';

import { ERROR_DEFAULT_STATE, OperationType } from '@/constants/operations';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setOperationErrors } from '@/store/operations/slice';

const Purchase = () => {
  const { errors } = useAppSelector((state) => state.operations);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCloseErrorModal = useCallback(() => {
    dispatch(setOperationErrors(ERROR_DEFAULT_STATE));
  }, [dispatch]);

  return (
    <div>
      <OperationHead title={t('other.purchase_of_goods')} />
      <ErrorModal onClose={handleCloseErrorModal} isOpen={!!errors.text} error={errors} />
      <div className="flex flex-1 lg:flex-row flex-col items-start md:gap-6 gap-3">
        <AddPosition operationType={OperationType.PURCHASE} />
        <PreCheck />
        <Pays operationType={OperationType.PURCHASE} />
      </div>
    </div>
  );
};

export { Purchase };
