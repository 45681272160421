import { useTranslation } from 'react-i18next';

import { DuplicateModal } from '../../../Reports/Operations/DuplicateModal';

import { useContainer } from './hook';

import { OperationType } from '@/constants/operations';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { AmountInput } from '@/views/shared/Form/AmountInput';

const SimplePays = ({ operationType }: { operationType: OperationType }) => {
  const {
    form,
    isLoading,
    selectedOperationId,
    showReportOperationsModal,
    handleCloseReportOperationsModal,
    onSubmit,
  } = useContainer({
    operationType,
  });
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = form;

  return (
    <>
      <DuplicateModal
        isOpen={showReportOperationsModal}
        reportId={selectedOperationId}
        onClose={handleCloseReportOperationsModal}
      />
      <div className="bg-white rounded-lg outline-none w-full lg:max-w-[360px]">
        <div className="p-4">
          <Form onSubmit={handleSubmit(onSubmit)} form={form}>
            <div className="flex flex-col gap-4">
              <AmountInput
                aria-invalid={!!errors.amount}
                control={control}
                label={
                  operationType === OperationType.EXPENSE
                    ? t('receipt.expense_amount')
                    : t('receipt.income_amount')
                }
                name="amount"
                placeholder="0.00"
              />
              <Button
                disabled={!isValid}
                isLoading={isLoading}
                className="w-full"
                onClick={handleSubmit(onSubmit)}
                text="receipt.issue_receipt"
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export { SimplePays };
