import { useTranslation } from 'react-i18next';

import { OperationHead } from '../modules/Head';
import { SimplePays } from '../modules/SimplePays';

import { OperationType } from '@/constants/operations';

const Expense = () => {
  const { t } = useTranslation();

  return (
    <div>
      <OperationHead title={t('close_shift.service_expense')} />
      <div className="flex flex-1 lg:flex-row flex-col justify-center items-start md:gap-6 gap-3">
        <SimplePays operationType={OperationType.EXPENSE} />
      </div>
    </div>
  );
};

export { Expense };
