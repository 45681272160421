import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { ApiResponse } from '@/types/queries';
import type { ZReportData } from '@/types/report';
import type { Shift } from '@/types/shift';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const shiftService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getShift: build.query<ApiResponse<Shift>, number>({
      query: (idShift) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SHIFT, { idShift }),
      providesTags: ['Shift'],
    }),
    openShift: build.mutation<ApiResponse<Shift>, { idKkm: number }>({
      query: (body) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.OPEN_SHIFT),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Shift', 'User'],
    }),
    closeShift: build.mutation<
      ApiResponse<Shift & ZReportData>,
      { idShift: number; idKkm: number; uid: string }
    >({
      query: ({ idKkm, idShift, uid }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.CLOSE_SHIFT, { idShift }),
        method: 'POST',
        body: {
          idKkm,
        },
        headers: {
          uid,
        },
      }),
      invalidatesTags: ['Shift', 'User'],
    }),
  }),
});

export const {
  useGetShiftQuery,
  useLazyGetShiftQuery,
  useOpenShiftMutation,
  useCloseShiftMutation,
} = shiftService;
