import { type ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useMedia } from 'react-use';

import { Icon } from '../Icon';

import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props = {
  value: string | number | readonly string[];
  className?: string;
  placeholder?: string;
  withoutHide?: boolean;
  onSearch?: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  setIsOpened?: (value: boolean) => void;
};

const SearchInput = ({
  value,
  className,
  placeholder,
  withoutHide,
  onChange,
  onSearch,
  setIsOpened,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const isMobile = useMedia('(max-width: 1024px)');

  const onFocus = () => {
    setFocus(true);

    if (isMobile && setIsOpened) {
      setIsOpened(true);
    }

    if (onSearch) {
      onSearch();
    }
  };

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus]);

  const onBlur = () => {
    if (isMobile && setIsOpened) {
      setIsOpened(false);
    }

    setFocus(false);
  };

  return isMobile && !withoutHide ? (
    <div
      className={twMergeClsx(
        'rounded-lg overflow-hidden flex items-stretch h-[44px] transition-shadow',
        focus && 'ring-1 ring-blue border border-lightGray-second',
        focus && className,
      )}
    >
      {focus && (
        <input
          ref={ref}
          onFocus={!onSearch ? onFocus : () => {}}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          className="flex-grow outline-none px-4"
        />
      )}
      <button
        onClick={onFocus}
        type="button"
        className={twMergeClsx('w-[44px]', focus && 'border-l border-lightGray-second')}
      >
        <Icon type="search" className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
      </button>
    </div>
  ) : (
    <div
      className={twMergeClsx(
        'border border-lightGray-second rounded-lg overflow-hidden flex items-stretch h-[44px] transition-shadow',
        focus && 'ring-1 ring-blue',
        className,
      )}
    >
      <input
        ref={ref}
        onFocus={!onSearch ? onFocus : () => {}}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className="flex-grow outline-none px-4"
      />
      <button onClick={onFocus} type="button" className="border-l border-lightGray-second w-[44px]">
        <Icon type="search" className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
      </button>
    </div>
  );
};

export { SearchInput };
