import { type MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';

import { LABEL_TYPES, ROUTES } from '@/constants';
import type { Kkm } from '@/types/cashbox';
import type { LabelType } from '@/types/common';
import { buildAppRouteUrl, renderAddress, twMergeClsx } from '@/utils';
import { Button } from '@/views/shared/Button';
import { Icon } from '@/views/shared/Icon';

type Props = {
  item: Kkm;
  znmValue: number | null;
  copyZnm: (e: MouseEvent<HTMLDivElement>, value: number) => void | undefined;
};

const CashboxItem = ({ item, znmValue, copyZnm }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMedia('(max-width: 1024px)');

  useEffect(() => {
    if (item.LabelType === LABEL_TYPES.SUCCESS) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [item?.LabelType, znmValue]);

  const isSuccesLabel = useMemo(() => item.LabelType === LABEL_TYPES.SUCCESS, [item]);
  const setKkmAndGoShiftPage = useCallback(
    (kkmId: number) => {
      navigate(buildAppRouteUrl(ROUTES.SHIFT, { idKkm: kkmId }));
    },
    [navigate],
  );

  const renderLabelIcon = (labelType?: LabelType) => {
    switch (labelType) {
      case LABEL_TYPES.SUCCESS:
        return <Icon type="openShift" className="fill-white" />;
      case LABEL_TYPES.WARNING:
        return <Icon type="warning" className="fill-orange" />;
      case LABEL_TYPES.PRIMARY:
        return <Icon type="success" className="fill-green" />;
      case LABEL_TYPES.AVAILABLE:
        return <Icon type="success" className="fill-green" />;
      default:
        return <Icon type="danger" className="fill-red" />;
    }
  };

  return isMobile ? (
    <div ref={ref}>
      <Button
        onClick={() => setKkmAndGoShiftPage(item.Id)}
        className={twMergeClsx(
          'bg-white text-black px-0 flex rounded-lg flex-col w-full min-h-[68px] mb-2 justify-between transition-colors font-normal text-sm h-auto items-stretch hover:bg-transparent',
          isSuccesLabel && 'bg-green text-white',
        )}
      >
        <div className="px-4 py-2.5 border-b border-lightGray-fourth">
          <div className="flex justify-between items-center mb-2">
            <div
              className={twMergeClsx(
                'text-base whitespace-nowrap text-black font-semibold',
                isSuccesLabel && 'text-white',
              )}
            >
              {item.Name}
            </div>
            {znmValue === item.Znm ? (
              <div className="w-[103px] rounded flex items-center gap-1 bg-blue text-xs overflow-hidden text-darkGray-second px-1.5 py-1">
                <Icon type="copied" className="w-3 h-3 fill-white" />
                <div className="text-white">{t('main.copied')}</div>
              </div>
            ) : (
              <div
                role="button"
                onClick={(e) => copyZnm(e, item.Znm)}
                className="w-[103px] rounded flex items-center bg-lightGray text-xs overflow-hidden text-darkGray-second"
              >
                <div className="px-1.5 py-1 bg-lightGray-fourth">{t('main.znm')}</div>
                <div className="px-1.5 py-1 font-semibold">{item.Znm}</div>
              </div>
            )}
          </div>
          <div className="text-left">{renderAddress(item.Address)}</div>
        </div>
        <div
          className={twMergeClsx(
            'flex items-center justify-between text-darkGray px-4 py-2.5',
            isSuccesLabel && 'text-white',
          )}
        >
          <div className="flex items-center gap-1">
            <i>{renderLabelIcon(item.LabelType)}</i>
            <span>{item.LabelText ? t(item.LabelText) : ''}</span>
          </div>
          <div>
            {item.ExpiredDate
              ? t(item.ExpiredDate.label, {
                  days: item.ExpiredDate.days,
                  date: item.ExpiredDate.date,
                })
              : t('main.kkm_expired_need_pay')}
          </div>
        </div>
      </Button>
    </div>
  ) : (
    <Button
      onClick={() => setKkmAndGoShiftPage(item.Id)}
      className={twMergeClsx(
        'bg-white text-black px-4 py-2.5 flex flex-col w-full min-h-[68px] gap-2 justify-between transition-colors font-normal text-sm rounded-none m-0 h-auto items-stretch hover:bg-transparent',
        isSuccesLabel && 'bg-green text-white',
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {znmValue === item.Znm ? (
            <div className="w-[103px] rounded flex items-center gap-1 bg-blue text-xs overflow-hidden text-darkGray-second px-1.5 py-1">
              <Icon type="copied" className="w-3 h-3 fill-white" />
              <div className="text-white">{t('main.copied')}</div>
            </div>
          ) : (
            <div
              role="button"
              onClick={(e) => copyZnm(e, item.Znm)}
              className="w-[103px] rounded flex items-center bg-lightGray text-xs overflow-hidden text-darkGray-second"
            >
              <div className="px-1.5 py-1 bg-lightGray-fourth">{t('main.znm')}</div>
              <div className="px-1.5 py-1 font-semibold">{item.Znm}</div>
            </div>
          )}
          <div
            className={twMergeClsx(
              'text-base whitespace-nowrap text-black font-semibold',
              isSuccesLabel && 'text-white',
            )}
          >
            {item.Name}
          </div>
        </div>
        <div className="flex items-center gap-1">
          <i>{renderLabelIcon(item.LabelType)}</i>
          <span>{item.LabelText ? t(item.LabelText) : ''}</span>
        </div>
      </div>
      <div
        className={twMergeClsx(
          'flex items-center justify-between text-darkGray',
          isSuccesLabel && 'text-white',
        )}
      >
        <div>{renderAddress(item.Address)}</div>
        <div>
          {item.ExpiredDate
            ? t(item.ExpiredDate.label, {
                days: item.ExpiredDate.days,
                date: item.ExpiredDate.date,
              })
            : t('main.kkm_expired_need_pay')}
        </div>
      </div>
    </Button>
  );
};

export { CashboxItem };
