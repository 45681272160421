import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import { toJpeg } from 'html-to-image';
import jsPDF from 'jspdf';
import type { RefObject } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { Icon } from '../Icon';

import { shareFile } from '@/utils/shareFile';

export type Props = {
  fileName: string;
  pdfCurrentStyle?: string;
  contentRef: RefObject<HTMLDivElement>;
  onBeforePrint?: () => Promise<void>;
};
const MobileAppDownloadFile = ({ fileName, pdfCurrentStyle, contentRef, onBeforePrint }: Props) => {
  const { t } = useTranslation();

  // check in mobile app
  const printFile = useCallback(async () => {
    if (!contentRef?.current) {
      console.error('Элемент не найден');

      return;
    }

    try {
      const element = contentRef.current;
      const clonedElement = element.cloneNode(true) as HTMLElement;

      if (pdfCurrentStyle) clonedElement.classList.add(pdfCurrentStyle);

      document.body.appendChild(clonedElement);
      clonedElement.style.position = 'fixed';
      clonedElement.style.top = '-1px';
      clonedElement.style.zIndex = '-10';
      clonedElement.id = 'cloned-report';
      const hideElems = clonedElement.querySelectorAll('.hideForPdf');

      hideElems.forEach((elem) => {
        elem.remove();
      });

      const img = await toJpeg(clonedElement, {
        backgroundColor: '#ffffff',
        height: clonedElement.offsetHeight + 100,
      });

      // eslint-disable-next-line
      const pdf = new jsPDF('p', 'mm', [297, clonedElement?.offsetHeight]);

      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(img, 'JPEG', 0, 0, pdfWidth, pdfHeight);

      const pdfBytes = pdf.output('arraybuffer');

      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

      const timestamp = new Date().getTime();
      const outputFileName = `${t(fileName)}-${timestamp}.pdf`;

      if (Capacitor.isNativePlatform()) {
        await write_blob({
          path: outputFileName,
          directory: Directory.Documents,
          blob: pdfBlob,
        });

        const fileUri = await Filesystem.getUri({
          directory: Directory.Documents,
          path: outputFileName,
        });

        await shareFile(fileUri.uri, outputFileName);
      } else {
        pdf.save(outputFileName);
      }
    } catch (error) {
      console.error('Ошибка при создании PDF:', error);
    } finally {
      const cloned = document.getElementById('cloned-report');

      if (cloned) {
        cloned.remove();
      }
    }
  }, [contentRef, fileName, pdfCurrentStyle, t]);

  const onHandleClick = useCallback(async () => {
    if (onBeforePrint) {
      await onBeforePrint();
    }

    printFile();
  }, [onBeforePrint, printFile]);

  return (
    <Button
      className="w-full lg:w-auto m-0"
      onClick={onHandleClick}
      text="report.export_to_pdf"
      Icon={<Icon type="printLine" />}
    />
  );
};

export { MobileAppDownloadFile };
