import { Button } from '../../Button';
import { Input } from '../Input';
import { MaskedInput } from '../MaskInput';

import { ButtonType } from '@/types/button';

type Props = {
  errors: any;
  isLoading: boolean;
  btnText: string;
  register?: any;
  type?: 'number' | 'text' | 'email' | 'password';
  name: string;
  mask?: string;
  control?: any;
  label: string;
  format?: string;
  placeholder: string;
  errorText?: string;
};

const InputWithButton = ({
  name,
  mask = '',
  control = {},
  label,
  format,
  placeholder,
  errorText = '',
  errors,
  isLoading,
  btnText,
  register,
  type = 'text',
}: Props) => {
  return (
    <div className="relative flex items-end justify-between child:flex-grow">
      {format ? (
        <MaskedInput
          format={format || ''}
          mask={mask}
          control={control}
          name={name}
          placeholder={placeholder}
          label={label}
          errorText={errorText}
          aria-invalid={!!(errors[name] || errorText)}
          className="rounded-tr-none rounded-br-none"
        />
      ) : (
        <Input
          register={register(name)}
          name={name}
          placeholder={placeholder}
          label={label}
          errorText={errorText}
          aria-invalid={!!(errors[name] || errorText)}
          type={type}
          className="rounded-tr-none rounded-br-none"
        />
      )}

      <Button
        className="w-30 rounded-tl-none rounded-bl-none m-0"
        disabled={isLoading}
        text={btnText}
        isLoading={isLoading}
        type={ButtonType.submit}
      />
    </div>
  );
};

export { InputWithButton };
