import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { KassaInfo } from '../KassaInfo';
import { LangChecker } from '../LangChecker';
import { UserInfo } from '../UserInfo';

import Logo from '@/assets/images/logo.svg?react';
import { MAIN_SALE_MENU, ROUTES } from '@/constants';
import { DropdownMenu } from '@/views/shared/DropdownMenu';

const Header = () => {
  const { t } = useTranslation();
  const path = useLocation();

  return (
    <div className="bg-darkGray-second w-full top-0 left-0 py-4 text-white z-20 sticky hidden lg:block">
      <div className="max-w-6xl m-auto flex items-center justify-between gap-2">
        {path?.pathname === ROUTES.MAIN ? (
          <Logo />
        ) : (
          <Link to={ROUTES.MAIN}>
            <Logo />
          </Link>
        )}

        <KassaInfo />
        <LangChecker />
        <div>
          <DropdownMenu buttonName={t('main.new_sale')} menu={MAIN_SALE_MENU} />
        </div>
        <UserInfo />
      </div>
    </div>
  );
};

export { Header };
