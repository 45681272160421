import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { OPERATION_TYPES } from '@/constants/app';
import { ERROR_DEFAULT_STATE } from '@/constants/operations';
import useOperations from '@/hooks/useOperations';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setOperationErrors } from '@/store/operations/slice';

const useContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getOperationDetails } = useOperations();

  const { errors } = useAppSelector((state) => state.operations);

  useEffect(() => {
    if (id) {
      getOperationDetails(id, OPERATION_TYPES.PURCHASE.ID);
    }
  }, [dispatch, getOperationDetails, id, t]);

  const handleCloseErrorModal = useCallback(() => {
    dispatch(setOperationErrors(ERROR_DEFAULT_STATE));
  }, [dispatch]);

  return { id, errors, handleCloseErrorModal };
};

export default useContainer;
