import { useTranslation } from 'react-i18next';

import { useAppSelector } from '@/hooks/useRedux';
import { formatAmount } from '@/utils/format-amount';
import { DefinitionItem } from '@/views/shared/Definition/DefinitionItem';
import { DefinitionList } from '@/views/shared/Definition/DefinitionList';
import { DefinitionSection } from '@/views/shared/Definition/DefinitionSection';
import { DisclosureCustom } from '@/views/shared/Disclosure';

const MainInfo = () => {
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);
  const { user } = useAppSelector((state) => state.user);

  return (
    <DisclosureCustom
      btnName={t('report.main_information')}
      btnClassName="bg-white px-3 py-2 border-b border-b-lightGray-second lg:hidden print:hidden"
    >
      <DefinitionSection title={t('report.main_information')}>
        <DefinitionList>
          <DefinitionItem
            title={t('report.organization_name')}
            value={selectedZReportData?.Kkm.Company.FullName || ''}
          />
          <DefinitionItem
            title={t('report.tax_id')}
            value={selectedZReportData?.Kkm.Company.Bin || ''}
          />
          <DefinitionItem title={t('report.kkm')} value={selectedZReportData?.Kkm.Id || ''} />
          <DefinitionItem
            title={t('report.total_amount')}
            value={selectedZReportData?.Kkm.Rnm || ''}
          />
          <DefinitionItem
            title={t('report.cash_register_name')}
            value={selectedZReportData?.Kkm.Name || ''}
          />
          <DefinitionItem
            title={t('report.trading_point_address')}
            value={`${selectedZReportData?.Kkm.Address.Town.Name}, ${selectedZReportData?.Kkm.Address.Street}
									${selectedZReportData?.Kkm.Address.House} ${selectedZReportData?.Kkm.Address.Flat}`}
          />
          <DefinitionItem title={t('report.cashier')} value={user?.Name || ''} />
        </DefinitionList>
        <DefinitionList>
          <DefinitionItem
            title={t('report.issued_receipts_alt')}
            value={formatAmount(selectedZReportData?.Count || 0, true)}
          />
          <DefinitionItem
            title={t('report.balance_at_opening')}
            value={formatAmount(selectedZReportData?.BalanceOpen || 0, true)}
          />
          <DefinitionItem title={t('report.balance_at_closing')} value="" />

          <DefinitionItem
            title={t('main.cash')}
            value={formatAmount(selectedZReportData?.BalanceClose || 0, true)}
          />
          <DefinitionItem
            title={t('main.cashless')}
            value={formatAmount(selectedZReportData?.BalanceCloseNonCash || 0, true)}
          />
        </DefinitionList>
      </DefinitionSection>
    </DisclosureCustom>
  );
};

export { MainInfo };
