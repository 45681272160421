import { Field, Label, Switch } from '@headlessui/react';
import { Controller } from 'react-hook-form';

type Props = {
  label: string;
  name: string;
  control: any;
};

const SwitchCustom = ({ label, name, control }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Field className="flex justify-between items-center gap-2">
          <Label>{label}</Label>

          <Switch
            checked={value}
            onChange={onChange}
            className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition border border-darkGray"
          >
            <span className="size-4 translate-x-1 rounded-full bg-lightGray-second transition group-data-[checked]:translate-x-6 group-data-[checked]:bg-blue " />
          </Switch>
        </Field>
      )}
    />
  );
};

export { SwitchCustom };
