export const sortArrayByParam = (
  array: any,
  param: string,
  sortAscending = false,
  type = 'string',
) => {
  if (type === 'number')
    return array.sort((a: any, b: any) =>
      sortAscending ? a[param] - b[param] : b[param] - a[param],
    );

  return array.sort((a: any, b: any) =>
    sortAscending ? a[param].localeCompare(b[param]) : b[param].localeCompare(a[param]),
  );
};
