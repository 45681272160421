import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { KkmChart, KkmItem, KkmList, KkmRequest } from '@/types/cashbox';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const cashboxService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getKkmsByCompanyId: build.query<ApiResponse<KkmList>, KkmRequest>({
      query: (body) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_KKMS_BY_COMPANY_ID),
        method: 'POST',
        body,
      }),
    }),
    getKkmsById: build.query<ApiResponse<KkmItem>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_KKM_BY_ID, { idKkm }),
    }),
    getKkmChart: build.query<ApiResponse<KkmChart>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_KKM_CHART, { idKkm }),
      providesTags: ['Operations'],
    }),
  }),
});

export const {
  useLazyGetKkmsByCompanyIdQuery,
  useGetKkmsByIdQuery,
  useLazyGetKkmsByIdQuery,
  useGetKkmChartQuery,
  useLazyGetKkmChartQuery,
} = cashboxService;
