import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { User } from '@/types/auth';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const userService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<ApiResponse<User>, number>({
      query: (idUser) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_USER, { idUser }),
      providesTags: ['User'],
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery } = userService;
