import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { MainInfo } from './MainInfo';
import { OperationsList } from './OperationsList';
import { Receipts } from './Receipts';
import { Sections } from './Sections';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setIsPrint } from '@/store/app/slice';
import { setSelectedZReportsData } from '@/store/reports/slice';
import { getFormattedFullDate } from '@/utils/dates';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { BackButtonWithHeader } from '@/views/shared/BackButtonWithHeader';
import { Button } from '@/views/shared/Button';
import { Error } from '@/views/shared/Form/Error';
import { Icon } from '@/views/shared/Icon';
import { Loading } from '@/views/shared/Loading';

type Props = {
  isLoading?: boolean;
  title?: string;
  error?: string;
  onBack?: () => void;
};

const DuplicateZReportPage = ({ title, isLoading, error, onBack }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedZReportData } = useAppSelector((state) => state.reports);
  const { isPrint } = useAppSelector((state) => state.app);
  const { shift } = useAppSelector((state) => state.shift);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });

  const handlePrint = () => {
    dispatch(setIsPrint(true));
  };

  useEffect(() => {
    try {
      if (isPrint) {
        reactToPrintFn();
      }
    } catch (e) {
      console.log('Неудалось распечатать', e);
    } finally {
      dispatch(setIsPrint(false));
    }
  }, [isPrint]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedZReportsData(null));
    };
  }, []);

  const returnToZReportList = useCallback(() => {
    dispatch(setSelectedZReportsData(null));
  }, [dispatch]);

  const renderContent = useMemo(() => {
    if (isLoading) return <Loading />;

    if (error) {
      return (
        <div className="mt-4">
          <Error text={error} />
        </div>
      );
    }

    if (selectedZReportData) {
      return (
        <div ref={printRef}>
          <div className="grid lg:grid-cols-3 lg:gap-4 mt-4 items-start">
            <MainInfo />
            <Receipts />
            <Sections />
          </div>
          <OperationsList />
        </div>
      );
    }

    return null;
  }, [error, isLoading, selectedZReportData]);

  return (
    <div>
      <div
        className={twMergeClsx(
          'flex flex-col lg:flex-row items-center gap-5 justify-between',
          !selectedZReportData && 'justify-start',
        )}
      >
        <BackButtonWithHeader
          title={title || 'report.duplicate_z_report'}
          onClick={onBack || returnToZReportList}
        />
        {selectedZReportData && (
          <>
            <div className="flex justify-center lg:justify-end">
              <div className="bg-darkGray rounded-2xl px-3 py-1 text-white flex gap-1">
                {t('report.shift')}: {shift?.ShiftIndex} <span>|</span>
                {getFormattedFullDate(selectedZReportData?.DateOpen || '')} <span>-</span>
                {getFormattedFullDate(selectedZReportData?.DateClose || '')}
              </div>
            </div>
            <div className="w-full lg:w-auto lg:flex lg:justify-end">
              <Button
                onClick={handlePrint}
                Icon={<Icon type="printLine" />}
                className="w-full lg:w-auto m-0"
                text="close_shift.print"
              />
            </div>
          </>
        )}
      </div>
      {renderContent}
    </div>
  );
};

export { DuplicateZReportPage };
