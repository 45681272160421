import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, type ReactNode } from 'react';

import { Button } from '../Button';

import useContainer from './hook';

import { ButtonKinds } from '@/types/button';
import { twMergeClsx } from '@/utils/tw-with-clsx';

type ModalPropsType = {
  title?: string;
  open: boolean;
  children: ReactNode;
  onClose: () => void;
  className?: string;
  classNameContainer?: string;
  withoutCloseOverlay?: boolean;
  withCloseButton?: boolean;
};

const Modal = ({
  title = '',
  open,
  onClose,
  children,
  className = '',
  classNameContainer,
  withoutCloseOverlay,
  withCloseButton = false,
}: ModalPropsType) => {
  const { isOpen, closeModal } = useContainer({ open, onClose });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => !withoutCloseOverlay && closeModal()}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div className="fixed inset-0 overflow-y-auto">
          <div
            className={twMergeClsx(
              'flex items-center p-2 text-center min-h-full justify-center',
              classNameContainer,
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel
                className={twMergeClsx(
                  'w-full transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all max-w-[416px]',
                  className,
                )}
              >
                {title && (
                  <DialogTitle
                    as="h3"
                    className={twMergeClsx(
                      'leading-6 font-semibold text-xl flex justify-between items-center ',
                      { 'mb-7': title },
                    )}
                  >
                    {title}
                    {withCloseButton && (
                      <Button
                        kind={ButtonKinds.iconOnly}
                        Icon={<XMarkIcon className="w-6" />}
                        onClick={onClose}
                        className="m-0"
                      />
                    )}
                  </DialogTitle>
                )}
                {!title && withCloseButton && (
                  <Button
                    kind={ButtonKinds.iconOnly}
                    Icon={<XMarkIcon className="w-6" />}
                    onClick={onClose}
                    className="absolute right-0 top-0 m-2.5"
                  />
                )}
                <div>{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
