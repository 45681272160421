import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DISCOUNT_TYPES, OperationType } from '@/constants/operations';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { changeStorno, clearPositions } from '@/store/operations/slice';
import { ButtonKinds } from '@/types/button';
import type { UpdateStorno } from '@/types/operations';
import { formatAmount } from '@/utils/format-amount';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { Button } from '@/views/shared/Button';
import { Icon } from '@/views/shared/Icon';

const PreCheck = ({ operationType }: { operationType?: OperationType }) => {
  const { positions } = useAppSelector((state) => state.operations);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const clearPrecheck = () => {
    dispatch(clearPositions());
  };

  const handleChangeStorno = ({ id, storno }: UpdateStorno) => {
    dispatch(
      changeStorno({
        id,
        storno,
      }),
    );
  };

  const emptyText = useMemo(() => {
    if (operationType === OperationType.PURCHASE_REFUND) {
      return t('other.precheck_refund_description');
    }

    return t('other.add_items_on_the_left_block');
  }, [operationType, t]);

  return (
    <div className="bg-white rounded-lg outline-none w-full lg:max-w-[360px]">
      <div className="flex justify-between items-center p-4 border-b border-lightGray-five">
        <div className="flex items-center gap-2">
          <h3 className="font-semibold text-lg leading-5">{t('receipt.precheck')}</h3>
          <div className="bg-lightGray-five/50 rounded-lg px-2 py-[2px] text-xs font-semibold text-darkGray-fourth">
            {t('receipt.precheck_item_count', { count: positions.length })}
          </div>
        </div>
        <button
          type="button"
          disabled={!positions.length}
          className="text-darkRed font-semibold disabled:text-darkGray-five text-sm"
          onClick={clearPrecheck}
        >
          {t('receipt.reset_precheck')}
        </button>
      </div>
      {positions.length ? (
        positions.map((item) => (
          <div
            key={item.id}
            className={twMergeClsx(
              'p-4 border-b border-lightGray-five last:border-none',
              item.storno && 'line-through text-darkRed',
            )}
          >
            <div className="flex items-start justify-between">
              <div className="text-lg font-semibold leading-6">
                <div>{item.name}</div>
                <div>
                  {formatAmount(item.price, true)} x {item.qty} {item.unitShortName}.{' '}
                </div>
              </div>
              <Button
                kind={ButtonKinds.secondary}
                className="mx-0 w-11"
                onClick={() =>
                  handleChangeStorno({
                    id: item.id,
                    storno: item.storno,
                  })
                }
              >
                {item.storno ? <Icon type="returnIcon" /> : <Icon type="storno" />}
              </Button>
            </div>
            <div className="my-2 flex flex-col text-sm text-darkGray-fourth">
              <div>
                {t('receipt.discount_alt')}{' '}
                {formatAmount(
                  item.discountPercent,
                  true,
                  item.discountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸',
                )}
                = {formatAmount(item.discount, true)}
              </div>
              <div className="flex items-center gap-1">
                <p>
                  {t('receipt.markup_alt')} {item.markupPercent}
                  {item.discountType === DISCOUNT_TYPES.PERCENT ? '%' : ' ₸'} = {item.markup} ₸
                </p>
                <div className="bg-lightGray-second rounded-full w-1 h-1" />
                <p>{item.sectionName}</p>
              </div>
            </div>
            <div className="text-lg font-semibold leading-6">{formatAmount(item.total, true)}</div>
          </div>
        ))
      ) : (
        <div className="p-4 text-center text-darkGray-five font-semibold">
          <p>{t('other.empty_precheck')}</p>
          <p>{emptyText}</p>
        </div>
      )}
    </div>
  );
};

export { PreCheck };
