import { format, differenceInDays, isAfter } from 'date-fns';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from './useRedux';

import {
  DEFAULT_PAGE,
  KKM_STATUSES,
  LABEL_TYPES,
  TOTAL_KKMS_COUNT,
  TOTAL_KKMS_COUNT_FILTER,
} from '@/constants/common';
import { useLazyGetKkmsByCompanyIdQuery, useLazyGetKkmsByIdQuery } from '@/store/cashbox/service';
import { setKkmItem, setKkmList, setKkmMetadata } from '@/store/cashbox/slice';
import { useLazyGetCompaniesQuery } from '@/store/companies/service';
import { setCompanyId, setCompanyItem } from '@/store/companies/slice';
import { useLazyGetExpiredKkmListQuery } from '@/store/pult/service';
import { useLazyGetShiftQuery } from '@/store/shift/service';
import { setOpenedShift } from '@/store/shift/slice';
import type { ExpiredDate, Kkm, KkmList, LoadKkm } from '@/types/cashbox';

const useGetCompanies = () => {
  const { user } = useAppSelector((state) => state.user);
  const { companyId } = useAppSelector((state) => state.companies);
  const { kkmSearch, kkmStatus } = useAppSelector((state) => state.cashbox);
  const [getCompanies, { data: companiesData, isLoading: loadingCompanies }] =
    useLazyGetCompaniesQuery();
  const [getShifts, { isLoading: loadingShifts }] = useLazyGetShiftQuery();
  const [getKkms] = useLazyGetKkmsByCompanyIdQuery();
  const [getKkmById] = useLazyGetKkmsByIdQuery();
  const [getExpiredKkmList] = useLazyGetExpiredKkmListQuery();
  const [isLoadingKKms, setIsLoadingKkms] = useState(true);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getKkmsWithLabels = useCallback(
    (kkms: Kkm[]) => {
      if (!user || !kkms) {
        return [];
      }

      return kkms.map((kkm) => {
        if (kkm.IdStatusKkm === KKM_STATUSES.REGISTER_IN_PROGRESS)
          return {
            ...kkm,
            LabelText: 'other.registration_in_progress',
            LabelType: user.Lock ? LABEL_TYPES.SECONDARY : LABEL_TYPES.DANGER,
          };

        if (kkm.IdStatusKkm === KKM_STATUSES.BLOCKED_BY_OFD) {
          if (kkm.IsActive)
            return {
              ...kkm,
              LabelText: 'other.cash_register_blocked_in_ofd_kassa',
              LabelType: user.Lock ? LABEL_TYPES.SECONDARY : LABEL_TYPES.DANGER,
            };

          return {
            ...kkm,
            LabelText: 'other.cash_register_blocked_in_ofd',
            LabelType: user.Lock ? LABEL_TYPES.SECONDARY : LABEL_TYPES.DANGER,
          };
        }

        if (!kkm.IsActive)
          return {
            ...kkm,
            LabelText: 'main.cash_register_blocked_in_k24v',
            LabelType: user.Lock ? LABEL_TYPES.SECONDARY : LABEL_TYPES.DANGER,
          };

        if (!kkm.Lock)
          return {
            ...kkm,
            LabelText: 'main.cash_register_available',
            LabelType: user.Lock ? LABEL_TYPES.AVAILABLE : LABEL_TYPES.PRIMARY,
          };

        if (kkm.IdShift !== user.IdShift)
          return {
            ...kkm,
            LabelText: 'main.shift_opened_by_another_cashier',
            LabelType: user.Lock ? LABEL_TYPES.SECONDARY : LABEL_TYPES.WARNING,
          };

        return {
          ...kkm,
          LabelText: user.Lock ? 'main.your_shift' : 'main.cash_register_available',
          LabelType: user.Lock ? LABEL_TYPES.SUCCESS : LABEL_TYPES.SECONDARY,
        };
      });
    },
    [user],
  );

  const getKkmsWithDates = useCallback(
    async (kkms: Kkm[], id: number) => {
      const expiredList = await getExpiredKkmList(id).unwrap();

      if (!expiredList || !kkms) {
        return [];
      }

      return kkms.map((kkm) => {
        const expiredDate = expiredList[kkm.Id];

        if (expiredDate) {
          const expiredDateObj: ExpiredDate = {
            label: '',
          };
          const date = new Date(expiredDate);
          const today = new Date();
          const differenceDays = differenceInDays(date, today);

          if (differenceDays > 0 && differenceDays <= 3) {
            expiredDateObj.label = 'main.kkm_expired';
            expiredDateObj.days = differenceDays;
          } else if (differenceDays > 3 && isAfter(date, today)) {
            expiredDateObj.label = 'main.kkm_expired_date';
            expiredDateObj.date = format(date, 'dd.MM.yyyy');
          } else {
            expiredDateObj.label = 'main.kkm_expired_need_pay';
          }

          return {
            ...kkm,
            ExpiredDate: expiredDateObj,
          };
        }

        return kkm;
      });
    },
    [getExpiredKkmList],
  );

  const loadCompanies = async () => {
    try {
      const companiesResponse = await getCompanies({}).unwrap();

      const companies = companiesResponse.Data.Companies;

      if (!companies || companies.length < 1) {
        throw new Error(
          JSON.stringify({
            body: {
              response: {
                data: {
                  Message: t('main.empty_companies'),
                },
              },
            },
          }),
        );
      }

      if (user?.Lock) {
        const shiftResponse = await getShifts(user.IdShift).unwrap();

        const shift = shiftResponse.Data;

        if (shift) {
          const kkmResponse = await getKkmById(shift.IdKkm).unwrap();

          dispatch(setOpenedShift(shift));
          dispatch(setCompanyId(shift.IdCompany));
          dispatch(setKkmItem(kkmResponse.Data.Kkm));
        }
      } else {
        dispatch(setCompanyId(companies[0].Id));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sliceKkmsList = ({
    data,
    currentPage,
    itemsPerPage,
  }: {
    data: KkmList;
    currentPage: number;
    itemsPerPage: number;
  }): KkmList => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return {
      ...data,
      kkms: data?.kkms.slice(start, end),
    };
  };

  const totalKkmList = ({ data, itemsPerPage }: { data: Kkm[]; itemsPerPage: number }) => {
    if (!data) {
      return 0;
    }

    return Math.ceil(data.length / itemsPerPage);
  };

  const loadKkms = useCallback(
    async (options?: LoadKkm) => {
      const isFilter = kkmSearch;

      if (!companyId) {
        return;
      }

      setIsLoadingKkms(true);

      const company = companiesData?.Data.Companies.find((item) => item.Id === companyId);

      if (company) {
        dispatch(setCompanyItem(company));
      }

      try {
        const kkmResponse = await getKkms({
          companies: [companyId],
          pageSize: isFilter ? TOTAL_KKMS_COUNT_FILTER : TOTAL_KKMS_COUNT,
          currentPage: isFilter ? DEFAULT_PAGE : options?.page || DEFAULT_PAGE,
          message: kkmSearch,
          status: kkmStatus?.value,
        }).unwrap();

        const kkmData = isFilter
          ? sliceKkmsList({
              currentPage: options?.page || 1,
              itemsPerPage: TOTAL_KKMS_COUNT,
              data: kkmResponse.Data,
            })
          : kkmResponse.Data;

        const kkms = await getKkmsWithDates(getKkmsWithLabels(kkmData.kkms), companyId);

        const totalPages = isFilter
          ? totalKkmList({
              data: kkmResponse.Data.kkms,
              itemsPerPage: TOTAL_KKMS_COUNT,
            })
          : Math.ceil(kkmData.total / kkmData.pageSize);

        dispatch(setKkmList(kkms || []));
        dispatch(
          setKkmMetadata({
            currentPage: isFilter ? options?.page || DEFAULT_PAGE : kkmData.currentPage,
            pageSize: kkmData.pageSize,
            total: kkmData.total,
            totalPages,
          }),
        );
      } catch (e) {
        console.log(e);
        dispatch(setKkmList([]));
      } finally {
        setIsLoadingKkms(false);
      }
    },
    [
      companiesData?.Data.Companies,
      companyId,
      dispatch,
      getKkms,
      getKkmsWithDates,
      getKkmsWithLabels,
      kkmSearch,
      kkmStatus?.value,
    ],
  );

  return {
    companiesData,
    companyId,
    isLoading: loadingCompanies || loadingShifts,
    isLoadingKKms,
    loadKkms,
    loadCompanies,
  };
};

export { useGetCompanies };
