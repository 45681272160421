import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import useContainer from './hook';

import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { Loading } from '@/views/shared/Loading';
import { SearchInput } from '@/views/shared/SearchInput';
import { Table } from '@/views/shared/Table';

const CashierSettings = () => {
  const {
    t,
    tableHead,
    isLoading,
    cashierList,
    searchValue,
    handleSearchCashierList,
    changeCashierActivity,
    setSearchValue,
  } = useContainer();

  const tableRows = useMemo(
    () =>
      cashierList?.map((cashier) => ({
        UserName: cashier?.User.Name,
        Operations: (
          <Button
            className="m-0 h-9 text-sm"
            kind={cashier?.Active ? ButtonKinds.danger : ButtonKinds.primary}
            Icon={
              cashier?.Active ? (
                <LockClosedIcon className="w-4" />
              ) : (
                <LockOpenIcon className="w-4" />
              )
            }
            onClick={changeCashierActivity(cashier?.Id)}
            text={cashier?.Active ? 'kassa_settings.block' : 'kassa_settings.unblock'}
          />
        ),
      })) || [],
    [cashierList, changeCashierActivity],
  );

  const count = useMemo(() => cashierList?.length || 0, [cashierList?.length]);

  if (isLoading) {
    return <Loading />;
  }

  if (!cashierList?.length && !searchValue) {
    return (
      <div className="mt-5">
        <p> {t('other.cashiers_not_found')}</p>
        <p>{t('other.you_can_add_cashiers_in_kassa24')}</p>
      </div>
    );
  }

  return (
    <div>
      {!cashierList?.length ? (
        <div>{t('other.cashiers_not_found')}</div>
      ) : (
        <div>
          <div className="my-5 flex items-center justify-between flex-col lg:flex-row">
            <div className="font-semibold text-xl">
              <Trans i18nKey="kassa_settings.cashier_alt" count={count}>
                {{ count }}
              </Trans>
            </div>
            <SearchInput
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('kassa_settings.search')}
              onSearch={handleSearchCashierList}
            />
          </div>
          <Table
            rows={tableRows}
            heads={tableHead}
            classCellName="last:text-right lg:first:w-full"
            classHeadCellName="last:text-right"
          />
        </div>
      )}
    </div>
  );
};

export { CashierSettings };
