import { TabGroup, TabList, TabPanels, TabPanel, Tab } from '@headlessui/react';

import { twMergeClsx } from '@/utils/tw-with-clsx';

type TabItem = { id: number; label: string; Component: JSX.Element };

type Props = {
  activeTabId: number;
  tabList: TabItem[];
  onChange: (tabId: number) => void;
  tabClassName?: string;
};

const CustomTabs = ({ activeTabId, tabList, tabClassName, onChange }: Props) => {
  return (
    <TabGroup selectedIndex={activeTabId} onChange={onChange}>
      <TabList className="flex rounded-lg border border-b-0 lg:border-b border-lightGray-second text-sm overflow-hidden bg-white flex-wrap lg:flex-nowrap">
        {tabList.map((tabItem) => (
          <Tab
            key={tabItem.id}
            className={twMergeClsx(
              ' text-darkGray font-semibold p-2 outline-none border-b lg:border-b-0 first-of-type:border-l-0 border-l border-lightGray-second hover:bg-white-second flex-1 ',
              activeTabId === tabItem.id && 'bg-lightGray-five text-black',
              tabClassName,
            )}
          >
            {tabItem.label}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabList.map(({ Component, id }) => (
          <TabPanel key={id}>{Component}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};

export { CustomTabs };
