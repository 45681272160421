import { SpinnerIcon } from '../Spinner';

type Props = {
  text: string;
};

const SpinnerContainer = ({ text }: Props) => {
  return (
    <div className="text-blue w-full h-full text-base flex justify-center items-center gap-2">
      <SpinnerIcon className="stroke-blue w-6 h-6" />
      <div>{text}</div>
    </div>
  );
};

export { SpinnerContainer };
