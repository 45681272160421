import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/hooks';
import type { MenuListType } from '@/types/app';
import { buildAppRouteUrl, getLastPathSegment, twMergeClsx } from '@/utils';

const DropdownMenu = ({ menu, buttonName }: { menu: MenuListType[]; buttonName: string }) => {
  const { t } = useTranslation();
  const { openedShift } = useAppSelector((state) => state.shift);
  const location = useLocation();

  if (!openedShift) {
    return <div />;
  }

  const checkOperationFromUrl = (currentLocation: string, menuLink: string) => {
    return getLastPathSegment(currentLocation) === getLastPathSegment(menuLink);
  };

  return (
    <Menu>
      <MenuButton className="bg-blue rounded-xl leading-10 flex items-center gap-4 outline-none w-40 justify-center">
        {buttonName} <ChevronDownIcon className="w-4 text-white" />
      </MenuButton>
      <MenuItems
        anchor="bottom"
        transition
        className="origin-top z-50 transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-blue rounded-xl rounded-t-none w-40 -mt-3 pt-4"
      >
        {menu.map((link: MenuListType) => (
          <MenuItem key={link.link}>
            <Link
              to={buildAppRouteUrl(link.link, { idKkm: openedShift.IdKkm })}
              className={twMergeClsx(
                'text-white block px-3 py-2 data-[focus]:bg-lightBlue',
                checkOperationFromUrl(location.pathname, link.link) && ' bg-lightBlue',
              )}
            >
              {link?.withIntl ? t(link.name) : link.name}
            </Link>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};

export { DropdownMenu };
