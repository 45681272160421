import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Languages } from '@/constants/app';
import { getFromLocalStorage } from '@/utils/localStorage';

type AppSliceTypes = {
  modal: ModalType | null;
  locale: Languages;
  isPrint: boolean;
};

type ModalType = {
  modalType: string;
  modalProps?: Record<string, unknown>;
};

const initialState: AppSliceTypes = {
  modal: null,
  isPrint: false,
  locale: getFromLocalStorage('i18nextLng') || Languages.RU,
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<ModalType>) => {
      state.modal = payload;
    },
    hideModal: (state) => {
      state.modal = null;
    },
    setLocale: (state, { payload }: PayloadAction<Languages>) => {
      state.locale = payload;
    },
    setIsPrint: (state, { payload }: PayloadAction<boolean>) => {
      state.isPrint = payload;
    },
  },
});

export const { showModal, hideModal, setLocale, setIsPrint } = app.actions;

export default app.reducer;
