import { useEffect, useState } from 'react';

type Props = {
  queryCallback: (message: string) => void;
  emptyCallback: () => void;
};

const useSearch = ({ queryCallback, emptyCallback }: Props) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query) {
        queryCallback(query);
      } else {
        emptyCallback();
      }
    }, 500);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    query,
    setQuery,
  };
};

export { useSearch };
