import i18n from 'i18next';
import { useCallback, useEffect } from 'react';

import type { Languages } from '@/constants/app';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setLocale } from '@/store/app/slice';
import { setToLocalStorage } from '@/utils/localStorage';

const useContainer = () => {
  const { locale } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const checkLang = useCallback(
    (lang: Languages) => () => {
      i18n.changeLanguage(lang);
      setToLocalStorage('i18nextLng', lang);
      dispatch(setLocale(lang));
    },
    [dispatch],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      i18n.changeLanguage(locale);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [locale]);

  return { lang: locale, checkLang };
};

export default useContainer;
