import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  value: string | number;
};

const DashedItem = ({ label, value }: Props) => {
  const { t } = useTranslation();

  return (
    <dl className="flex items-end text-xs leading-120 font-normal text-blackPrimary/60 last:mb-0">
      <dt className="flex-shrink-0 text-darkGray">{t(label)}</dt>
      <span className="w-full mx-2 border-t border-dashed border-lightGray" />
      <dd className="max-w-[162px] flex-shrink-0 text-right text-black">{value}</dd>
    </dl>
  );
};

export { DashedItem };
