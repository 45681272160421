import {
  ChevronRightIcon,
  ClockIcon,
  CreditCardIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, ComputerDesktopIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import { BalanceItem } from './BalanceItem';
import useContainer from './hook';

import { REPORT_TYPES } from '@/constants';
import { ROUTES } from '@/constants/routes';
import { ButtonKinds } from '@/types/button';
import { buildAppRouteUrl } from '@/utils/build-route-url';
import { formatAmount } from '@/utils/format-amount';
import { CloseShiftModal } from '@/views/pages/Shift/CloseShiftModal';
import { Button } from '@/views/shared/Button';
import CustomPopover from '@/views/shared/Popover';
import { twMerge } from 'tailwind-merge';

const KassaInfo = () => {
  const {
    openedShift,
    t,
    totalBalance,
    totalReceiptCount,
    isOpenShiftModal,
    cashBalance,
    cashlessBalance,
    toggleCloseShiftModal,
  } = useContainer();

  return (
    <>
      <CloseShiftModal isOpen={isOpenShiftModal} onClose={toggleCloseShiftModal} />
      <CustomPopover
        className="w-[256px]"
        header={({ open }) => (
          <div className="flex gap-2 text-left">
            <ComputerDesktopIcon className="w-6" />
            <div>
              <span className="font-semibold">
                {t('main.cash_register_number')} №{openedShift?.IdKkm}
              </span>
              <div className="text-xs">
                {t('main.balance')} {formatAmount(totalBalance ?? 0, true)}
              </div>
            </div>
            <ChevronDownIcon
              className={twMerge('w-3 transition-transform', open && 'rotate-180')}
            />
          </div>
        )}
      >
        {({ close }) => (
          <>
            <BalanceItem Icon={WalletIcon} title="main.cash" amount={cashBalance?.Amount ?? 0} />
            <BalanceItem
              Icon={CreditCardIcon}
              title="main.cashless"
              amount={cashlessBalance?.Amount ?? 0}
            />

            <hr className="opacity-40" />
            <Link
              to={buildAppRouteUrl(ROUTES.REPORT, { idShift: openedShift?.Id ?? 0 })}
              onClick={() => close()}
              className="px-3 flex justify-between items-center hover:opacity-80"
            >
              {t('main.all_reports')}
              <ChevronRightIcon className="w-3" />
            </Link>
            <Link
              to={`${buildAppRouteUrl(ROUTES.REPORT, { idShift: openedShift?.Id ?? 0 })}tab=${REPORT_TYPES.X_REPORT}`}
              onClick={() => close()}
              className="px-3 flex justify-between items-center hover:opacity-80"
            >
              {t('main.x_report')}
              <ChevronRightIcon className="w-3" />
            </Link>
            <hr className="opacity-40" />
            <div className="px-3">
              <Button
                text="main.close_shift"
                kind={ButtonKinds.danger}
                onClick={toggleCloseShiftModal}
              />
            </div>
          </>
        )}
      </CustomPopover>
      <div className="flex gap-2">
        <ClockIcon className="w-6" />
        <div>
          <span className="font-semibold">
            {t('main.shift')} №{openedShift?.ShiftIndex}
          </span>
          <p className="text-xs">
            {t('main.number_of_receipts')}: {totalReceiptCount}
          </p>
        </div>
      </div>
    </>
  );
};

export { KassaInfo };
