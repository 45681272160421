import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { operationsService } from '../operations/service';

import { settingsService } from './service';

import type { Section, Unit, ArticleItem } from '@/types';
import type { CashierList, OfdList } from '@/types/settings';

type InitialState = {
  headSnackbarName: string;
  ofdList: OfdList[] | null;
  cashierList: CashierList[] | null;
  sectionList: Section[];
  articleList: ArticleItem[];
  unitList: Unit[];
};

const initialState: InitialState = {
  headSnackbarName: '',
  ofdList: [],
  cashierList: [],
  sectionList: [],
  articleList: [],
  unitList: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setHeadSnackbarName: (state, { payload }: PayloadAction<string>) => {
      state.headSnackbarName = payload;
    },
    setOfdList: (state, { payload }: PayloadAction<OfdList[] | null>) => {
      state.ofdList = payload;
    },
    setCashierList: (state, { payload }: PayloadAction<CashierList[] | null>) => {
      state.cashierList = payload;
    },
    setSectionList: (state, { payload }: PayloadAction<Section[]>) => {
      state.sectionList = payload;
    },
    setArticleList: (state, { payload }: PayloadAction<ArticleItem[]>) => {
      state.articleList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      settingsService.endpoints.getCashierList.matchFulfilled,
      (state, { payload }) => {
        state.cashierList = payload.Data;
      },
    );
    builder.addMatcher(
      settingsService.endpoints.getSectionsList.matchFulfilled,
      (state, { payload }) => {
        state.sectionList = payload.Data.Sections;
      },
    );
    builder.addMatcher(
      settingsService.endpoints.getArticlesList.matchFulfilled,
      (state, { payload }) => {
        state.articleList = payload.Data;
      },
    );
    builder.addMatcher(
      operationsService.endpoints.getUnits.matchFulfilled,
      (state, { payload }) => {
        state.unitList = payload.Data;
      },
    );
  },
});

export default settingsSlice.reducer;

export const { setHeadSnackbarName, setOfdList, setCashierList, setSectionList, setArticleList } =
  settingsSlice.actions;
