import { Tooltip } from 'react-tooltip';

import { Icon } from '../../Icon';

import type { LabelFormPropsType } from '@/types/form';

const Label = ({ name, text, LabelIcon, tooltipContent, tooltipId }: LabelFormPropsType) => {
  return (
    <>
      <label htmlFor={name} className="text-sm text-darkGray pl-2 flex items-center gap-1">
        {LabelIcon}
        <span>{text}</span>
        {tooltipId && tooltipContent && (
          <i data-tooltip-id={tooltipId}>
            <Icon type="info" className="w-4 h-4" />
          </i>
        )}
      </label>

      <Tooltip id={tooltipId} className="max-w-[232px] z-50" place="bottom" noArrow>
        <div className="text-xs text-lightGray-second">{tooltipContent}</div>
      </Tooltip>
    </>
  );
};

export { Label };
