import { PlusIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { AddSectionModal } from './AddModal';
import useContainer from './hook';

import { ButtonKinds } from '@/types/button';
import { getAmountWithZeroes } from '@/utils/format-amount';
import { Button } from '@/views/shared/Button';
import { Loading } from '@/views/shared/Loading';
import { SearchInput } from '@/views/shared/SearchInput';
import { Table } from '@/views/shared/Table';

const SectionsSettings = ({ refetch }: { refetch: () => void }) => {
  const {
    t,
    tableHead,
    isLoading,
    sectionList,
    searchValue,
    showAddModal,
    handleSearchSectionList,
    changeSectionStatus,
    setSearchValue,
    setShowAddModal,
    handleAddSection,
  } = useContainer({ refetch });

  const tableRows = useMemo(
    () =>
      sectionList?.map((section) => ({
        Name: section.Name,
        Nds: (
          <div>
            <span className="lg:hidden">{t('kassa_settings.vat')}:</span>
            {getAmountWithZeroes(section.Nds)}
            <span className="lg:hidden">%</span>
          </div>
        ),
        Operations: (
          <Button
            className="m-0 h-9 text-sm lg:inline-flex w-full"
            kind={section.Active ? ButtonKinds.danger : ButtonKinds.primary}
            onClick={changeSectionStatus(section.Id)}
            text={section.Active ? 'kassa_settings.disable' : 'kassa_settings.enable'}
          />
        ),
      })) || [],
    [changeSectionStatus, sectionList, t],
  );

  const count = useMemo(() => sectionList?.length || 0, [sectionList?.length]);

  const renderedContent = useMemo(() => {
    if (!sectionList?.length && !searchValue) {
      return <div className="whitespace-pre">{t('kassa_settings.not_found')}</div>;
    }

    if (!sectionList?.length) return <div>{t('kassa_settings.section_search_not_found')}</div>;

    return (
      <Table
        rows={tableRows}
        heads={tableHead}
        classCellName="last:text-right font-normal lg:last:w-[120px] last:w-full"
        classHeadCellName="last:text-right"
      />
    );
  }, [searchValue, sectionList?.length, t, tableHead, tableRows]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <AddSectionModal
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        onAdd={handleAddSection}
      />
      <div className="my-5 flex items-center justify-between flex-col lg:flex-row gap-4">
        <div className="font-semibold text-xl">
          <Trans i18nKey="kassa_settings.section_alt" count={count}>
            {{ count }}
          </Trans>
        </div>
        <div className="flex items-center justify-between gap-4 flex-col lg:flex-row w-full lg:w-auto">
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('kassa_settings.search')}
            onSearch={handleSearchSectionList}
            className="w-full lg:w-auto"
          />
          <Button
            text="kassa_settings.add_section"
            Icon={<PlusIcon className="w-4" />}
            className="w-full lg:w-auto"
            onClick={() => setShowAddModal(true)}
          />
        </div>
      </div>
      {renderedContent}
    </div>
  );
};

export { SectionsSettings };
