import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useGetCompaniesQuery } from '@/store/companies/service';
import { setCompanyId } from '@/store/companies/slice';
import { renderAddress } from '@/utils';
import { twMergeClsx } from '@/utils/tw-with-clsx';

const Companies = () => {
  const { companyId } = useAppSelector((state) => state.companies);
  const { data } = useGetCompaniesQuery({});
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isMobile = useMedia('(max-width: 1024px)');

  const selectCompany = async (id: number) => {
    dispatch(setCompanyId(id));
  };

  return (
    <div className="bg-[transparent] lg:bg-white lg:max-w-[480px] lg:rounded-lg overflow-hidden w-full">
      <h3 className="text-darkGray hidden lg:block text-sm px-4 py-2.5 border-b border-lightGray">
        {t('main.list_of_companies')}
      </h3>
      {isMobile ? (
        <div className="rounded-md overflow-x-scroll flex flex-1 border border-lightGray-second">
          {data?.Data.Companies.map((item) => (
            <button
              type="button"
              key={item.Id}
              onClick={() => selectCompany(item.Id)}
              className={twMergeClsx(
                'text-blue py-3 px-4 min-w-[150px] w-full outline-none border-lightGray-second border-r last:border-none',
                companyId === item.Id && 'bg-lightGray-five font-medium',
              )}
            >
              {item.ShortName}
            </button>
          ))}
        </div>
      ) : (
        <div>
          {data?.Data.Companies.map((item) => (
            <button
              type="button"
              key={item.Id}
              onClick={() => selectCompany(item.Id)}
              className={twMergeClsx(
                'px-4 py-2.5 flex flex-col w-full min-h-[68px] gap-2 justify-between transition-colors',
                companyId === item.Id && 'bg-blue',
              )}
            >
              <div className="flex items-center justify-between w-full">
                <h5
                  className={twMergeClsx(
                    'text-base whitespace-nowrap text-black font-semibold',
                    companyId === item.Id && 'text-white',
                  )}
                >
                  {item.ShortName}
                </h5>
                <div
                  className={twMergeClsx(
                    'px-1.5 py-1 rounded-[4px] min-w-[65px] bg-lightGray text-xs text-darkGray-second font-semibold text-center',
                    companyId === item.Id && 'bg-white/20 text-white',
                  )}
                >
                  {t('main.cash_register_count', { count: item.KkmsAmount })}
                </div>
              </div>
              <div
                className={twMergeClsx(
                  'text-darkGray text-left',
                  companyId === item.Id && 'text-white',
                )}
              >
                {renderAddress(item.Address)}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { Companies };
