import type { ReactNode } from 'react';

const DefinitionSection = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden">
      <div className="px-3 py-2 border-b border-lightGray-second ">
        <div className="text-black lg:text-darkGray-five font-semibold">{title}</div>
      </div>
      {children}
    </div>
  );
};

export { DefinitionSection };
