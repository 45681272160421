import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import useContainer from './hook';

import LogoGray from '@/assets/images/logo-gray.svg?react';
import Logo from '@/assets/images/logo.svg?react';
import First from '@/assets/images/slider/1.svg?react';
import Second from '@/assets/images/slider/2.svg?react';
import Third from '@/assets/images/slider/3.svg?react';
import Fourth from '@/assets/images/slider/4.svg?react';
import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';

const Slider = () => {
  const {
    showSlider,
    scrollSnaps,
    emblaRef,
    selectedIndex,
    nextBtnEnabled,
    scrollNext,
    scrollTo,
    handleHideSlider,
  } = useContainer();
  const { t } = useTranslation();

  const sliderItems = [
    {
      id: 1,
      component: <First />,
      description: t('auth.no_more_cash_register'),
    },
    {
      id: 2,
      component: <Second />,
      description: t('auth.open_and_close_shift'),
    },
    {
      id: 3,
      component: <Third />,
      description: t('auth.issue_receipts_in_a_second'),
    },
    {
      id: 4,
      component: <Fourth />,
      description: t('auth.collect_X_and_Z_reports_in_real_time'),
    },
  ];

  if (!showSlider) return null;

  return (
    <div className="z-10 bg-white-second flex-grow basis-0 flex flex-col justify-start items-center h-screen absolute left-0 lg:bg-darkGrayBlue lg:relative w-full lg:w-auto">
      <div className="my-7 hidden lg:block">
        <Logo />
      </div>
      <div className="my-7 block lg:hidden">
        <LogoGray />
      </div>
      <div className="flex flex-col justify-center items-center flex-grow">
        <h4 className="max-w-56 text-xl text-black lg:text-white font-semibold lg:max-w-max text-center">
          {t('auth.welcome_to_online_cash_register')}
        </h4>
        <div className="my-7 overflow-hidden" ref={emblaRef}>
          <div className="w-72 h-44 lg:w-96 lg:h-64 flex">
            {sliderItems.map((item) => (
              <div className="relative flex-[0_0_100%] child:w-full child:h-full" key={item.id}>
                {item.component}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center list-none gap-2 mb-10">
          {scrollSnaps.map((_, index) => (
            <Button
              key={_}
              className={twMerge(
                '!w-2 h-2 flex after:bg-lightGray-second after:rounded-xl after:w-full after:h-full !p-0 transition-all delay-75 ease-in-out',
                index === selectedIndex && 'after:bg-lightGray-third !w-4',
              )}
              kind={ButtonKinds.secondary}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>

        {sliderItems.map(
          (item, index) =>
            index === selectedIndex && (
              <p
                className="max-w-56 lg:max-w-max text-darkGray lg:text-white text-lg text-center leading-5"
                key={item.id}
              >
                {item.description}
              </p>
            ),
        )}
        <div className="flex flex-col gap-3 mt-8 lg:hidden w-full">
          <Button
            onClick={nextBtnEnabled ? scrollNext : handleHideSlider}
            text="auth.next"
            className="!w-full"
          />
          <Button
            className="text-darkGray"
            kind={ButtonKinds.secondary}
            onClick={handleHideSlider}
            text="auth.skip"
          />
        </div>
      </div>
    </div>
  );
};

export { Slider };
