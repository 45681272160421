import { Link, useLocation } from 'react-router-dom';

import { LangChecker } from '../LangChecker';
import { UserInfo } from '../UserInfo';

import Logo from '@/assets/images/logo.svg?react';
import { ROUTES } from '@/constants/routes';

const HeaderWithoutShift = () => {
  const path = useLocation();

  return (
    <div className="bg-darkGray-second w-full top-0 left-0 py-4 text-white z-20 sticky hidden lg:block">
      <div className="max-w-6xl m-auto flex items-center justify-between gap-2">
        {path?.pathname === ROUTES.MAIN ? (
          <Logo />
        ) : (
          <Link to={ROUTES.MAIN}>
            <Logo />
          </Link>
        )}
        <div className="flex max-w-80 w-full items-center justify-between gap-2">
          <LangChecker />
          <UserInfo />
        </div>
      </div>
    </div>
  );
};

export { HeaderWithoutShift };
