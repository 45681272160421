import type { ReactNode } from 'react';

type Props = {
  title: string | ReactNode;
  value: string | number;
};

const DefinitionItem = ({ title, value }: Props) => {
  return (
    <dl className="flex justify-between items-center text-sm leading-4">
      <dt className="text-darkGray-third">{title}</dt>
      <dd className="text-black font-semibold">{value}</dd>
    </dl>
  );
};

export { DefinitionItem };
