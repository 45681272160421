import { useParams } from 'react-router-dom';

import { OperationConfirmInfo } from '@/views/shared/OperationConfirmInfo';

const Success = () => {
  const { idReport } = useParams();

  return <OperationConfirmInfo reportId={Number(idReport)} />;
};

export { Success };
