import { yupResolver } from '@hookform/resolvers/yup';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useLazyGetOfdListQuery, useSaveOfdSettingsMutation } from '@/store/settings/service';
import { setOfdList } from '@/store/settings/slice';
import type { OptionType } from '@/types/form';
import type { OfdSettingsForm } from '@/types/settings';

const useContainer = ({ onSave }: { onSave: () => void }) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const [getOfdList] = useLazyGetOfdListQuery();
  const [saveSettings, { isLoading }] = useSaveOfdSettingsMutation();

  const { kkmItem } = useAppSelector((state) => state.cashbox);
  const { ofdList } = useAppSelector((state) => state.settings);
  const [ofdOptions, setOfdOptions] = useState<OptionType<number>[]>([]);

  useEffect(() => {
    if (params?.idKkm && !ofdList?.length) {
      getOfdList(Number(params.idKkm))
        .unwrap()
        .then((result) => {
          dispatch(setOfdList(result?.Data || []));

          if (result?.Data) {
            const options = result.Data.map((ofd) => ({
              id: ofd.Id,
              value: ofd.Id,
              label: ofd.Name,
            }));
            setOfdOptions(options);
          }
        });
    }
  }, [dispatch, getOfdList, ofdList?.length, ofdOptions, params.idKkm]);

  const validationSchema = Yup.object().shape({
    rnm: Yup.number()
      .required(t('kassa_settings.ofd_error_rnm'))
      .typeError(t('kassa_settings.ofd_error_rnm')),
    kkmId: Yup.number()
      .required(t('kassa_settings.ofd_error_kkm'))
      .typeError(t('kassa_settings.ofd_error_kkm')),
    token: Yup.number()
      .required(t('kassa_settings.ofd_error_token'))
      .typeError(t('kassa_settings.ofd_error_token')),
  });

  const defaultOfd = useMemo(
    () => ofdOptions?.find((ofd) => ofd.id === kkmItem?.Ofd.Id),
    [kkmItem?.Ofd.Id, ofdOptions],
  );

  const defaultValues = useMemo(
    () => ({
      ofdValue: defaultOfd || null,
      rnm: Number(kkmItem?.Rnm) || 0,
      kkmId: kkmItem?.IdCPCR || 0,
      token: kkmItem?.TokenCPCR || 0,
    }),
    [defaultOfd, kkmItem?.IdCPCR, kkmItem?.Rnm, kkmItem?.TokenCPCR],
  );

  const methods = useForm<OfdSettingsForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    values: defaultValues,
  });

  const saveOfdSettings: SubmitHandler<OfdSettingsForm> = useCallback(
    (formValues) => {
      const formData = new FormData();
      formData.append('IdOfd', formValues?.ofdValue?.id.toString() ?? '0');
      formData.append('Rnm', formValues.rnm.toString());
      formData.append('IdCPCR', formValues.kkmId.toString());
      formData.append('TokenCPCR', formValues.token.toString());

      if (params?.idKkm) {
        saveSettings({ body: formData, idKkm: Number(params.idKkm) })
          .unwrap()
          .then((res) => {
            if (res.Status === StatusCodes.OK) {
              onSave();
              toast.success(t('kassa_settings.ofd_settings_saved'), {
                theme: 'dark',
              });
            }
          })
          .catch((e) => {
            console.error(e);
            toast.warning(t('main.error'), {
              theme: 'dark',
            });
          });
      }
    },
    [onSave, params.idKkm, saveSettings, t],
  );

  return { t, methods, isLoading, ofdOptions, saveOfdSettings };
};

export default useContainer;
