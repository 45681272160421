import { format } from 'date-fns';

import { PERIOD_TYPES } from '@/constants/date';
import { REPORT_DATE_TYPE, REPORT_TYPES } from '@/constants/reports';
import type { DatePeriodFilter, DatePeriodProp, DateToString } from '@/types/date';

export const getCurrentDateForFilter = () => {
  const today = new Date();

  const month = format(today, 'MM');
  const year = format(today, 'yyyy');
  const days = format(today, 'dd');

  const weekdayFromToday = new Date(today);
  const yearFromToday = new Date(today);
  weekdayFromToday.setDate(Number(days) - 7);
  yearFromToday.setFullYear(Number(year) - 2);

  return {
    today,
    weekdayFromToday,
    yearFromToday,
    days,
    month,
    year,
  };
};

export const getTimeZone = () => {
  const timeZoneOffset = -new Date().getTimezoneOffset() / 60;

  return timeZoneOffset >= 0
    ? `+${String(timeZoneOffset).padStart(2, '0')}:00`
    : `-${String(-timeZoneOffset).padStart(2, '0')}:00`;
};

export const getDataToString = ({ id, date, type }: DateToString) => {
  const month = format(date, 'MM');
  const year = format(date, 'yyyy');
  const days = format(date, 'dd');

  const timeZoneOffset = -new Date().getTimezoneOffset() / 60;
  const timeZone =
    timeZoneOffset >= 0
      ? `+${String(timeZoneOffset).padStart(2, '0')}:00`
      : `-${String(-timeZoneOffset).padStart(2, '0')}:00`;

  let time = `23:59:59${timeZone}`;

  if (id === REPORT_DATE_TYPE.DATE_FROM) {
    time = `00:00:00${timeZone}`;
  }

  if (type === REPORT_TYPES.Z_REPORT_ARCHIVE) {
    let time2 = '00:00:00';

    if (id === REPORT_DATE_TYPE.DATE_TO) {
      time2 = '23:59:59';
    }

    return `${year}-${month}-${days}T${time2}`;
  }

  return `${year}-${month}-${days}T${time}`;
};

export const getFormattedFullDate = (date: string) => {
  const currentDate = new Date(date);

  return format(currentDate, 'dd.MM.yyyy HH:mm:ss');
};

export const getFormattedDate = (date: Date) => {
  return format(date, 'dd.MM.yyyy');
};

export const getRangePeriod = ({ type, range }: DatePeriodProp) => {
  const currentData = getCurrentDateForFilter();
  const { today, days, month, weekdayFromToday } = currentData;
  const currentDay = new Date();
  let date: DatePeriodFilter = {
    from: new Date(),
    to: new Date(),
  };

  switch (type) {
    case PERIOD_TYPES.DEFAULT:
      date = {
        from: weekdayFromToday,
        to: today,
      };
      break;
    case PERIOD_TYPES.YESTERDAY:
      today.setDate(Number(days) - 1);
      date = {
        from: today,
        to: today,
      };
      break;
    case PERIOD_TYPES.THREE_DAYS:
      today.setDate(Number(days) - 2);
      date = {
        from: today,
        to: new Date(),
      };
      break;
    case PERIOD_TYPES.WEEK:
      today.setDate(Number(days) - 7);
      date = {
        from: today,
        to: new Date(),
      };
      break;
    case PERIOD_TYPES.MONTH:
      today.setDate(currentDay.getDate());
      today.setMonth(currentDay.getMonth() - 1);
      date = {
        from: today,
        to: new Date(),
      };
      break;
    case PERIOD_TYPES.QUARTER:
      if (Number(month) <= 2) {
        today.setDate(currentDay.getDate());
        today.setMonth(currentDay.getMonth() - 3);
        today.setFullYear(currentDay.getFullYear() - 1);
      } else {
        today.setDate(currentDay.getDate());
        today.setMonth(currentDay.getMonth() - 3);
      }

      date = {
        from: today,
        to: new Date(),
      };
      break;
    case PERIOD_TYPES.HALF_YEAR:
      if (Number(month) <= 5) {
        today.setDate(currentDay.getDate());
        today.setMonth(currentDay.getMonth() - 6);
        today.setFullYear(currentDay.getFullYear() - 1);
      } else {
        today.setDate(currentDay.getDate());
        today.setMonth(currentDay.getMonth() - 6);
      }

      date = {
        from: today,
        to: new Date(),
      };
      break;

    case PERIOD_TYPES.YEAR:
      today.setDate(currentDay.getDate());
      today.setFullYear(currentDay.getFullYear() - 1);
      date = {
        from: today,
        to: new Date(),
      };
      break;
    case PERIOD_TYPES.PERIOD:
      date = {
        from: range?.startDate || new Date(),
        to: range?.endDate || new Date(),
      };
      break;
    default:
      date = {
        from: new Date(),
        to: new Date(),
      };
  }

  return {
    date: { ...date },
    dateString: {
      from: format(date.from, 'dd.MM.yyyy'),
      to: format(date.to, 'dd.MM.yyyy'),
    },
    dateISOstring: {
      FromDate: `${format(date.from, 'yyyy-MM-dd')}T${format(
        date.from,
        `00:00:00`,
      )}${getTimeZone()}`,
      ToDate: `${format(date.to, 'yyyy-MM-dd')}T${format(date.to, `HH:mm:ss`)}${getTimeZone()}`,
    },
  };
};
