import { useEffect, useState } from 'react';

import { DuplicateZReportPage } from '../Reports/ZReport/DuplicatePage';

import { useNavigation, useAppDispatch, useAppSelector, useShift } from '@/hooks';
import { useLazyGetUidQuery } from '@/store/auth/service';
import { setSelectedZReportsData } from '@/store/reports/slice';
import { useCloseShiftMutation } from '@/store/shift/service';
import { getError } from '@/utils/getError';

const CloseShift = () => {
  const dispatch = useAppDispatch();
  const { goToMainPage } = useNavigation();
  const [getUid] = useLazyGetUidQuery();
  const [closeShift, { isLoading }] = useCloseShiftMutation();
  const { openedShift } = useAppSelector((state) => state.shift);
  const { user } = useAppSelector((state) => state.user);
  const [error, setError] = useState('');
  const { setUserAndShiftStatus } = useShift();

  useEffect(() => {
    if (!openedShift) {
      return;
    }

    const getUidAndCloseShift = async () => {
      try {
        const uidResponse = await getUid(openedShift.IdKkm).unwrap();

        if (uidResponse.Data.Uid && openedShift) {
          closeShift({
            idKkm: openedShift.IdKkm,
            idShift: openedShift.Id,
            uid: uidResponse.Data.Uid,
          })
            .unwrap()
            .then((res) => {
              dispatch(setSelectedZReportsData(res?.Data));

              if (!user) {
                return;
              }

              setUserAndShiftStatus(openedShift.IdKkm, user?.Id);
            })
            .catch((e) => {
              setError(getError(e));
            });
        }
      } catch (e) {
        console.error(e);
      }
    };
    getUidAndCloseShift();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openedShift, user]);

  return (
    <DuplicateZReportPage
      title="close_shift.z_report"
      onBack={goToMainPage}
      error={error}
      isLoading={isLoading}
    />
  );
};

export { CloseShift };
