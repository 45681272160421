import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigation } from './useNavigate';

import { OPERATION_TYPES } from '@/constants/app';
import { ERROR_DEFAULT_STATE, OperationType } from '@/constants/operations';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  addPosition,
  clearPositions,
  setOperationErrors,
  setOperationInfo,
  setSN,
} from '@/store/operations/slice';
import { useLazyGetOperationDetailsQuery } from '@/store/reports/service';
import { getPositionForPreCheck } from '@/utils/operations';

const useOperations = () => {
  const dispatch = useAppDispatch();
  const { openedShift } = useAppSelector((state) => state.shift);
  const { t } = useTranslation();
  const { goToRefundSale, goToRefundPurchase } = useNavigation();

  const [getOperationDetailsQuery] = useLazyGetOperationDetailsQuery();

  const clearOperationStore = useCallback(() => {
    dispatch(clearPositions());
    dispatch(setSN(''));
    dispatch(setOperationInfo(null));
    dispatch(setOperationErrors(ERROR_DEFAULT_STATE));
  }, [dispatch]);

  const getOperationDetails = useCallback(
    async (id: string, opType: (typeof OperationType)[keyof typeof OperationType]) => {
      if (!id || !openedShift) return;

      clearOperationStore();
      await getOperationDetailsQuery(id)
        .unwrap()
        .then((result) => {
          const operationData = result.Data.Document;
          dispatch(clearPositions());

          if (operationData.IdTypeDocument === opType) {
            dispatch(setOperationInfo(result.Data));
            dispatch(setSN(id));
            // add positions to store for precheck view
            operationData.Positions.forEach((position) => {
              dispatch(
                addPosition(
                  getPositionForPreCheck(
                    position,
                    operationData.IdDomain,
                    OperationType.PURCHASE_REFUND,
                  ),
                ),
              );
            });
          } else if (
            operationData.IdTypeDocument === OPERATION_TYPES.SALE.ID &&
            opType === OPERATION_TYPES.PURCHASE.ID
          ) {
            // если операция является покупкой, а страница возврата продажи
            dispatch(
              setOperationErrors({
                text: t('other.sale_refund_warning_text'),
                btnName: t('other.go_to_sale_refund'),
                btnOnClick: () => goToRefundSale(openedShift.IdKkm, Number(id)),
              }),
            );
          } else if (
            operationData.IdTypeDocument === OPERATION_TYPES.PURCHASE.ID &&
            opType === OPERATION_TYPES.SALE.ID
          ) {
            // если операция является продажой, а страница возврата покупки
            dispatch(
              setOperationErrors({
                text: t('other.purchase_refund_warning_text'),
                btnName: t('other.go_to_purchase_refund'),
                btnOnClick: () => goToRefundPurchase(openedShift.IdKkm, Number(id)),
              }),
            );
          } else {
            dispatch(setOperationErrors(ERROR_DEFAULT_STATE));
          }
        })
        .catch(() => {
          dispatch(setOperationErrors({ text: t('other.operation_can_not_be_refunded') }));
        });
    },
    [
      clearOperationStore,
      dispatch,
      getOperationDetailsQuery,
      goToRefundPurchase,
      goToRefundSale,
      openedShift,
      t,
    ],
  );

  return { getOperationDetails, clearOperationStore };
};

export default useOperations;
