import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { CustomizedTooltip } from '../CustomizedTooltip';

import type { ChartOperation } from '@/types/cashbox';

type Props = {
  operations: ChartOperation[];
};

const LineChart = ({ operations }: Props) => {
  return (
    <ResponsiveContainer width="100%" height={295}>
      <AreaChart
        data={operations}
        margin={{
          top: 10,
          right: 10,
        }}
      >
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#749BFF" stopOpacity={0.1} />
            <stop offset="99%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#4CCDFF" stopOpacity={0.1} />
            <stop offset="99%" stopColor="#FFFFFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid height={1} stroke="#F5F5F5" horizontal />
        <XAxis dataKey="Time" tickLine={false} />
        <YAxis tickLine={false} />
        <Tooltip content={<CustomizedTooltip />} />
        <Area
          type="monotone"
          dataKey="SellTotal"
          stroke="#749BFF"
          fillOpacity={1}
          fill="url(#colorPv)"
          activeDot={{ fill: '#FFFFFF', stroke: '#749BFF', strokeWidth: 2, r: 8 }}
        />
        <Area
          type="monotone"
          dataKey="RefundTotal"
          stroke="#4CCDFF"
          fillOpacity={1}
          fill="url(#colorUv)"
          activeDot={{ fill: '#FFFFFF', stroke: '#4CCDFF', strokeWidth: 2, r: 8 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export { LineChart };
