import { useTranslation } from 'react-i18next';

import { formatAmount } from '@/utils';

type Props = {
  Icon: any;
  title: string;
  amount: number;
};
const BalanceItem = ({ Icon, title, amount }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 px-3 justify-between items-center text-xs">
      <div className="flex gap-1">
        <Icon className="w-3" />
        <span>{t(title)}</span>
      </div>
      <div className="font-semibold">{formatAmount(amount, true)}</div>
    </div>
  );
};

export { BalanceItem };
