import { FilterPanel } from '../FilterPanel';

import useContainer from './hook';
import { ZReportList } from './List';

import { EXPORT_FILE_TYPES } from '@/constants/export';
import { Loading } from '@/views/shared/Loading';

const ZReport = () => {
  const { isLoading, zReportList, t } = useContainer();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <FilterPanel
        withSearch={false}
        totalCount={zReportList?.length || 0}
        exportFileName={t('report.z_report_filename')}
        exportType={EXPORT_FILE_TYPES.Z_REPORT}
      />
      <ZReportList />
    </div>
  );
};

export { ZReport };
