import type { ChangeEvent } from 'react';

type Props = {
  label?: string;
  name: string;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

const Checkbox = ({ name, checked, label, onChange }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-5 h-5 flex items-center justify-center">
        <input
          onChange={onChange}
          checked={checked}
          name={name}
          id={name}
          type="checkbox"
          className="appearance-none flex-shrink-0 checked:bg-checked-icon w-5 h-5 bg-center bg-no-repeat bg-[length:14px_14px] checked:border-none rounded border-lightGray-second border cursor-pointer checked:bg-blue hover:border-blue transition-all"
        />
      </div>

      <label
        className="text-[13px] md:text-sm font-normal text-black leading-4 md:leading-5"
        htmlFor={name}
      >
        {label}
      </label>
    </div>
  );
};

export { Checkbox };
