import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type {
  SectionResponse,
  CashierList,
  KassaSettingsSaveRequest,
  OfdList,
  CashierSearchRequest,
  UpdateCashierStatusRequest,
  AddSettingsRequest,
  AddSectionForm,
  AddArticleRequest,
  ImportArticleFileRequest,
  ImportArticleFileResponse,
} from '@/types';
import type { ArticleItem } from '@/types/article';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

export const settingsService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    saveKassaSettings: build.mutation<ApiResponse<string>, KassaSettingsSaveRequest>({
      query: ({ body, idKkm }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_KKM_BY_ID, { idKkm }),
        method: 'POST',
        body,
      }),
    }),
    saveOfdSettings: build.mutation<ApiResponse<string>, KassaSettingsSaveRequest>({
      query: ({ body, idKkm }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.OFD_SETTINGS, { idKkm }),
        method: 'POST',
        body,
      }),
    }),
    getOfdList: build.query<ApiResponse<OfdList[]>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.OFD_SETTINGS, { idKkm }),
    }),
    getCashierList: build.query<ApiResponse<CashierList[]>, number>({
      query: (idKkm) => `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_CASHIERS, { idKkm })}?all=1`,
    }),
    searchCashiers: build.query<ApiResponse<CashierList[]>, CashierSearchRequest>({
      query: ({ idKkm, searchValue }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_CASHIERS, {
          idKkm,
        })}?all=1&search=${searchValue}`,
    }),
    updateCashierStatus: build.mutation<ApiResponse<string>, UpdateCashierStatusRequest>({
      query: ({ idKkm, id }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.UPDATE_CASHIER_STATUS, { idKkm, id })}?all=1`,
    }),
    getSectionsList: build.query<ApiResponse<SectionResponse>, number>({
      query: (idKkm) => `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SECTIONS, { idKkm })}?all=1`,
    }),
    searchSections: build.query<ApiResponse<SectionResponse>, CashierSearchRequest>({
      query: ({ idKkm, searchValue }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SECTIONS, {
          idKkm,
        })}?all=1&search=${searchValue}`,
    }),
    updateSectionStatus: build.mutation<ApiResponse<string>, UpdateCashierStatusRequest>({
      query: ({ idKkm, id }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.UPDATE_SECTION_STATUS, { idKkm, id })}?all=1`,
    }),
    addSection: build.mutation<ApiResponse<string>, AddSettingsRequest<AddSectionForm>>({
      query: ({ idKkm, body }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SECTIONS, { idKkm }),
        method: 'POST',
        body,
      }),
    }),
    getArticlesList: build.query<ApiResponse<ArticleItem[]>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SETTINGS_ARTICLES, { idKkm }),
    }),
    searchArticles: build.query<ApiResponse<ArticleItem[]>, CashierSearchRequest>({
      query: ({ idKkm, searchValue }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SETTINGS_ARTICLES, {
          idKkm,
        })}?search=${searchValue}`,
    }),
    addArticle: build.mutation<ApiResponse<string>, AddSettingsRequest<AddArticleRequest>>({
      query: ({ idKkm, body }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_SETTINGS_ARTICLES, { idKkm }),
        method: 'POST',
        body,
      }),
    }),
    importArticleFile: build.mutation<
      ApiResponse<ImportArticleFileResponse>,
      ImportArticleFileRequest
    >({
      query: ({ idKkm, body, check }) => ({
        url: `${buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_ARTICLES, { idKkm })}?check=${check}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSaveKassaSettingsMutation,
  useSaveOfdSettingsMutation,
  useGetOfdListQuery,
  useLazyGetOfdListQuery,
  useGetCashierListQuery,
  useLazyGetCashierListQuery,
  useLazySearchCashiersQuery,
  useUpdateCashierStatusMutation,
  useGetSectionsListQuery,
  useLazySearchSectionsQuery,
  useUpdateSectionStatusMutation,
  useAddSectionMutation,
  useGetArticlesListQuery,
  useLazySearchArticlesQuery,
  useAddArticleMutation,
  useImportArticleFileMutation,
} = settingsService;
