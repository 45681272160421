import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useGetReceiptQuery } from '@/store/operations/service';
import { getBase64Img } from '@/utils';
import { Loading } from '@/views/shared/Loading';

const Showcheck = () => {
  const location = useLocation();

  const { data, isLoading } = useGetReceiptQuery(location.search.slice(1));

  const { t } = useTranslation();

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <div className="w-[280px] rounded-lg drop-shadow overflow-hidden p-2 bg-white text-darkRed font-medium">
        {t('duplicate.incorrect_link')}
      </div>
    );
  }

  return (
    <div className="w-[280px] rounded-lg drop-shadow overflow-hidden p-2 bg-white">
      <div className="font-semibold text-left text-base border-b border-b-black/10 pb-2">
        {t('duplicate.duplicate_receipt')}:
      </div>
      <img src={getBase64Img(data.Data)} alt="receipt" />
    </div>
  );
};

export { Showcheck };
