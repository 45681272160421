import { StatusCodes } from 'http-status-codes';

import { ERRORS_STATUS } from '@/constants/errors';

export const getError = (error: any) => {
  if (error.status) {
    if (error.status > ERRORS_STATUS.NO_FISCAL_CONNECTION) {
      return 'error.no_fiscal_connection';
    }

    if (error.status === StatusCodes.FORBIDDEN) {
      return 'error.no_access_kassa';
    }

    if (error.status === StatusCodes.GONE) {
      return 'error.shift_closed';
    }

    if (error.status === StatusCodes.TOO_MANY_REQUESTS) {
      return 'other.operation_failed_device_blocking';
    }
  } else if (error?.response) {
    if (error.response.status > ERRORS_STATUS.NO_FISCAL_CONNECTION) {
      return 'error.no_fiscal_connection';
    }

    if (error.response.status === StatusCodes.FORBIDDEN) {
      return 'error.no_access_kassa';
    }

    if (error.response.status === StatusCodes.GONE) {
      return 'error.shift_closed';
    }

    if (error.response.status === StatusCodes.TOO_MANY_REQUESTS) {
      return 'other.operation_failed_device_blocking';
    }
  }

  return 'error.something_went_wrong';
};
