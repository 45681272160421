import type { ExtFile } from '@dropzone-ui/react';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FileExcel from '@/assets/icons/fill-excel-2-fill-big.svg?react';
import { IMPORT_TYPE } from '@/constants/import';
import { ButtonKinds } from '@/types/button';
import { getFileFormattedSize } from '@/utils/file';
import { twMergeClsx } from '@/utils/tw-with-clsx';
import { Button } from '@/views/shared/Button';
import { SpinnerIcon } from '@/views/shared/Spinner';

type Props = {
  files: ExtFile[];
  isLoading: boolean;
  errorText: string;
  deleteFile: () => void;
  onCheck: (check: number) => void;
};

const FileItem = ({ files, isLoading, errorText, deleteFile, onCheck }: Props) => {
  const { t } = useTranslation();
  useEffect(() => {
    onCheck(IMPORT_TYPE.CHECK);
  }, [onCheck]);

  if (isLoading) {
    return (
      <div>
        <SpinnerIcon className="stroke-blue w-6 h-6" />
        <p>{t('other.uploading_your_file')}</p>
      </div>
    );
  }

  return (
    <div>
      <p className="text-darkGray mb-2">{t('other.uploaded_files')}</p>
      <div
        className={twMergeClsx(
          'flex gap-3 rounded-xl px-4 py-2 items-center justify-between bg-lightGray-five',
          !!errorText && 'bg-lightRed',
        )}
      >
        <FileExcel className="w-5 h-7 fill-green" />
        <div className="flex-grow">
          <p className="font-semibold text-sm">{files[0].file?.name}</p>
          <p className="text-darkGray">{getFileFormattedSize(files[0].file?.size || 0)}</p>
        </div>
        <Button
          onClick={deleteFile}
          Icon={<XCircleIcon className="w-6 text-darkRed" />}
          kind={ButtonKinds.iconOnly}
          className="w-12"
        />
      </div>

      {!!errorText && (
        <div className="border border-lightGray-five rounded-lg p-3 mt-3">
          <div className="text-darkRed flex gap-2 whitespace-pre-line items-center">
            <span className="flex-shrink">
              <ExclamationCircleIcon className="w-4 text-darkRed" />
            </span>
            {errorText}
          </div>
        </div>
      )}
    </div>
  );
};

export { FileItem };
