import { ErrorModal } from '../modules/ErrorModal';
import { OperationHead } from '../modules/Head';
import { Pays } from '../modules/Pays';
import { SearchReceipt } from '../modules/SearchReceipt';
import { PreCheck } from '../PreCheck';

import useContainer from './hook';

import { OperationType } from '@/constants/operations';

const SaleRefund = () => {
  const { id, errors, handleCloseErrorModal } = useContainer();

  return (
    <div>
      <OperationHead />
      <ErrorModal onClose={handleCloseErrorModal} isOpen={!!errors.text} error={errors} />
      <div className="flex flex-1 lg:flex-row flex-col items-start justify-center gap-6">
        {!id && <SearchReceipt operationType={OperationType.SALE} />}
        <PreCheck operationType={OperationType.REFUND} />
        <Pays operationType={OperationType.REFUND} />
      </div>
    </div>
  );
};

export { SaleRefund };
