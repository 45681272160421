import { useTranslation } from 'react-i18next';

import { ErrorModal } from '../modules/ErrorModal';
import { OperationHead } from '../modules/Head';
import { Pays } from '../modules/Pays';
import { SearchReceipt } from '../modules/SearchReceipt';
import { PreCheck } from '../PreCheck';

import useContainer from './hook';

import { OperationType } from '@/constants/operations';

const PurchaseRefund = () => {
  const { id, errors, handleCloseErrorModal } = useContainer();
  const { t } = useTranslation();

  return (
    <div>
      <OperationHead title={t('main.refund_of_sale')} />
      <ErrorModal onClose={handleCloseErrorModal} isOpen={!!errors.text} error={errors} />
      <div className="flex flex-1 lg:flex-row flex-col items-start justify-center gap-6">
        {!id && <SearchReceipt operationType={OperationType.PURCHASE_REFUND} />}
        <PreCheck operationType={OperationType.PURCHASE_REFUND} />
        <Pays operationType={OperationType.PURCHASE_REFUND} />
      </div>
    </div>
  );
};

export { PurchaseRefund };
