import type { ExtFile } from '@dropzone-ui/react';
import { Dropzone } from '@dropzone-ui/react';
import { useTranslation } from 'react-i18next';

import File from '@/assets/images/file-dropzone.svg?react';
import { IMPORT_ARTICLE_FILE_MAX_SIZE } from '@/constants/import';

type Props = {
  errorText: string;
  files: ExtFile[];
  onUpdate: (file: ExtFile[]) => void;
};
const UploadFile = ({ errorText, files, onUpdate }: Props) => {
  const { t } = useTranslation();

  return (
    <Dropzone
      onChange={onUpdate}
      value={files}
      localization="RU-ru"
      maxFiles={1}
      maxFileSize={IMPORT_ARTICLE_FILE_MAX_SIZE}
      accept=".xls, .xlsx"
      label={t('kassa_settings.choose_file')}
      header={false}
      footer={false}
      minHeight="260px"
    >
      <div className="text-center text-darkGray text-sm">
        <File className="m-auto" />
        {errorText && <div className="text-darkRed">{errorText}</div>}
        <div className="text-black font-semibold text-base mt-1">
          {t('kassa_settings.choose_file')}
        </div>
        <div>{t('other.file_size_limit')}</div>
      </div>
    </Dropzone>
  );
};

export { UploadFile };
