import type { ExtFile } from '@dropzone-ui/react';
import { StatusCodes } from 'http-status-codes';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { IMPORT_ARTICLE_FILE_MAX_SIZE, IMPORT_TYPE } from '@/constants/import';
import { useAppSelector } from '@/hooks/useRedux';
import { useImportArticleFileMutation } from '@/store/settings/service';

export type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

const useContainer = ({ onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { kkmItem } = useAppSelector((state) => state.cashbox);

  const [importArticleQuery, { isLoading }] = useImportArticleFileMutation();
  const [files, setFiles] = useState<ExtFile[]>([]);
  const [fileError, setFileError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onUpdateFile = useCallback(
    (filesData: ExtFile[]) => {
      if (filesData[0]?.size) {
        if (filesData[0]?.size > IMPORT_ARTICLE_FILE_MAX_SIZE) {
          setErrorText(t('other.file_too_large'));
          setFileError(true);
        } else setFiles(filesData);
      }
    },
    [t],
  );

  const checkUploadedFile = useCallback(
    (check: number) => {
      if (!files[0]?.file || !kkmItem) return;

      const formData = new FormData();
      formData.append('file', files[0].file);
      formData.append('check', check.toString());

      importArticleQuery({ body: formData, idKkm: kkmItem.Id, check })
        .unwrap()
        .then((result) => {
          if (check === IMPORT_TYPE.CHECK) {
            if (result.Status === StatusCodes.OK && result.Data.isValid) {
              setFileError(false);
              setErrorText('');
            } else if (result.Data?.reportUnits) {
              setFileError(true);
              const messages = result.Data?.reportUnits.map((report) => report.reportMessage);
              setErrorText(messages.join('\n'));
            } else {
              setFileError(true);
              setErrorText(result.Data?.message || t('kassa_settings.loading_error'));
            }
          } else if (result.Status === StatusCodes.OK) {
            setFiles([]);
            setFileError(false);
            setErrorText('');
            toast.success(`${t('other.successfully_imported_items')}: ${result?.Data?.count}`, {
              theme: 'dark',
            });
            onClose();
            onSuccess();
          }
        })
        .catch((e) => {
          setFileError(true);
          setErrorText(e?.data?.Data?.message || t('kassa_settings.loading_error'));
        });
    },
    [files, importArticleQuery, kkmItem, onClose, onSuccess, t],
  );

  const deleteFile = useCallback(() => {
    setFileError(false);
    setErrorText('');
    setFiles([]);
  }, []);

  return {
    t,
    files,
    fileError,
    errorText,
    isLoading,
    deleteFile,
    onUpdateFile,
    checkUploadedFile,
    setFiles,
  };
};

export default useContainer;
