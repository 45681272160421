import { ArrowRightStartOnRectangleIcon, UserIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { setUser } from '@/store/user/slice';
import { clearAuthData } from '@/utils';

const MobileHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const logout = () => {
    clearAuthData(() => {
      dispatch(setUser(null));
      navigate(ROUTES.SIGNIN);
    });
  };

  if (user) {
    return (
      <header className="flex items-stretch bg-white sticky top-0 z-50">
        <div className="flex flex-grow p-3 gap-3">
          <div className="w-8 h-8 flex items-center justify-center rounded-full bg-lightGray-five/50">
            <UserIcon className="w-5" />
          </div>

          <dl>
            <dt className="text-base leading-4 font-semibold">{user?.Name}</dt>
            <dd className="text-darkGray-five">{t('main.cashier')}</dd>
          </dl>
        </div>

        <button
          onClick={logout}
          type="button"
          className="p-3 w-[60px] border-l border-lightGray-second/50 active:bg-lightGray-fourth"
        >
          <ArrowRightStartOnRectangleIcon className="w-6 h-6 mx-auto text-darkGray-five" />
        </button>
      </header>
    );
  }

  return null;
};

export { MobileHeader };
