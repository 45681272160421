import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { Icon } from '../../Icon';
import { Error } from '../Error';
import { Label } from '../Label';

import type { AmountInputFormPropsType } from '@/types/form';
import { twMergeClsx } from '@/utils/tw-with-clsx';

const CountInput = ({
  name,
  control,
  label,
  decimalScale = 2,
  decimalSeparator = '.',
  fixedDecimalScale = false,
  placeholder,
  errorText = '',
  className,
  classNameInput,
  ...rest
}: AmountInputFormPropsType) => {
  const {
    formState: { errors },
  } = useFormContext();

  const getLabel = useCallback(() => {
    if (rest['aria-invalid']) {
      return <Error text={errors.amount?.message?.toString() || errorText} />;
    }

    return <Label name={name} text={label} />;
  }, [errorText, errors.amount?.message, label, name, rest]);

  return (
    <div className="w-full">
      {getLabel()}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <div
            className={twMergeClsx(
              'bg-white border border-solid border-lightGray-second rounded-xl flex',
              className,
            )}
          >
            <button
              className="border-r border-lightGray-second outline-none w-11 flex-shrink-0 disabled:opacity-35"
              type="button"
              disabled={value === 1}
              onClick={() => onChange(value - 1)}
            >
              <Icon type="minus" className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
            </button>
            <NumericFormat
              onBlur={onBlur}
              onChange={onChange}
              thousandSeparator=" "
              decimalSeparator={decimalSeparator}
              decimalScale={decimalScale}
              fixedDecimalScale={fixedDecimalScale}
              allowNegative
              id={name}
              placeholder={placeholder}
              value={value}
              className={twMergeClsx(
                'focus:border-blue px-4 py-3 w-full h-11 outline-none',
                classNameInput,
              )}
              {...rest}
            />
            <button
              className="border-l border-lightGray-second outline-none w-11 flex-shrink-0"
              type="button"
              onClick={() => onChange(value + 1)}
            >
              <Icon type="plus" className="w-[18px] h-[18px] fill-darkGray-third mx-auto" />
            </button>
          </div>
        )}
      />
    </div>
  );
};

export { CountInput };
