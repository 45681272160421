import { FilterPanel } from '../FilterPanel';

import useContainer from './hook';
import { OperationsList } from './List';

import { EXPORT_FILE_TYPES } from '@/constants/export';
import { Loading } from '@/views/shared/Loading';

const Operations = () => {
  const { reportList, isLoading, searchValue, t, handleSearchOperations } = useContainer();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <FilterPanel
        totalCount={reportList?.length ?? 0}
        onSearch={handleSearchOperations}
        searchValue={searchValue}
        exportFileName={t('report.ops_report_filename')}
        exportType={EXPORT_FILE_TYPES.OPERATIONS_REPORT}
      />
      <OperationsList />
    </div>
  );
};

export { Operations };
