import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { AuthRequest, Token, Uid } from '@/types/auth';
import type { ApiResponse } from '@/types/queries';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const authService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    auth: build.mutation<ApiResponse<Token>, AuthRequest>({
      query: (data) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SIGN_IN),
        method: 'POST',
        body: data,
      }),
    }),
    getUid: build.query<ApiResponse<Uid>, number>({
      query: (idKkm) => buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_UID, { idKkm }),
    }),
  }),
});

export const { useAuthMutation, useGetUidQuery, useLazyGetUidQuery } = authService;
