import { ArrowLeftIcon, PrinterIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { Loading } from '../Loading';

import useContainer from './hook';

import { REPORT_SEND_TYPES } from '@/constants';
import { ButtonKinds } from '@/types/button';
import { getBase64Img } from '@/utils/file';
import { EmailForm } from '@/views/pages/Reports/Operations/DuplicateModal/EmailForm';
import { WAppForm } from '@/views/pages/Reports/Operations/DuplicateModal/WAForm';
import { Button } from '@/views/shared/Button';
import { CustomTabs } from '@/views/shared/Tabs';

type Props = {
  reportId: number;
  isModal?: boolean;
  onClose?: () => void;
};

const OperationConfirmInfo = ({ reportId, isModal, onClose }: Props) => {
  const {
    activeTabId,
    isLoading,
    reportDetails,
    t,
    printRef,
    printButtonText,
    setActiveTabId,
    printReceipt,
    backToShift,
  } = useContainer({
    reportId,
  });

  const tabList = [
    {
      id: REPORT_SEND_TYPES.EMAIL,
      label: 'Email',
      Component: <EmailForm id={reportId} />,
    },
    {
      id: REPORT_SEND_TYPES.WHATAPP,
      label: 'WhatsApp',
      Component: <WAppForm link={reportDetails.link} id={reportId} />,
    },
  ];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex gap-4 flex-col lg:flex-row justify-center">
      <div className="rounded-lg w-[360px] lg:w-64 bg-white p-3 order-2 lg:order-1 h-60 lg:h-auto overflow-y-scroll lg:overflow-visible">
        <img
          ref={printRef}
          src={getBase64Img(reportDetails.img)}
          className="w-[360px]"
          alt="check"
        />
      </div>
      <div className="rounded-lg bg-white p-3 w-[360px] h-max order-1 lg:order-2">
        <div className="flex justify-between items-center border-b border-lightGray p-1 pt-0">
          <h3 className="font-semibold text-lg">
            {isModal ? t('duplicate.duplicate_receipt') : t('receipt.operation_successful')}
          </h3>
          {isModal && (
            <Button
              kind={ButtonKinds.iconOnly}
              Icon={<XMarkIcon className="w-5" />}
              onClick={onClose}
              className="w-auto m-0"
            />
          )}
        </div>
        <div className="my-3">
          <CustomTabs tabList={tabList} activeTabId={activeTabId} onChange={setActiveTabId} />
        </div>
        <div className="border-t border-lightGray pt-3 mb-3">
          <Button
            kind={ButtonKinds.secondary}
            onClick={printReceipt}
            Icon={<PrinterIcon className="w-5" />}
            text={printButtonText}
            className="w-full"
          />
          {!isModal && (
            <Button
              kind={ButtonKinds.secondary}
              onClick={backToShift}
              Icon={<ArrowLeftIcon className="w-5" />}
              text={t('receipt.return_to_cash_register')}
              className="w-full mt-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { OperationConfirmInfo };
