import { Capacitor } from '@capacitor/core';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { useAppSelector } from '@/hooks/useRedux';
import { useLazyGetUidQuery } from '@/store/auth/service';
import { useLazyGetXReportQuery } from '@/store/reports/service';
import { formatAmount } from '@/utils/format-amount';
import { renderAddress } from '@/utils/render-address';
import { Button } from '@/views/shared/Button';
import { DefinitionItem } from '@/views/shared/Definition/DefinitionItem';
import { DefinitionList } from '@/views/shared/Definition/DefinitionList';
import { DisclosureCustom } from '@/views/shared/Disclosure';
import { Icon } from '@/views/shared/Icon';
import { MobileAppDownloadFile } from '@/views/shared/MobileAppDownloadFile';
import { SpinnerIcon } from '@/views/shared/Spinner';

const XReport = () => {
  const { user } = useAppSelector((state) => state.user);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const [getXReport, { data, isLoading }] = useLazyGetXReportQuery();
  const [getUid] = useLazyGetUidQuery();
  const { shift } = useAppSelector((state) => state.shift);
  const { kkmId } = useAppSelector((state) => state.cashbox);

  const { t } = useTranslation();

  useEffect(() => {
    if (!shift?.Id) {
      return;
    }

    const loadXReport = async () => {
      try {
        const uidResponse = await getUid(shift.IdKkm).unwrap();

        if (uidResponse) {
          await getXReport({
            idShift: shift.Id,
            uid: uidResponse.Data.Uid,
          }).unwrap();
        }
      } catch (e) {
        console.log(e);
      }
    };

    loadXReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkmId, shift]);

  if (isLoading) {
    return (
      <div className="text-blue w-full h-full text-base flex justify-center items-center gap-2 mt-4">
        <SpinnerIcon className="stroke-blue w-6 h-6" />
        <div>{t('main.loading')}</div>
      </div>
    );
  }

  if (!data) {
    return (
      <p className="mt-4 text-darkGray text-center">{t('other.no_open_shift_for_x_report')}</p>
    );
  }

  return (
    <div className="flex flex-col-reverse gap-2 lg:grid grid-cols-[1fr,250px] mt-4 items-start">
      <div ref={printRef} className="flex gap-2 lg:gap-4 flex-col lg:flex-row flex-1 w-full">
        <div className="bg-white rounded-lg overflow-hidden w-full">
          <DisclosureCustom
            btnClassName="px-3 py-2 lg:border-b border-lightGray-second lg:text-darkGray-five lg:font-semibold"
            btnName={t('report.main_information')}
          >
            <DefinitionList>
              <DefinitionItem
                title={t('report.organization_name')}
                value={data.Data.Kkm.Company.FullName}
              />
              <DefinitionItem title={t('report.tax_id')} value={data.Data.Kkm.Company.Bin} />
              <DefinitionItem title={t('report.kkm')} value={data.Data.Kkm.Id} />
              <DefinitionItem
                title={
                  <span className="whitespace-pre-line">{t('report.kgd_registration_number')}</span>
                }
                value={data.Data.Kkm?.Rnm || ''}
              />
              <DefinitionItem title={t('report.cash_register_name')} value={data.Data.Kkm.Name} />
              <DefinitionItem
                title={t('report.trading_point_address')}
                value={renderAddress(data.Data.Kkm.Address)}
              />
              <DefinitionItem title={t('report.cashier')} value={user?.Name || ''} />
            </DefinitionList>
            <DefinitionList>
              <DefinitionItem
                title={
                  <span className="whitespace-pre-line">
                    {t('report.balance_at_shift_opening')}
                  </span>
                }
                value={formatAmount(data.Data.BalanceOpen, true)}
              />
              <DefinitionItem title={t('report.issued_receipts_alt')} value={data.Data.Count} />
            </DefinitionList>
            <DefinitionList>
              <DefinitionItem
                title={
                  <span className="whitespace-pre-line">{t('other.balance_at_x_report_time')}</span>
                }
                value={formatAmount(
                  Number(data.Data.BalanceOpen) +
                    Number(data.Data.Total.Sales.Amount) +
                    Number(data.Data.Total.Incomes.Amount) -
                    Number(data.Data.Total.Refunds.Amount) -
                    Number(data.Data.Total.Expenses.Amount),
                  true,
                )}
              />
            </DefinitionList>
          </DisclosureCustom>
        </div>
        <div className="bg-white rounded-lg overflow-hidden w-full">
          <DisclosureCustom
            btnClassName="px-3 py-2 lg:border-b border-lightGray-second lg:text-darkGray-five lg:font-semibold"
            btnName={t('report.registered_receipts')}
          >
            <DefinitionList title={t('report.service_income')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.Incomes.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total.Incomes.Amount, true)}
              />
            </DefinitionList>
            <DefinitionList title={t('report.service_expense')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.Expenses.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total.Expenses.Amount, true)}
              />
            </DefinitionList>
            <DefinitionList title={t('report.sales')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.Sales.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total?.Sales?.Amount, true)}
              />
            </DefinitionList>
            <DefinitionList title={t('report.sales_returns')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.Refunds.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total.Refunds.Amount, true)}
              />
            </DefinitionList>
            <DefinitionList title={t('report.purchases')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.Purchases.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total.Purchases.Amount, true)}
              />
            </DefinitionList>
            <DefinitionList title={t('report.purchase_returns')}>
              <DefinitionItem
                title={t('report.number_of_receipts')}
                value={data.Data.Total.PurchaseRefunds.Qty}
              />
              <DefinitionItem
                title={t('report.total_amount')}
                value={formatAmount(data.Data.Total.PurchaseRefunds.Amount, true)}
              />
            </DefinitionList>
          </DisclosureCustom>
        </div>
      </div>

      <div className="w-full lg:flex justify-end">
        {Capacitor.isNativePlatform() ? (
          <MobileAppDownloadFile
            fileName="report.x_report_file_name"
            pdfCurrentStyle="x-report-pdf"
            contentRef={printRef}
          />
        ) : (
          <Button
            onClick={reactToPrintFn}
            Icon={<Icon type="printLine" />}
            className="w-full lg:w-auto m-0"
            text="close_shift.print"
          />
        )}
      </div>
    </div>
  );
};

export { XReport };
