import { fiscalApi } from '../fiscalApi';

import { FISCAL_API_ENDPOINTS } from '@/constants/api';
import type { ApiResponse } from '@/types/queries';
import type {
  OperationsRequest,
  OperationsResponse,
  DownloadReportRequest,
  OperationDetailsResponse,
  SendReceiptRequest,
  XReportResponse,
  XReportRequest,
  SectionsReportResponse,
  ZReportRequest,
  ZReportData,
} from '@/types/report';
import { buildEndpointUrl } from '@/utils/build-endpoint-url';

const reportService = fiscalApi.injectEndpoints({
  endpoints: (build) => ({
    getOperationsReport: build.mutation<ApiResponse<OperationsResponse>, OperationsRequest>({
      query: ({ body, idKkm }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.GET_OPERATIONS_REPORT, { idKkm }),
        method: 'POST',
        body,
      }),
    }),
    downloadReportFile: build.mutation<BlobPart, DownloadReportRequest>({
      query: ({ dateFrom, dateTo, idKkm }) => ({
        url: `${buildEndpointUrl(FISCAL_API_ENDPOINTS.DOWNLOAD_REPORT_FILE, {
          idKkm,
        })}?datefrom=${dateFrom}&dateto=${dateTo}`,
        method: 'GET',
        headers: { 'Content-Type': 'blob' },
        responseHandler: (response) => response.arrayBuffer(),
      }),
    }),
    downloadZReportFile: build.mutation<BlobPart, DownloadReportRequest>({
      query: ({ dateFrom, dateTo, idKkm }) => ({
        url: `${buildEndpointUrl(FISCAL_API_ENDPOINTS.DOWNLOAD_Z_REPORT_FILE, {
          idKkm,
        })}?datefrom=${dateFrom}&dateto=${dateTo}`,
        method: 'GET',
        headers: { 'Content-Type': 'blob' },
        responseHandler: (response) => response.arrayBuffer(),
      }),
    }),
    getOperationDetails: build.query<ApiResponse<OperationDetailsResponse>, string | number>({
      query: (id) => FISCAL_API_ENDPOINTS.OPERATION_DETAILS + id,
    }),
    sendOperationReceiptToEmail: build.mutation<ApiResponse<string>, SendReceiptRequest>({
      query: ({ body, id }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.SEND_RECEIPT, { idReceipt: id }),
        method: 'POST',
        body,
      }),
    }),
    getXReport: build.query<ApiResponse<XReportResponse>, XReportRequest>({
      query: ({ idShift, uid }) => ({
        url: buildEndpointUrl(FISCAL_API_ENDPOINTS.X_REPORT, { idShift }),
        headers: {
          uid,
        },
      }),
    }),
    getSectionsReport: build.query<ApiResponse<SectionsReportResponse>, number>({
      query: (idShift) => buildEndpointUrl(FISCAL_API_ENDPOINTS.SECTIONS_REPORT, { idShift }),
    }),
    getZReport: build.mutation<ApiResponse<ZReportData[]>, ZReportRequest>({
      query: ({ dateFrom, dateTo, idKkm }) =>
        `${buildEndpointUrl(FISCAL_API_ENDPOINTS.Z_REPORT, {
          idKkm,
        })}?datefrom=${dateFrom}&dateto=${dateTo}`,
    }),
  }),
});

export const {
  useGetOperationsReportMutation,
  useDownloadReportFileMutation,
  useLazyGetOperationDetailsQuery,
  useSendOperationReceiptToEmailMutation,
  useGetXReportQuery,
  useLazyGetXReportQuery,
  useLazyGetSectionsReportQuery,
  useGetSectionsReportQuery,
  useGetZReportMutation,
  useDownloadZReportFileMutation,
} = reportService;
