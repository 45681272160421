export const setToLocalStorage = (name: string, value: any) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getFromLocalStorage = (name: string) => {
  if (localStorage.getItem(name) !== null) {
    return JSON.parse(localStorage.getItem(name) as string);
  }

  return null;
};

export const removeFromLocalStorage = (name: string) => {
  return localStorage.removeItem(name);
};
