import { Preferences } from '@capacitor/preferences';

export const setStorageValue = async (key: string, value: string) => {
  await Preferences.set({
    key,
    value,
  });
};

// JSON "get" example
export const getStorageValue = async (key: string) => {
  const ret = await Preferences.get({ key });

  return ret;
};

export const removeStorageValue = async (key: string) => {
  await Preferences.remove({ key });
};
