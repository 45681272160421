import { AdjustmentsHorizontalIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useNavigation } from '@/hooks';
import { OperationsMenuModal } from '@/views/shared/OperationsMenuModal';

const MobileFooterNav = () => {
  const { openedShift } = useAppSelector((state) => state.shift);
  const { goToShift, goToReport } = useNavigation();
  const { t } = useTranslation();

  if (!openedShift) {
    return null;
  }

  return (
    <div className="sticky bottom-0 p-4 bg-lightGray-fourth z-50 text-darkGray-second flex justify-around items-center">
      <button type="button" className="w-12" onClick={() => goToReport(openedShift.Id)}>
        <DocumentTextIcon className="w-6 h-6 mx-auto text-darkGray-five" />
        <span>{t('main.reports')}</span>
      </button>
      <OperationsMenuModal />
      <button type="button" className="w-12" onClick={() => goToShift(openedShift.IdKkm)}>
        <AdjustmentsHorizontalIcon className="w-6 h-6 mx-auto text-darkGray-five" />
        {t('main.cash_register_number')}
      </button>
    </div>
  );
};

export { MobileFooterNav };
