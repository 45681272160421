import { UserIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants/routes';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setUser } from '@/store/user/slice';
import { ButtonKinds } from '@/types/button';
import { clearAuthData } from '@/utils/clear-auth-data';
import { Button } from '@/views/shared/Button';

const UserInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const logout = useCallback(() => {
    clearAuthData(() => {
      dispatch(setUser(null));
      navigate(ROUTES.SIGNIN);
    });
  }, [dispatch, navigate]);

  if (user) {
    return (
      <div className="flex items-center gap-2">
        <UserIcon className="w-6" />

        <div>
          <span className="font-semibold">{user?.Name}</span>
          <Button
            onClick={logout}
            text="close_shift.logout"
            className="text-sm"
            kind={ButtonKinds.simple}
          />
        </div>
      </div>
    );
  }

  return null;
};

export { UserInfo };
