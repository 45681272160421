import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { FISCAL_FRONT_URL } from '@/constants/api';
import { REPORT_SEND_TYPES } from '@/constants/reports';
import { useAppSelector, useNavigation } from '@/hooks';
import { useLazyGetOperationDetailsQuery } from '@/store/reports/service';
import type { OperationDetailsReceipt } from '@/types/report';
import { shareFile } from '@/utils/shareFile';

const useContainer = ({ reportId }: { reportId: number }) => {
  const { t } = useTranslation();
  const { openedShift } = useAppSelector((state) => state.shift);
  const [getOperationDetails, { isLoading, isFetching }] = useLazyGetOperationDetailsQuery();
  const [reportDetails, setReportDetails] = useState<OperationDetailsReceipt>({
    link: '',
    img: '',
  });
  const [error, setErrorData] = useState('');
  const [activeTabId, setActiveTabId] = useState(REPORT_SEND_TYPES.EMAIL);
  const printRef = useRef<HTMLImageElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const { goToShift } = useNavigation();

  const backToShift = () => {
    if (openedShift) {
      goToShift(openedShift.IdKkm);
    }
  };

  const showReportOperationsDuplicate = useCallback(() => {
    try {
      getOperationDetails(reportId, true)
        .unwrap()
        .then((data) => {
          const link = `${FISCAL_FRONT_URL}${data.Data.Check.Path.replace(/\\u0026/g, '&')}`;
          const encodedlink = encodeURI(link);

          setReportDetails({
            link: encodedlink,
            img: data.Data.Receipt,
          });
        })
        .catch((e) => {
          setErrorData(e?.response);
        });
    } catch (e: any) {
      setErrorData(e?.response);
    }
  }, [getOperationDetails, reportId]);

  useEffect(() => {
    if (reportId) showReportOperationsDuplicate();
  }, [reportId, showReportOperationsDuplicate]);

  const printReceipt = useCallback(async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const fileName = `Чек-${reportId}.png`;

        await Filesystem.writeFile({
          directory: Directory.Documents,
          path: fileName,
          data: `data:image/png;base64, ${reportDetails.img}`,
        });

        const fileUri = await Filesystem.getUri({
          directory: Directory.Documents,
          path: fileName,
        });
        await shareFile(fileName, fileUri.uri);
      } else {
        reactToPrintFn();
      }
    } catch (e) {
      console.error('Error downloading or saving file:', e);
    }
  }, [reactToPrintFn, reportDetails.img, reportId]);

  const printButtonText = useMemo(
    () => (Capacitor.isNativePlatform() ? 'receipt.download' : 'receipt.print'),
    [],
  );

  return {
    activeTabId,
    reportDetails,
    isLoading: isLoading || isFetching,
    t,
    error,
    printRef,
    printButtonText,
    setActiveTabId,
    printReceipt,
    backToShift,
  };
};

export default useContainer;
