import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useCallback, useMemo } from 'react';

import { ArticleModal } from './ArticleModal';
import useContainer from './hook';
import { ImportModal } from './ImportModal';

import FileExcel2Fill from '@/assets/icons/file-excel-2-fill.svg?react';
import type { ArticleItem } from '@/types/article';
import { ButtonKinds } from '@/types/button';
import { getAmountWithZeroes } from '@/utils/format-amount';
import { Button } from '@/views/shared/Button';
import { Loading } from '@/views/shared/Loading';
import CustomPopover from '@/views/shared/Popover';
import { SearchInput } from '@/views/shared/SearchInput';
import { Table } from '@/views/shared/Table';
import { twMerge } from 'tailwind-merge';

const ArticlesSettings = () => {
  const {
    t,
    articleList,
    isLoading,
    tableHead,
    totalCount,
    searchValue,
    sectionList,
    showArticleModal,
    checkedArticle,
    isMobile,
    showImportModal,
    refetch,
    setSearchValue,
    handleSearchArticleList,
    setArticleAndOpenEditModal,
    toggleShowEditModal,
    getUnit,
    setShowImportModal,
  } = useContainer();

  const getAdaptiveContent = useCallback((label: string, value: string | number | JSX.Element) => {
    return (
      <div className="flex items-center justify-between gap-2">
        <span className="flex-shrink-0 lg:hidden">{label}</span>
        <span className="w-full mx-2 border-t border-dashed border-lightGray lg:hidden" />
        <span className="text-right lg:text-left">{value}</span>
      </div>
    );
  }, []);

  const tableRows = useMemo(
    () =>
      articleList.length
        ? articleList?.map((article: ArticleItem) => ({
            Qr: getAdaptiveContent(tableHead[0].label, article.Qr || t('other.not_specified')),
            Name: getAdaptiveContent(tableHead[1].label, article.Name),
            Unit: getAdaptiveContent(tableHead[2].label, getUnit(article?.IdUnit)),
            Price: getAdaptiveContent(tableHead[3].label, getAmountWithZeroes(article.Price)),
            Section: getAdaptiveContent(
              tableHead[4].label,
              sectionList?.find((section) => section.Id === article.IdSection)?.Name || '',
            ),
            Discount: getAdaptiveContent(tableHead[5].label, getAmountWithZeroes(article.Discount)),
            Markup: getAdaptiveContent(tableHead[6].label, getAmountWithZeroes(article.Markup)),
            InSale: getAdaptiveContent(
              tableHead[7].label,
              article.Active ? (
                <span className="bg-lightGreen rounded-3xl w-auto py-1 px-2 text-sm">
                  {t('kassa_settings.yes')}
                </span>
              ) : (
                <span className="bg-lightRed rounded-3xl w-auto py-1 px-2 text-sm">
                  {t('kassa_settings.no')}
                </span>
              ),
            ),
            Operations: (
              <Button
                className="m-0 h-9 text-sm lg:inline-flex w-full lg:w-12 "
                kind={ButtonKinds.secondaryGray}
                onClick={() => setArticleAndOpenEditModal(article)}
                Icon={<PencilIcon className="w-5" />}
                text={isMobile ? 'kassa_settings.redact' : ''}
              />
            ),
          }))
        : [],
    [
      articleList,
      getAdaptiveContent,
      getUnit,
      isMobile,
      sectionList,
      setArticleAndOpenEditModal,
      t,
      tableHead,
    ],
  );

  const renderedContent = useMemo(() => {
    if (!articleList?.length && !searchValue) {
      return <div>{t('other.no_items_found_add_by_clicking_add_item')}</div>;
    }

    if (!articleList?.length) return <div>{t('other.no_items_found')}</div>;

    return (
      <Table
        rows={tableRows}
        heads={tableHead}
        classCellName="last:text-right font-normal lg:last:w-[120px] last:w-full w-full lg:w-auto"
        classHeadCellName="last:text-right"
      />
    );
  }, [articleList?.length, searchValue, t, tableHead, tableRows]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {showImportModal && (
        <ImportModal onClose={() => setShowImportModal(false)} onSuccess={refetch} />
      )}
      {showArticleModal && (
        <ArticleModal
          article={checkedArticle}
          isOpen={showArticleModal}
          onClose={setArticleAndOpenEditModal}
          onSave={refetch}
        />
      )}
      <div className="my-5 flex items-center justify-between flex-col lg:flex-row gap-4">
        <div className="font-semibold text-xl">
          {t('kassa_settings.item_alt', { count: totalCount })}
        </div>
        <div className="flex items-center justify-between gap-4 flex-col lg:flex-row w-full lg:w-auto child:w-full lg:child:w-auto">
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('kassa_settings.search')}
            onSearch={handleSearchArticleList}
            className="w-full lg:w-auto"
          />
          <CustomPopover
            header={({ open }) => (
              <div className="bg-blue text-white flex gap-2 items-center pr-2 rounded-xl h-11 font-semibold">
                <Button
                  text="kassa_settings.add_item"
                  Icon={<PlusIcon className="w-4" />}
                  className="hover:bg-blue flex-grow"
                  onClick={toggleShowEditModal}
                />

                <ChevronDownIcon
                  className={twMerge('w-4 ml-2 transition-transform', open && 'rotate-180')}
                />
              </div>
            )}
            className="w-full mt-1 bg-blue p-0 overflow-hidden rounded-lg gap-0"
          >
            <>
              <Button
                text="kassa_settings.add_item"
                className="w-full justify-start"
                Icon={<PlusIcon className="w-4" />}
                onClick={toggleShowEditModal}
              />
              <Button
                text="kassa_settings.import_items"
                className="w-full justify-start"
                Icon={<FileExcel2Fill className="w-4" />}
                onClick={() => setShowImportModal(true)}
              />
            </>
          </CustomPopover>
        </div>
      </div>
      {renderedContent}
    </div>
  );
};

export { ArticlesSettings };
