import { ArrowLeftIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { ButtonKinds } from '@/types/button';
import { Button } from '@/views/shared/Button';

type Props = {
  title: string;
  onClick: () => void;
};
const BackButtonWithHeader = ({ title, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Button onClick={onClick} className="hidden lg:flex m-0" kind={ButtonKinds.secondaryIcon}>
          <ArrowLeftIcon className="w-4" />
          {t('report.back')}
        </Button>
      </div>
      <div className="flex justify-between w-full lg:w-auto lg:justify-center items-center">
        <Button onClick={onClick} className="lg:hidden m-0 z-10" kind={ButtonKinds.iconOnly}>
          <ChevronLeftIcon className="w-4" />
        </Button>
        <h1 className="text-2xl font-semibold text-center flex-grow -ml-10 lg:ml-0">{t(title)}</h1>
      </div>
    </>
  );
};

export { BackButtonWithHeader };
