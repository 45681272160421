import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from './useRedux';

import { useGetBalancesQuery } from '@/store/balance/service';
import { setOpenedBalances, setTotalBalance } from '@/store/balance/slice';

const useBalance = () => {
  const { openedShift } = useAppSelector((state) => state.shift);
  const dispatch = useAppDispatch();

  const { data } = useGetBalancesQuery(openedShift?.IdKkm || 0, {
    skip: !openedShift,
  });

  useEffect(() => {
    if (data?.Data.Balances) {
      dispatch(setOpenedBalances(data?.Data.Balances));

      const totalBalance = data?.Data.Balances.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.Amount;
      }, 0);

      dispatch(setTotalBalance(totalBalance));
    }
  }, [data?.Data.Balances, dispatch]);
};

export { useBalance };
