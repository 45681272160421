import useContainer from './hook';

import { ButtonType } from '@/types/button';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import { SwitchCustom } from '@/views/shared/Form/Switch';

const KassaSettings = ({ onSave }: { onSave: () => void }) => {
  const { t, methods, isLoading, saveKassaSettings } = useContainer({ onSave });

  return (
    <div className="bg-white rounded-lg shadow-gray py-3 m-auto mt-6 w-auto max-w-lg">
      <Form onSubmit={methods.handleSubmit(saveKassaSettings)} form={methods}>
        <div className="flex flex-col gap-2">
          <div className="px-3 flex flex-col gap-2">
            <Input
              id="name"
              register={methods.register('name')}
              type="text"
              aria-invalid={!!methods?.formState?.errors.name}
              label={t('kassa_settings.name')}
              errorText={
                methods.formState.errors.name?.message || t('other.enter_cash_register_name')
              }
            />

            <Input
              id="znm"
              register={methods.register('znm')}
              type="text"
              aria-invalid={false}
              label={t('main.znm')}
              errorText=""
              disabled
            />
            <Input
              id="address"
              register={methods.register('address')}
              type="text"
              aria-invalid={false}
              label={t('kassa_settings.address')}
              errorText=""
              disabled
            />
            <Input
              id="placeUsed"
              register={methods.register('placeUsed')}
              type="number"
              aria-invalid={false}
              label={t('kassa_settings.place_of_use')}
              errorText=""
              disabled
            />
          </div>
          <div className="my-2 py-2 text-base border-t px-3 border-lightGray">
            <SwitchCustom
              control={methods.control}
              name="autorenew"
              label={t('kassa_settings.automatic_shift_closure')}
            />
          </div>
          <div className="border-b border-lightGray pb-2 px-3">
            {t('kassa_settings.shift_will_be_closed_automatically')}
          </div>
          <div className="px-3">
            <Button
              text={isLoading ? 'kassa_settings.saving_changes' : 'kassa_settings.save_changes'}
              type={ButtonType.submit}
              isLoading={isLoading}
              className="w-full mt-2 "
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export { KassaSettings };
