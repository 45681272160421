import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../Icon';

import type { OptionType } from '@/types/form';
import { twMergeClsx } from '@/utils/tw-with-clsx';

type Props<T> = {
  options: OptionType<T>[];
  selectedOption: OptionType<T> | null;
  setSelectedOption: (value: OptionType<T> | null) => void;
  name: string;
  label?: string;
  withIntl?: boolean;
  defaultValue?: OptionType<T>;
  className?: string;
  containerClassName?: string;
};

const Select = <T,>({
  options,
  selectedOption,
  label,
  name,
  withIntl,
  defaultValue,
  className,
  containerClassName,
  setSelectedOption,
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <div className={twMergeClsx('relative', containerClassName)}>
      {label && (
        <label htmlFor={name} className="text-sm text-darkGray pl-2">
          {label}
        </label>
      )}
      <Listbox value={selectedOption} onChange={setSelectedOption} defaultValue={defaultValue}>
        {({ open }) => (
          <>
            <ListboxButton
              className={twMergeClsx(
                'border border-lightGray-second rounded-xl overflow-hidden flex items-center h-11 transition-shadow w-full bg-white px-4',
                className,
              )}
            >
              <span className="flex-grow text-left">
                {withIntl && selectedOption ? t(selectedOption.label) : selectedOption?.label}
              </span>
              <Icon
                type="arrowDown"
                className={twMergeClsx(
                  'w-3 h-3 fill-darkGray-third my-auto transition-transform',
                  open && 'rotate-180',
                )}
              />
            </ListboxButton>
            <ListboxOptions
              transition
              className="origin-top shadow-gray rounded-lg transition mt-2 bg-white absolute duration-200 ease-out empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0 w-full overflow-y-auto max-h-80 z-20"
            >
              {options.map((option) => (
                <ListboxOption
                  key={option.id}
                  value={option}
                  className="data-[focus]:bg-blue/10 px-3 py-2"
                >
                  {withIntl ? t(option.label) : option.label}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </>
        )}
      </Listbox>
    </div>
  );
};

export { Select };
