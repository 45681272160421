import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import type { MutableRefObject, ReactElement, ReactNode } from 'react';

import { twMergeClsx } from '@/utils/tw-with-clsx';

type CloseFn = (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;

type Props = {
  header: ReactElement | (({ open }: { open: boolean }) => ReactElement);
  children: ReactElement | (({ close }: { close: CloseFn }) => ReactElement);
  className?: string;
};

const CustomPopover = ({ header, children, className }: Props) => {
  return (
    <Popover className="relative z-50">
      {({ open }) => (
        <>
          <PopoverButton className="outline-none w-full">
            {typeof header === 'function' ? header({ open }) : header}
          </PopoverButton>
          <PopoverPanel
            transition
            className={twMergeClsx(
              'flex flex-col bg-darkGray-second rounded-bl-lg rounded-br-lg z-20 text-white gap-2 py-3 mt-3 origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 absolute w-full left-1/2 -translate-x-1/2',
              className,
            )}
          >
            {({ close }) => (typeof children === 'function' ? children({ close }) : children)}
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default CustomPopover;
