import { Capacitor } from '@capacitor/core';

import useContainer from './hook';

import { formatAmount } from '@/utils/format-amount';
import { Button } from '@/views/shared/Button';
import { DefinitionItem } from '@/views/shared/Definition/DefinitionItem';
import { DefinitionList } from '@/views/shared/Definition/DefinitionList';
import { DisclosureCustom } from '@/views/shared/Disclosure';
import { Icon } from '@/views/shared/Icon';
import { Loading } from '@/views/shared/Loading';
import { MobileAppDownloadFile } from '@/views/shared/MobileAppDownloadFile';

const SectionsReport = () => {
  const {
    sectionSubtitles,
    isOpenSections,
    reportSections,
    printRef,
    isLoading,
    t,
    printSection,
    reactToPrintFn,
  } = useContainer();

  if (isLoading) {
    return <Loading />;
  }

  if (!reportSections) {
    return <p className="my-4">{t('other.no_open_shift_for_section_report')}</p>;
  }

  return (
    <div className="relative flex mt-4 justify-center flex-wrap lg:flex-nowrap">
      <div ref={printRef} className="w-full lg:w-96 order-2 lg:order-1" id="reportContainer">
        <div className="bg-white rounded-lg overflow-hidden w-full">
          <div className="px-3 py-2 border-b border-lightGray-second hidden lg:block">
            <div className="text-darkGray-five font-semibold ">
              {t('report.section_totals_for_shift')}
            </div>
          </div>
          {Object.keys(reportSections).length ? (
            Object.keys(reportSections)?.map((reportSection) => {
              return (
                <DefinitionList key={reportSection}>
                  <DisclosureCustom
                    btnName={`${t('report.section')}:${' '}  ${reportSection} ${
                      reportSections?.[reportSection]?.Name
                    }`}
                    containerClassName="flex flex-col gap-2 mt-3"
                    allOpened={isOpenSections}
                  >
                    {Object.keys(reportSections[reportSection])
                      .filter((item) => item !== 'Name')
                      .map((item, index) => {
                        return (
                          <DefinitionItem
                            key={item}
                            title={sectionSubtitles[index]}
                            value={formatAmount(
                              Number(reportSections?.[reportSection]?.[item]),
                              true,
                            )}
                          />
                        );
                      })}
                  </DisclosureCustom>
                </DefinitionList>
              );
            })
          ) : (
            <div className="text-sm leading-4 text-black font-semibold px-3 py-2">
              {t('report.section_empty')}
            </div>
          )}
        </div>
      </div>
      <div className="lg:absolute right-0 order-1 lg:order-2 mb-3 lg:mb-0 w-full lg:w-auto">
        {Capacitor.isNativePlatform() ? (
          <MobileAppDownloadFile
            fileName="report.export_pdf_file_name"
            pdfCurrentStyle="section-pdf"
            contentRef={printRef}
            onBeforePrint={printSection}
          />
        ) : (
          <Button
            onClick={reactToPrintFn}
            Icon={<Icon type="printLine" />}
            className="w-full lg:w-auto m-0"
            text="close_shift.print"
          />
        )}
      </div>
    </div>
  );
};

export { SectionsReport };
