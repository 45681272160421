import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { REPORT_TYPES } from '@/constants/reports';
import { useAppSelector, useShift } from '@/hooks';

export const tabList = [
  {
    id: REPORT_TYPES.OPERATIONS,
    name: 'report.operation_log',
  },
  {
    id: REPORT_TYPES.X_REPORT,
    name: 'report.x_report',
  },
  {
    id: REPORT_TYPES.Z_REPORT_ARCHIVE,
    name: 'report.z_report_archive',
  },
  {
    id: REPORT_TYPES.SECTIONS,
    name: 'report.by_sections',
  },
];

const useContainer = () => {
  const { idShift } = useParams();
  const { shift } = useAppSelector((state) => state.shift);
  const { setCurrentShift } = useShift();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  const [activeTabId, setActiveTabId] = useState(
    tabList[Number(currentTab)]?.id || REPORT_TYPES.OPERATIONS,
  );

  useEffect(() => {
    setActiveTabId(tabList[Number(currentTab)]?.id || REPORT_TYPES.OPERATIONS);
  }, [currentTab, location.search, navigate]);

  const onChangeTab = (tabIndex: number) => {
    setActiveTabId(tabIndex);

    const selectTab = tabList[tabIndex];

    navigate({
      pathname: location.pathname,
      search: `tab=${selectTab.id}`,
    });
  };

  useEffect(() => {
    if (!shift || Number(idShift) !== shift.Id) {
      setCurrentShift({ idShift: Number(idShift) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idShift, shift]);

  return { activeTabId, setActiveTabId, onChangeTab };
};

export default useContainer;
