import useContainer from './hook';

import { ButtonType } from '@/types/button';
import { getBase64Img } from '@/utils/file';
import { Button } from '@/views/shared/Button';
import { Form } from '@/views/shared/Form';
import { Input } from '@/views/shared/Form/Input';
import Modal from '@/views/shared/Modal';

const Billcheck = () => {
  const { methods, isLoading, receiptImage, showReceiptModal, getReceipt, updateReceiptModal } =
    useContainer();

  return (
    <div className="bg-white px-3 py-4 rounded-xl w-80">
      <Modal
        open={showReceiptModal}
        onClose={() => updateReceiptModal('', false)}
        title="Дубликат чека"
      >
        {receiptImage ? (
          <div>
            <img src={getBase64Img(receiptImage)} alt="receipt" />
          </div>
        ) : (
          'Чек не найден'
        )}
      </Modal>

      <h1 className="text-xl">Fiscal24</h1>
      <p className="text-darkGray">Онлайн-касса</p>
      <p className="text-darkGray my-3">Укажите данные чека</p>
      <Form form={methods} onSubmit={methods.handleSubmit(getReceipt)}>
        <div className="flex flex-col gap-3 text-left">
          <Input
            type="number"
            name="znm"
            label="ЗНМ Кассы"
            register={methods.register('znm')}
            aria-invalid={!!methods.formState.errors.znm}
            errorText={methods.formState.errors.znm?.message}
          />
          <Input
            type="number"
            name="fn"
            label="Фискальный признак"
            register={methods.register('fn')}
            aria-invalid={!!methods.formState.errors.fn}
            errorText={methods.formState.errors.fn?.message}
          />
          <Input
            type="datetime-local"
            name="datetime"
            step="1"
            label="Дата"
            register={methods.register('datetime')}
            aria-invalid={!!methods.formState.errors.datetime}
            errorText={methods.formState.errors.datetime?.message}
          />
          <Input
            type="number"
            name="total"
            label="Итоговая сумма"
            register={methods.register('total')}
            aria-invalid={!!methods.formState.errors.total}
            errorText={methods.formState.errors.total?.message}
          />
          <Button
            type={ButtonType.submit}
            text="Подтвердить"
            className="w-full"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export { Billcheck };
