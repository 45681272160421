import { Languages } from '@/constants/app';
import type { Section, Unit } from '@/types';

export const getUnitsOptions = (
  units: Unit[],
  locale: (typeof Languages)[keyof typeof Languages],
) => {
  return (
    units?.map((unit) => ({
      id: unit.Id,
      label: locale === Languages.KZ ? unit.NameKAZ : unit.NameRU,
      value: unit,
    })) ?? []
  );
};

export const getSectionsOptions = (sections: Section[]) => {
  return (
    sections?.map((section) => ({
      id: section.Id,
      label: section.Name,
      value: section,
    })) ?? []
  );
};
