import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { WHATSAPP_API_URL } from '@/constants/api';
import { PHONE_LENGTH } from '@/constants/fields';
import type { WAppFormValues } from '@/types/report';
import { Form } from '@/views/shared/Form';
import { InputWithButton } from '@/views/shared/Form/InputWithButton';

const WAppForm = ({ link, id }: { link: string; id: number }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .transform((currentValue) => currentValue.replace(/\s/g, '').replace('+', ''))
      .min(PHONE_LENGTH, t('other.check_phone_number_correctness'))
      .required(t('other.enter_phone')),
  });

  const defaultValues = useMemo(() => {
    return {
      phone: '',
    };
  }, []);

  const methods = useForm<WAppFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
    register,
  } = methods;

  const sendToWhatsApp = useCallback(
    ({ phone }: WAppFormValues) => {
      if (phone.replace(/\D+/g, '').length) {
        setErrorText('');
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          window.open(
            `${WHATSAPP_API_URL}/send?phone=${phone}&text=${t(
              'close_shift.receipt_number',
            )}:${id}. ${t('receipt.receipt_link')}: ${encodeURIComponent(link)}`,
          );
          reset();
          toast.success(`${t('other.check_sent_successfully_to_number')}: ${phone}.`, {
            theme: 'dark',
          });
        }, 1000);
      } else {
        setErrorText(t('other.enter_phone_number'));
      }
    },
    [id, link, reset, t],
  );

  return (
    <Form onSubmit={handleSubmit(sendToWhatsApp)} form={methods}>
      <div className="relative my-3">
        <InputWithButton
          format="+7 ### ### ## ##"
          control={control}
          name="phone"
          errors={errors}
          placeholder="+7 (000) 000 00 00"
          label={t('receipt.send_receipt_whatsapp')}
          errorText={errors.phone?.message ?? errorText}
          isLoading={isLoading}
          btnText="duplicate.send"
          register={register}
        />
      </div>
    </Form>
  );
};

export { WAppForm };
