export const SHIFT_EXPIRED_DIFFERENCE = 23.9;

export const NEGATIVE_FULL = '-100.00';

export const POSITIVE_FULL = '100.00';

export const NO_CHANGE = '0%';

export const CASH_TITLE = 'Наличные средства';

export const NON_CASH_TITLE = 'Безналичные средства';
